import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";
import EditTable from '../EditTable/EditTable'
import AddFieldDialog from '../Dialogs/AddFieldDialog';
import EditFieldDialog from '../Dialogs/EditFieldDialog';
import {useAuthDispatch} from "../../contexts/Auth";
import {CircularProgress} from "@material-ui/core";

const ComputationFields = forwardRef((props, ref) => {
    const intl = useIntl();
    const {field} = props;

    const columns = [
        intl.formatMessage({id: "field_computation.table_head.field", defaultMessage: "Field"}),
        intl.formatMessage({id: "field_computation.table_head.type", defaultMessage: "Type"})
    ];

    const [fields, setFields] = useState([]);

    // TODO: Figure out how validation is used.
    const [_validation, setValidation] = useState([]);

    const setValidationFromErrors = (errors) => {
        if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
            setValidation(...errors[0].extensions.validation);
        }
    };

    const [computableFields, setComputableFields] = useState(null);
    let [computableTypes, setComputableTypes] = useState(null);
    const client = new graphQLApi(
        useAuthDispatch(),
        props.history,
        null,
        {handleErrors: setValidationFromErrors}
    );
    const stableClient = useCallback(client, []);
    const stableField = useCallback(field, []);
    useEffect(() => {
        if (stableField.computation_fields) {
            setFields(stableField.computation_fields);
        }

        stableClient
            .query(`{fieldDefinitions{id computes}}`)
            .then(r => {
                if (r && r.fieldDefinitions) {

                    let options = '';
                    let computables = [];
                    r.fieldDefinitions
                        .find(entry => entry.id === field.type)
                        .computes.forEach((option, index) => {
                            computables.push(option);
                            if (index !== 0) {
                                options += `,${option}`;
                            } else {
                                options += `${option}`;
                            }
                        });
                    setComputableTypes(computables);
                    getFields(stableClient, options);
                }
            })

    }, [stableClient, field, stableField]);


    function getFields(client, options) {
        let filter = `(filter:{type_in:"${options}"})`;
        if (options === "*") {
            filter = '';
        }
        const query = `{fields${filter}{data{ id type name }}}`;
        client
            .query(query)
            .then(r => {
                setComputableFields(r.fields.data);
            })
            .catch(error => console.error(error));
    }

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editDialogInitialValue, setEditDialogInitialValue] = useState({});

    function editComputationField(id) {
        setEditDialogInitialValue(fields.find(field => field.id === id));
        setEditDialogOpen(true);
    }

    function deleteComputationField (id) {
        const newFields = fields.filter(field => field.id !== id).map(field => field.id);
        const query = `{fieldUpdate(id:${field.id}, computation_fields:[${newFields}]) { computation_fields{id name type} }}`;
        client
            .mutate(query)
            .then(r => {
                if (r && r.hasOwnProperty('fieldUpdate')) {
                    setFields(r.fieldUpdate.computation_fields ? r.fieldUpdate.computation_fields : []);
                    handleSetcomputationFields(r.fieldUpdate.computation_fields);
                }
            });
    }

    function handleSetcomputationFields(newFields) {
        if (newFields) field.computation_fields = newFields;
        else field.computation_fields = fields;
    }

    function handleDialogClose(data) {
        setAddDialogOpen(false);

        if (!data || fieldAlreadyExists(data)) return;

        setFields([...fields, data]);
    }

    function handleEditDialogClose(data) {
        setEditDialogOpen(false);

        if (!data || fieldAlreadyExists(data)) return;

        const newFields = fields.map(field => {
            if (field.id === editDialogInitialValue.id) {
                return data;
            } else {
                return field;
            }
        });

        setFields(newFields);
    }

    function fieldAlreadyExists(data) {
        if (fields.find(field => field.id === data.id) === undefined) return false;
        return true;

    }

    useImperativeHandle(ref, () => ({
        addComputation() {
            //BUG: Returns to fields view after saving.
            setAddDialogOpen(true);
        },
        setComputationFields() {
            handleSetcomputationFields();
        },
        fieldsAreCompatible() {
            const bool = fields.length > 0 ? fields.some(item => computableTypes.includes("*") || computableTypes.includes(item.type)) : true;

            return bool;
        }
    }));

    return (
      <div style={{textAlign: 'center'}}>
        {(!field || !computableTypes) ? (
            <CircularProgress/>
        ) : (
            <EditTable columns={columns} data={fields} onClickEdit={editComputationField} onClickDelete={deleteComputationField} computableTypes={computableTypes} warningMessage={intl.formatMessage({id: "computation_fields.incompatible_field_type", defaultMessage: "Incompatible Field type"})} />
        )}
        {computableFields
            && <AddFieldDialog
            open={addDialogOpen}
            fields={computableFields}
            onClickClose={handleDialogClose}
            title={intl.formatMessage({id: "common.dialog.add.title", defaultMessage: "Add"})}
            label={intl.formatMessage({id: "common.dialog.add.label", defaultMessage: "Field"})} />}
        {computableFields
            && <EditFieldDialog
            open={editDialogOpen}
            fields={computableFields}
            onClickClose={handleEditDialogClose}
            title={intl.formatMessage({id: "common.dialog.edit.title", defaultMessage: "Edit Field"})} label={intl.formatMessage({id: "common.dialog.edit.title", defaultMessage: "Edit"})}
            initialValue={editDialogInitialValue} />
        }
      </div>
    );
});

export default ComputationFields;
