import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import componentStyles from "assets/theme/layouts/admin.js";
import {useIntl} from "react-intl";
import {authUser} from "contexts/Auth";
import EntityList from "views/Entities/EntityList";
import EntityEdit from "views/Entities/EntityEdit";
import {config} from "config";

const useStyles = makeStyles(componentStyles);

export default function Entities({history, match}) {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();

  const brandText = intl.formatMessage({id:"navbar.brand_text"});
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <>
      <>
        <Box position="relative" className={classes.mainContentNoSidebar}>
          <AdminNavbar history={history} match={match} logo={{
            innerLink: "/admin/products",
            imgSrc: (authUser().site && authUser().site.logo_uri)
              ? authUser().site.logo_uri
              : config.brandLogo.default,
            imgAlt: brandText,
          }}/>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Container classes={{ root: classes.mainRoot }}>
              <Switch>
                <Route path={"/data/:entityTypeID?"} component={EntityList} exact />
                <Route path={"/data/:entityTypeID/create"} component={EntityEdit} exact />
                <Route path={"/data/:entityTypeID/:id"} component={EntityEdit} />
              </Switch>
            </Container>
            <AdminFooter />
          </Container>
        </Box>
      </>
    </>
  );
};
