import {SwapHoriz} from "@material-ui/icons";
import ChannelList from "views/Channels/ChannelList";
import ChannelEdit from "views/Channels/ChannelEdit";
import {rolePriorities} from "config";

export default function routes(intl) {
  const prefix = '/channels';
  return [
    {
      path: "",
      name: intl.formatMessage({id: "menu.channels.list", defaultMessage: "Channels"}),
      icon: SwapHoriz,
      component: ChannelList,
      iconColor: "Info",
      layout: "/admin",
      allow_priority: rolePriorities.can_import_export,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.channels.create", defaultMessage: "Create channel"}),
          component: ChannelEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.channels.edit", defaultMessage: "Edit channel"}),
          component: ChannelEdit,
          hidden: true,
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}