import React, {useEffect, useState, useImperativeHandle} from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core";
import Autocomplete, {
    createFilterOptions
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

const AssetTagsDialog = React.forwardRef((props, ref) => {
    const {onDialogClose, open, assets} = props;
    const [selectedTags, setSelectedTags] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const {tags} = props;

    useEffect(() => {

        if (Array.isArray(assets)) {
            const tagsArray = assets.map(a => a.asset_tags);
            if (Array.isArray(tagsArray) && tagsArray.length > 0) {
                const common = tagsArray.reduce((a, b) => a.filter(t => b.map(x => x.id).includes(t.id)));
                setSelectedTags(common);
                setInitialTags(common);
            }
        } else {
            setSelectedTags(assets.asset_tags);
        }
    }, [assets]);


    useImperativeHandle(ref, () => ({
        selectedTags,
        initialTags
    }));


    return <Dialog open={open}>
        <DialogTitle disableTypography><Typography variant="h2">Asset tags to</Typography></DialogTitle>
        <DialogContent>
            <Autocomplete
                multiple
                options={tags}
                onChange={(e, values) => {
                    values.forEach(v => {
                        if (v.inputValue && v.inputValue !== '') {
                            v.id = '0';
                            v.name = v.inputValue;
                            delete v.inputValue;
                        }
                    })
                    setSelectedTags(values);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== "") {
                        filtered.push({
                            inputValue: params.inputValue,
                            id: "0",
                            name: `Add "${params.inputValue}"`
                        })
                    }
                    return filtered;
                }}
                getOptionLabel={(option) => option.name}
                value={selectedTags}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tags"
                    />
                )}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onDialogClose}>Close</Button>
        </DialogActions>
    </Dialog>
});

AssetTagsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    assets: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        file: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }))
    })).isRequired,
};

export default AssetTagsDialog;

