import React from "react";
import {useIntl} from "react-intl";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {CallSplit} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function EntityTypeEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "entity_types.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "roles",
            initial: [],
            multiple: true,
            type: "[ID]",
            label: intl.formatMessage({id: "entity_types.edit.label.roles", defaultMessage: "Roles with access"}),
            query: "roles",
            titleField: "title",
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<CallSplit color="primary"/>}
                        title={intl.formatMessage({id: "entity_types.edit.heading", defaultMessage:"Data Types edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"entityTypes"}
                                         mutations={"entityType"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
