import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip, Dialog, DialogContent, Grid, Paper} from "@material-ui/core";
import {Crop, Delete, Edit, OpenInBrowser} from "@material-ui/icons";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EnhancedTable from "components/DataTable/EnhancedTable";
import {FileTypeClass, FileTypeIcon} from "components/FileTypes";
import DropzoneUploader from "components/Dropzone/DropzoneUploader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import AssetTagsDialog from "./AssetTagsDialog";
import AssetTagFilterDialog from "./AssetTagFilterDialog";
import {makeStyles} from "@material-ui/core/styles";
import ImageCropper from "components/Dialogs/ImageCropper";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  blue: {
    backgroundColor: "#1976d2"
  },
  white: {
    backgroundColor: ""
  }
}));
export default function AssetList(props) {
  const classes = useStyles();

  const intl = useIntl();
  const tableRef = useRef();
  const dropzoneRef = useRef();
  const tagRef = useRef();
  const dispatch = useAuthDispatch();

  const [tags, setTags] = useState([]);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [tagFilterDialogOpen, setTagFilterDialogOpen] = useState(false);
  const [filterTags, setFilterTags] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [assetsFilter, setAssetsFilter] = useState("");

  const [showConfirmDeleteFiles, setShowConfirmDeleteFiles] = useState(false);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [showConfirmDeleteFile, setShowConfirmDeleteFile] = useState(false);
  const [deleteFile, setDeleteFile] = useState(null);

  const [selectedRow, setSelectedRow] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const columns = [
    {
      field: 'file_thumb',
      title: intl.formatMessage({id: 'assets.list.column.thumbnail', defaultMessage: 'Thumbnail'}),
      render: row => {
        if (row.file_thumb) {
          return <img height={30} alt={row.file} src={row.file_thumb}/>;
        }
        return <FontAwesomeIcon size="2x" icon={FileTypeIcon(row.file_type)}/>;
      },
      width: 85,
      sortable: false,
    },
    {title: intl.formatMessage({id: 'assets.list.column.file', defaultMessage: 'File'}), field: 'file'},
    {
      title: intl.formatMessage({id: 'assets.list.column.type', defaultMessage: 'Type'}),
      field: 'file_type',
      render: row => FileTypeClass(intl, row.file_type, row.file),
    },
    {
      title: intl.formatMessage({id: 'assets.list.column.asset_tags', defaultMessage: 'Tags'}),
      field: 'asset_tags',
      render: row => {
        if (row.hasOwnProperty('asset_tags')) {
          return row.asset_tags.map(t => t.name).join(', ');
        }
      }
    },
    {
      title: intl.formatMessage({id: 'assets.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'datetime'
    },
  ];

  const actions = [
    {
      icon: OpenInBrowser,
      tooltip: intl.formatMessage({id: "assets.list.actions.open", defaultMessage: "Open file"}),
      onClick: (row) => {
        window.open(row.file_uri, '_blank');
      },
    },
    {
      icon: LocalOfferIcon,
      tooltip: intl.formatMessage({id: "assets.list.actions.add_tags", defaultMessage: "Add tags"}),
      onClick: (row) => {
        setSelectedFiles(row);
        setTagDialogOpen(true);
      },
    },
    {
      icon: LocalOfferIcon,
      tooltip: intl.formatMessage({
        id: "assets.list.actions.add_tags_selected",
        defaultMessage: "Add tags to selected"
      }),
      onSelected: true,
      onClick: (rows) => {
        setSelectedFiles(rows);
        setTagDialogOpen(true);
      },
    },
    {
      icon: Delete,
      tooltip: intl.formatMessage({id: "assets.list.actions.delete", defaultMessage: "Delete"}),
      onClick: (row) => {
        setDeleteFile(row);
        setShowConfirmDeleteFile(true);
      },
    },
    {
      icon: Delete,
      tooltip: intl.formatMessage({id: "assets.list.actions.delete_selected", defaultMessage: "Delete selected"}),
      onSelected: true,
      onClick: (rows) => {
        setDeleteFiles(rows);
        setShowConfirmDeleteFiles(true);
      },
    },
    {
      disabled: (row) => (row.file_type.search('image') === -1),
      icon: Crop,
      tooltip: intl.formatMessage({id: "assets.list.actions.crop_image", defaultMessage: "Crop image"}),
      onClick: (row) => {
        setSelectedRow(row);
        setShowModal(true);
      }
    },
    {
      icon: Edit,
      tooltip: intl.formatMessage({id: "assets.list.actions.edit_image", defaultMessage: "Edit image"}),
      rowClick: true,
      onClick: (row) => {
        setSelectedRow(row);
        setShowEditDialog(true);
      }
    }
  ];

  useEffect(() => {
    const client = new graphQLApi(dispatch, props.history);
    client.query('query {assetTags {' +
      'data{id name}' +
      '}}')
      .then(r => {
        setTags(r.assetTags.data);
      });
  }, []);

  const onDeleteAssetConfirm = (confirmed) => {
    if (confirmed) {
      const client = new graphQLApi(dispatch, props.history);
      client.mutate('($id:ID!) {assetDelete(id:$id)}', {id: deleteFile.id}).then(_r => {
        onDeleted(deleteFile);
      });
    }
    setShowConfirmDeleteFile(false);
    setDeleteFile(null);
  }

  const onDeleteAssetsConfirm = (confirmed) => {
    if (confirmed) {
      const client = new graphQLApi(dispatch, props.history);
      client.mutate('($ids:[ID]!) {assetDelete(ids:$ids)}',
        {ids: deleteFiles.map(r => parseInt(r.id))}).then(_r => {
        onDeleted(deleteFiles);
      });
    }
    setShowConfirmDeleteFiles(false);
    setDeleteFiles([]);
  }

  const onUploaded = (_files) => {
    tableRef.current.update();
  }

  const onTagDialogClose = () => {
    const client = new graphQLApi(dispatch, props.history);
    if (Array.isArray(selectedFiles)) {
      const newTags = tagRef.current.selectedTags;
      const initialTags = tagRef.current.initialTags;
      const removed = initialTags.filter(t => !newTags.map(i => i.id).includes(t.id));

      selectedFiles.forEach(asset => {
        let updatedTags = [];
        if (removed && removed.length > 0) {
          removed.forEach(r => {
            const filtered = asset.asset_tags.filter(t => t.id !== r.id);
            filtered.forEach(t => {
              if (!updatedTags.includes(t)) {
                updatedTags.push(t);
              }
            })
          });
        } else {
          updatedTags = asset.asset_tags
        }

        newTags.forEach(t => {
          if (!updatedTags.includes(t)) {
            updatedTags.push(t);
          }
        })
        // console.log('firstTags', asset.asset_tags, asset.id)
        // console.log('updatedTags', updatedTags, asset.id)

        client
          .mutate('($id:ID!,$tags:String) {assetUpdate(id:$id, asset_tags:$tags) {id}}',
            {id: asset.id, tags: JSON.stringify(updatedTags)})
          .then(r => {
            if (r && r.hasOwnProperty('assetUpdate')) {
              tableRef.current.update();
              tableRef.current.clearSelected();
              setTagDialogOpen(false);
            }
          });
      });
    } else {
      client
        .mutate('($id:ID!,$tags:String) {assetUpdate(id:$id, asset_tags:$tags) {id}}',
          {id: selectedFiles.id, tags: JSON.stringify(tagRef.current.selectedTags)})
        .then(r => {
          if (r && r.hasOwnProperty('assetUpdate')) {
            tableRef.current.update();
            tableRef.current.clearSelected();
            setTagDialogOpen(false);
          }
        });
    }

  }

  const handleSelectTags = (event, tag) => {
    if (Array.isArray(filterTags)) {
      if (!filterTags.map(t => t.id).includes(tag.id)) {
        setFilterTags(selectedTags => [...selectedTags, tag]);
        event.currentTarget.style.backgroundColor = "#1976d2";
      } else {
        const updated = filterTags.filter(t => t.id !== tag.id);
        setFilterTags(updated);
        event.currentTarget.style.backgroundColor = ""
      }
      // fetchAssets(filterTags);
    }
    // else ????
  }

  const showTags = () => {
    return tags.map((tag) =>
      <Chip
        key={tag.id}
        variant="outlined"
        size="small"
        label={tag.name}
        clickable={true}
        onClick={(event) => handleSelectTags(event, tag)}
        className={`${classes.chip} ${(filterTags.map(t => t.id).includes(tag.id)) ? classes.blue : ''}`}
      />
    )
  }

  const showTagFilterDialog = () => {
    setTagFilterDialogOpen(true);
  }

  const showFilteredTags = () => {
    if (!tagFilterDialogOpen) {
      return filterTags.map(ft =>
        <Grid item><Chip size="small" variant="outlined" key={ft.id} label={ft.name}
                         onDelete={() => removeFilterTag(ft)}/></Grid>
      )
    }
  }
  const removeFilterTag = (tag) => {
    const filteredTags = filterTags.filter(ft => ft.id !== tag.id);
    fetchAssets(filteredTags);
    setFilterTags(filteredTags);
  }

  const fetchAssets = (filterTags) => {
    if (filterTags.length > 0) {
      const tagIds = filterTags.map(t => t.id);
      setAssetsFilter('asset_tag_ids:[' + tagIds.join(',') + ']');
    } else {
      setAssetsFilter('');
    }
    tableRef.current.update()
  }

  const onTagFilterDialogClose = () => {
    console.log(filterTags);
    fetchAssets(filterTags);
    // if (filterTags.length > 0) {
    //     const tagIds = filterTags.map(t => t.id);
    //     const client = new graphQLApi(dispatch, props.history);
    //     client.query('{' + 'assets' +
    //         '(' +
    //         'page:' + (page + 1) + ',limit:' + rowsPerPage + (orderBy !== '' ? ',sorting:"' + orderBy + '",direction:"' + order + '"' : '') +
    //         'filter:{asset_tag_ids:"' + tagIds + '"' + '}' +
    //         ')' +
    //         '{total current_page last_page from to data {id ' + queryFields + '} } }').then(r => {
    //         // setLoading ????
    //         if (r) {
    //             console.log(r)
    //             tableRef.current.setFilteredRows(r['assets'].data);
    //         }
    //     });
    // }
    setTagFilterDialogOpen(false)
  }

  const onDeleted = (files) => {
    if (Array.isArray(files)) {
      tableRef.current.clearSelected();
    } else {
      tableRef.current.setSelected(cur => cur.filter(c => c !== files.id));
    }
    tableRef.current.update();
  }

  const queryFields = "file file_uri file_type file_thumb asset_tags{id name} created_at";

  return (
    <Grid container>
      <Grid item xs={12}>
        {showModal && <ImageCropper
          title={intl.formatMessage({
            id: "enhanced_table.actions.edit",
            defaultMessage: "Edit"
          })}
          onClose={() => setShowModal(false)}
          open={showModal}
          selectedItem={selectedRow}
        />}
        <ConfirmDialog
          onClose={onDeleteAssetsConfirm}
          open={showConfirmDeleteFiles}
          title={intl.formatMessage({
            id: "dropzone.list.confirm.delete_selected.title",
            defaultMessage: "Confirm you want to delete {count} files"
          }, {count: deleteFiles.length})}
          message={intl.formatMessage({
            id: "dropzone.list.confirm.delete_selected.message",
            defaultMessage: "Are you sure you want to delete all of the {count} selected files?"
          }, {count: deleteFiles.length})}
        />
        <ConfirmDialog
          onClose={onDeleteAssetConfirm}
          open={showConfirmDeleteFile}
          title={intl.formatMessage({
            id: "dropzone.list.confirm.delete.title",
            defaultMessage: "Confirm deletion"
          })}
          message={(<span>{intl.formatMessage({
            id: "dropzone.list.confirm.delete.message",
            defaultMessage: "Are you sure you want to delete the file:"
          })}<br/>{deleteFile ? deleteFile.file : ''}</span>)}
        />
        <DropzoneUploader ref={dropzoneRef} onUploaded={onUploaded} mutation="asset"
                          fields={queryFields}>
          <Paper>
            <EnhancedTable
              ref={tableRef}
              columns={columns}
              query={"assets"}
              fields={queryFields}
              actions={actions} filter={assetsFilter}
              title={
                <Grid container spacing={1} style={{alignItems: "center"}}>
                  <Grid item><Button variant="outlined" size={"small"} onClick={showTagFilterDialog}>Tag filter</Button></Grid>
                  {showFilteredTags()}
                </Grid>
              }
              urlState
            />
          </Paper>
        </DropzoneUploader>
        <AssetTagsDialog
          ref={tagRef}
          open={tagDialogOpen}
          onDialogClose={onTagDialogClose}
          assets={selectedFiles}
          tags={tags}
        />
        <AssetTagFilterDialog
          open={tagFilterDialogOpen}
          close={onTagFilterDialogClose}
          showTags={showTags}
        />
        {selectedRow && <Dialog open={showEditDialog} onClose={() => setShowEditDialog(false)} maxWidth="md">
          <DialogContent style={{textAlign: "center"}}>
            <a href={selectedRow.file_uri} rel="noreferrer" target="_blank">
              {selectedRow.file_type && selectedRow.file_type.search('video') !== -1 ? <video controls width="100%">
                <source src={selectedRow.file_uri}/>
              </video> : ''}
              {selectedRow.file_type && selectedRow.file_type.search('audio') !== -1 ? <audio controls>
                <source src={selectedRow.file_uri}/>
              </audio> : ''}
              {selectedRow.file_type && selectedRow.file_type.search('image') !== -1 ?
                <img src={selectedRow.file_thumb} alt={selectedRow.file}/> : selectedRow.file}
            </a>
            <GraphQLEditForm
              query="assets"
              mutations="asset"
              id={selectedRow.id}
              cols={1}
              fields={[
                {
                  field: 'file',
                  input: 'file',
                  type: 'String',
                  label: intl.formatMessage({id: 'assets.list.column.file', defaultMessage: 'File'}),
                  help: intl.formatMessage({
                    id: 'assets.list.column.file_help',
                    defaultMessage: 'To overwrite the file, click the trash icon and select a new file.'
                  })
                }
              ]}
              onSave={() => {
                if (tableRef.current) tableRef.current.update();
                setShowEditDialog(false);
              }}
              buttons={[
                {onClick: () => setShowEditDialog(false), label: intl.formatMessage({id: "common.button.close"})}
              ]}
            />
          </DialogContent>
        </Dialog>}
      </Grid>
    </Grid>
  );
}
