import {Button, Snackbar} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";

let versionCheckTimer = null;

export default function ReleaseVersionChecker() {
  const intl = useIntl();

  const [checkedAt, setCheckedAt] = useState(null);
  const [newVersion, setNewVersion] = useState("");
  const [refreshCountdown, setRefreshCountdown] = useState(15);
  const [extendableTimes, setExtendableTimes] = useState(5);
  useEffect(() => {
    if (!versionCheckTimer) {
      // console.log('Prefecthing version check from manifest on load');
      fetch("/manifest.json", {})
        .then((response) => response.json())
        .then((result) => {
          if (result.hasOwnProperty("version_hash")) {
            localStorage.setItem("version_hash", result.version_hash);
          }
        });
      versionCheckTimer = setInterval(() => {
        // console.log('Fecthing version check from manifest');
        fetch("/manifest.json", {})
          .then((response) => response.json())
          .then((result) => {
            let loaded = localStorage.getItem("version_hash");
            if (result.hasOwnProperty("version_hash")) {
              if (!loaded) {
                localStorage.setItem("version_hash", result.version_hash);
              } else if (loaded !== result.version_hash) {
                setNewVersion(result.version_hash);
                if (!checkedAt) {
                  setCheckedAt(Date.now());
                }
              }
            }
          })
      }, 60000);
    }
  });

  const checkedAtTimer = useRef(null)

  useEffect(() => {
    if(checkedAt) {
      checkedAtTimer.current = setInterval(() => {
        const countdown = 15000 - (Date.now() - checkedAt);
        // console.log(countdown);
        if (countdown <= 0) {
          // console.log("Kill Me Please!");
          handleCloseVersionAlert(null, null);
        }
        setRefreshCountdown((countdown / 1000).toFixed(0));
      }, 1000);

      return () => {
        clearInterval(checkedAtTimer.current);
      }
    }
  }, [checkedAt]);

  function handleCloseVersionAlert(event, reason) {
    if (reason === 'clickaway') return;
    localStorage.setItem('version_hash', newVersion);
    setNewVersion(null);
    window.location.reload(true);
  }

  function handleExtendTimer() {
    setExtendableTimes(state => state - 1);
    setCheckedAt(Date.now());
    setRefreshCountdown(15);
  }

  return (
    <Snackbar
      open={newVersion !== ""}
      onClose={handleCloseVersionAlert}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      message={intl.formatMessage({
        id: "common.message.update",
        defaultMessage:
          "There's a new version available, push the button when you're ready.",
      })}
      action={
        <>
          <Button variant="contained" color="primary" onClick={() => {
              handleCloseVersionAlert(null, null);
          }}>
            {intl.formatMessage({
              id: "common.button.update",
              defaultMessage: "Update",
            })}{extendableTimes <= 0
              ? ` (${refreshCountdown})`.replace('-', '')
              : ''}
          </Button>
          {extendableTimes > 0
            ? (
              <Button variant="contained" color="secondary" style={{marginLeft: '8px'}} onClick={() => {
                handleExtendTimer();
              }} disabled={extendableTimes <= 0}>
                {intl.formatMessage({
                  id: "common.button.extend_time",
                  defaultMessage: "Extend Time"
                })}{` (${refreshCountdown})`.replace('-', '')}
              </Button>
            )
            : ''}
        </>
      }
    />
  );
}
