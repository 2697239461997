import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Collapse} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2)
  }
}));

export default function ErrorAlert({message}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={(message ? true : false)}>
        <Alert severity='error'>{message}</Alert>
      </Collapse>
    </div>
  );
}

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired
}
