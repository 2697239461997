import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Add, Ballot, CheckBox, CheckBoxOutlineBlank, Delete, Edit} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import themeColors from "assets/theme/colors";
import AutocompleteApi from "components/AutocompleteApi/AutocompleteApi";
import EnhancedTable from "components/DataTable/EnhancedTable";
import DropzoneUploader from "components/Dropzone/DropzoneUploader";
import Field from "components/Fields/Field";
import FieldOptions from "components/Fields/FieldOptions";
import {FileTypeClass, FileTypeIcon} from "components/FileTypes";
import {authUser, useAuthDispatch} from "contexts/Auth";
import JoditEditor from "jodit-react";
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory, useLocation} from "react-router-dom";
import {graphQLApi} from "services/GraphQLApi";
import {FieldTypes} from "variables/fields";
import {joditConfig} from "variables/general";
import {rolePriorities} from "config";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    gridTile: {
      width: 120,
      textAlign: "center"
    },
    title: {
      color: theme.palette.primary.contrastText,
      fontSize: 11,
      textAlign: "left",
    },
    titleWrap: {
      marginLeft: 3,
      marginRight: 3,
    },
    titleBar: {
      height: 20
      // background:
      //   'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    iconButton: {
      color: themeColors.primary.badgeBg,
    }
  }
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return value === index ? (
    <Paper
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{padding: "1rem", display: "flex", flexGrow: "1"}}
      {...other}
    >
      {children}
    </Paper>
  ) : null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export default function EntityEdit(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  let entityTypeID = props.match.params.entityTypeID ? props.match.params.entityTypeID : null;

  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const fieldOptionsRef = useRef();
  const editor = React.useRef(null);
  const [entity, setEntity] = React.useState({
    id: null,
    sku: "",
    name: "",
    translations: {},
    values: [],
    parent: null,
    variant_fields: [],
  });
  const [showAssetSelector, setShowAssetSelector] = React.useState(false);
  const [singleAssetSelector, setSingleAssetSelector] = React.useState(false);
  const [assets, setAssets] = React.useState([]);
  const [assetsSearch, setAssetsSearch] = React.useState("");
  const [assetSelectorField, setAssetSelectorField] = React.useState(null);
  const [showAddField, setShowAddField] = React.useState(null);
  const [showEditFieldOptions, setShowEditFieldOptions] = React.useState(null);
  const [_fieldOptionsIsLoading, setFieldOptionsIsLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("tab") && (+queryParams.get("tab") - 1) > 0) {
      setTab(+queryParams.get("tab") - 1);
      setInitialised(true);
    }
  }, []);
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("tab")) {
      setTab(+queryParams.get("tab") - 1);
    }
    else {
      setTab(0);
    }
  }, [location]);
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    if (tab === 0 && !queryParams.get("tab")) return;
    else if (tab + 1 === +queryParams.get("tab")) return;
    if (tab > 0) {
      queryParams.set("tab", tab + 1);
    }
    else if (initialised) return history.push({pathname: window.location.pathname, search: ""});

    const params = tab > 0 && queryParams.get("tab") && queryParams.get("tab") !== "" ? "?tab="+queryParams.get("tab") : "";
    if (window.location.search === params) return;
    if (params !== "") history.push({pathname: window.location.pathname, search: params});
  }, [tab, initialised]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  let id = props.match.params.id ? Number(props.match.params.id.split('?')[0]) : null;

  const [_validations, setValidations] = useState({});
  const handleApiErrors = (errors) => {
    console.log('Got error!', errors);
    let vs = {};
    if (errors) {
      errors.forEach(e => {
        if (e && e.extensions && e.extensions.validation) {
          vs = {...vs, ...e.extensions.validation};
        }
      });
      setTab(0);
    }
    setValidations(vs);
  }
  const queryFields = `
    id
    entity_type{id title}
    parent{id variant_fields{id name}}
    variant_fields{id name}
    values{
      id
      language{id}
      field{id type uses_languages}
      field_option{id title titles{language{id} translation}}
      asset{id file file_uri file_thumb file_type}
      string
      text
      bool
      integer
      decimal
      date
      related_entity{id}
    }
  `;
  const [fieldGroups, setFieldGroups] = useState([]);
  const [languageSelect, setLanguageSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(null);
  const client = new graphQLApi(useAuthDispatch(), props.history, handleApiErrors);
  const stableClient = useCallback(client, []);
  const [relatedEntityValues, setRelatedEntityValues] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);

  // Generate an object key comprised of field id and language id if the field uses language layer
  const getEntityValueFieldKey = (field, languageId) => {
    let key = field.id;
    if (['option', 'options', 'asset', 'assets'].findIndex(t => t === field.type) === -1
      && field.uses_languages) {
      key += '_';
      if (languageId > 0) {
        key += languageId;
      } else {
        key += 'null';
      }
    }
    return key;
  }

  useEffect(() => {
    setIsLoading(true);
    stableClient.query('{' +
      (id > 0 ? 'entities(filter:{id:' + id + '}){data{' + queryFields + '}} entityValues(filter:{related_entity_id:'+id+'}) {data{entity{id entity_type{id title}}}}' : '') +
      'settings(filter:{key_in:["language","country_code","locale","language_selector"]}){ data {key type data} }' +
      'languages{data{ id name country_code }}' +
      'entityTypes{data{ id title }}' +
      '}')
      .then(r => {
        if (r && r.hasOwnProperty('entityTypes')) {
          setEntityTypes(r.entityTypes.data);
        }
        if (r && r.hasOwnProperty('languages')) {
          setLanguages(r.languages.data);
        }
        if (r && r.hasOwnProperty('settings')) {
          let set = {};
          r.settings.data.forEach(s => {
            set[s.key] = s.data;
          });
          setSettings({...settings, ...set});
          if (set.language_selector !== undefined) setLanguageSelect(set.language_selector === "1");
        }
        if (r && r.hasOwnProperty('entities')) {
          let values = {};
          r.entities.data[0].values.forEach(v => {
            let fieldKey = getEntityValueFieldKey(v.field, v.language ? v.language.id : 0);
            if (v.field.type === FieldTypes.OPTIONS) {
              if (!values[fieldKey]) {
                values[fieldKey] = {field_option_ids: []};
              }
              values[fieldKey] = {
                ...v,
                field_id: v.field ? parseInt(v.field.id) : null,
                field_option_ids: [...values[fieldKey].field_option_ids, v.field_option],
                language_id: v.language ? parseInt(v.language.id) : null,
              };
            } else if (v.field.type === FieldTypes.ASSETS) {
              if (!values[fieldKey]) {
                values[fieldKey] = {assets: []};
              }
              values[fieldKey] = {
                ...v,
                field_id: v.field ? parseInt(v.field.id) : null,
                assets: [...values[fieldKey].assets, v.asset],
              };
            } else if (v.field.type.search(/^entities_\d+$/) !== -1) {
              if (!values[fieldKey]) {
                values[fieldKey] = {related_entities: []};
              }
              values[fieldKey] = {
                ...v,
                field_id: v.field ? parseInt(v.field.id) : null,
                related_entities: [...values[fieldKey].related_entities, {...v.related_entity, entity_value_id:v.id}],
              };
            } else {
              values[fieldKey] = {
                ...v,
                field_id: v.field ? parseInt(v.field.id) : null,
                field_option_id: v.field_option,
                language_id: v.language ? parseInt(v.language.id) : null,
                asset_id: v.asset ? parseInt(v.asset.id) : null,
                related_entity_id: v.related_entity ? parseInt(v.related_entity.id) : null,
              };
            }
          });
          setEntity({
            ...r.entities.data[0],
            values: values,
          });
          // setEntityValues(r.entities.data[0].values); // FIXME I do not think this is needed
          stableClient.query('{' +
            'fieldGroups(filter:{entity_type_id:'+r.entities.data[0].entity_type.id+'},sorting:"sorting",direction:"asc"){data{id name fields{id name key type uses_languages field_options{id title titles{language{id} translation} }} }}' +
            '}')
            .then(r => {
              if (r && r.hasOwnProperty('fieldGroups')) {
                setFieldGroups(r.fieldGroups.data);
              }
              setIsLoading(false);
            });
        }
        if (r && r.hasOwnProperty('entityValues')) {
          setRelatedEntityValues(r.entityValues.data.map(v => v.entity.id));
        }
        if (entityTypeID && entityTypeID) {
          stableClient.query('{' +
            'fieldGroups(filter:{entity_type_id:'+entityTypeID+'},sorting:"sorting",direction:"asc"){data{id name fields{id name key type uses_languages field_options{id title titles{language{id} translation} }} }}' +
            '}')
            .then(r => {
              if (r && r.hasOwnProperty('fieldGroups')) {
                setFieldGroups(r.fieldGroups.data);
              }
              setIsLoading(false);
            });
        }
      });
  }, [stableClient, location]);

  const updateEntityValues = (key, attributes) => {
    if (attributes) {
      setEntity({
        ...entity,
        values: {
          ...entity.values,
          [key]: {
            ...entity.values[key],
            ...attributes,
          }
        }
      });
    } else {
      setEntity({
        ...entity,
        values: {
          ...entity.values,
          [key]: null
        }
      });
    }
  }

  const saveEntity = () => {
    let vars = [];
    let args = [];
    let values = {};
    if (id) {
      vars.push('$id:ID!');
      args.push('id:$id');
      values.id = id;
    } else {
      let queryParams = new URLSearchParams(location.search);
      let relatedEntityId = parseInt(queryParams.get('related'));
      if (!isNaN(relatedEntityId)) {
        vars.push('$related_to_entity_id:ID!');
        args.push('related_to_entity_id:$related_to_entity_id');
        values.related_to_entity_id = relatedEntityId;
      }
    }
    vars.push('$parent_id:ID');
    args.push('parent_id:$parent_id');
    values.parent_id = entity.parent ? entity.parent.id : null;
    vars.push('$entity_type_id:ID');
    args.push('entity_type_id:$entity_type_id');
    values.entity_type_id = entityTypeID;
    vars.push('$variant_fields:[ID]');
    args.push('variant_fields:$variant_fields');
    values.variant_fields = entity.variant_fields ? entity.variant_fields.map(f => f.id) : [];
    vars.push('$values:[EntityValueInput]');
    args.push('values:$values');
    values.values = [];
    for (let key in entity.values) {
      let fKey = key.split("_");
      let fId = parseInt(fKey[0]);
      let lId = parseInt(fKey[1]);
      let field = entity.values[key].field;
      if (!field) for (let fgI in fieldGroups) {
        for (let fI in fieldGroups[fgI].fields) {
          if (parseInt(fieldGroups[fgI].fields[fI].id) === fId) {
            field = fieldGroups[fgI].fields[fI];
            break;
          }
        }
        if (field) break;
      }
      if (!field) {
        console.error('Value key has no field', key, fId, entity.values);
        return;
      }
      if (field.type === FieldTypes.OPTIONS) {
        if (entity.values[key].field_option_ids.length) {
          entity.values[key].field_option_ids.forEach(value => {
            values.values.push({
              field_id: fId,
              field_option_id: value.id
            });
          });
        }
        else {
          values.values.push({
            field_id: fId,
          });
        }
      }
      else if (field.type === FieldTypes.ASSETS) {
        if (entity.values[key].assets.length) {
          entity.values[key].assets.forEach(value => {
            values.values.push({
              field_id: fId,
              asset_id: value.id
            });
          });
        }
        else {
          values.values.push({
            field_id: fId,
          });
        }
      }
      else if (field.type.startsWith(FieldTypes.ENTITIES)) {
        if (entity.values[key].related_entities.length) {
          entity.values[key].related_entities.forEach(value => {
            values.values.push({
              field_id: fId,
              related_entity_id: value.id
            });
          });
        }
        else {
          values.values.push({
            field_id: fId,
          });
        }
      }
      else {
        values.values.push({
          field_id: fId,
          language_id: lId ? lId : null,
          field_option_id: entity.values[key].field_option ? entity.values[key].field_option.id : null,
          asset_id: entity.values[key].asset ? entity.values[key].asset.id : null,
          related_entity_id: entity.values[key].related_entity ? entity.values[key].related_entity.id : null,
          string: entity.values[key].string,
          text: entity.values[key].text,
          integer: entity.values[key].integer,
          decimal: entity.values[key].decimal,
          bool: entity.values[key].bool,
          date: entity.values[key].date,
        });
      }
    }
    client.mutate('(' + vars.join(',') + ') {'
      + (id ? 'entityUpdate' : 'entityCreate')
      + '(' + args.join(',') + '){' + queryFields + '}}', values)
      .then(r => {
        if (r) {
          if (!id) {
            setSnackbarMessage(intl.formatMessage({id: "product.edit.snackbar.data_created", defaultMessage: "Data was created successfully"}));
            return history.replace('/data/'+entityTypeID+'/'+r.entityCreate.id);
          }
          if (location.state && location.state.from) {
            return history.push({pathname: '/data/'+location.state.from, state: {return: true}});
          }
          props.history.goBack();
        }
      });
  }

  const handleLanguageChange = async (languageId) => {
    await setLanguage(languageId);
    fieldGroups.forEach(fg => {
      fg.fields.forEach(f => {
        let valueKey = getEntityValueFieldKey(f, languageId);
        if (f.editor) {
          f.editor.setData(entity.values[valueKey] ? entity.values[valueKey].text : "");
        }
      });
    });
  };

  const handleShowAssetSelector = (entityValue, single) => {
    setShowAssetSelector(true);
    setAssetSelectorField(entityValue);
    setSingleAssetSelector(!!single);
    searchAssetsSelector("");
  }

  const searchAssetsSelector = (query) => {
    client.query('{assets' +
      '(limit:32,sorting:"created_at",direction:"desc",filter:{q:"' + query + '"})' +
      '{data {id file file_uri file_thumb file_type}}' +
      '}').then(r => {
      if (r && r.assets) {
        setAssets(r.assets.data);
      }
    });
  }

  const debouncedSearchAssetsSelector = debounce((s) => {
    searchAssetsSelector(s);
  }, 1250);

  const getOptionLabel = (option) => {
    let r = '';
    if (language && option.titles && option.titles.length)
      r = option.titles.map(t => (t.language.id === language ? t.translation : '')).join('');
    else if (option.hasOwnProperty('title'))
      r = option.title
    return r;
  }

  const getFormField = (f, fIdx, fgIdx, languageId) => {
    if (languageId === undefined) languageId = language;
    let field = null;
    let valueKey = f.id;
    if (f.type !== FieldTypes.OPTION && f.type !== FieldTypes.OPTIONS) {
      valueKey = getEntityValueFieldKey(f, languageId);
    }
    let label = f.name;
    if (f.uses_languages) {
      let code = settings ? settings.country_code : "None";
      let name = settings ? settings.language : 'Default';
      if (languageId) {
        name = languages.find(l => l.id === languageId).name;
        code = languages.find(l => l.id === languageId).country_code.substr(0, 2);
      }
      label = (<Grid container spacing={1}>
        <Grid item style={{fontSize:"smaller"}}>
          <img src={"/flags/" + code + ".png"} title={name} alt=''/> <sup>{name}</sup>
        </Grid>
        <Grid item>{f.name}</Grid>
      </Grid>);
    }

    const actions = [
      {
        disabled: () => !id,
        tooltip: intl.formatMessage({id:"common.button.add", defaultMessage:"Add"}), 
        icon: Add,
        isFreeAction: true,
        onClick: () => {
          setShowAddDialog(true);
        }
      },
      {
        tooltip: intl.formatMessage({id:"common.button.edit", defaultMessage:"Edit"}), 
        icon: Edit,
        rowClick: true,
        onClick: (row) => {
          setIsLoading(true);
          setFieldGroups([]);
          history.push({pathname: '/data/'+row.entity_type.id+'/'+row.id, state: {from: entityTypeID+'/' + id + location.search}});
          if (tab > 0) setTab(0);
        }
      },
      {
        disabled: () => !id,
        tooltip: intl.formatMessage({id:"common.button.delete", defaultMessage:"Delete"}), 
        icon: Delete,
        onClick: (row) => {
          setSelectedEntity(row);
          setShowDeleteDialog(true);
        }
      }
    ];

    const deleteRelation = (relatedValueIds) => {
      const filteredEntity = relatedValueIds.find(e => e.id === selectedEntity.id);
      if (!filteredEntity) return;
      client.mutate(`($id:ID!) {entityValueDelete(id:$id)}`, {id: filteredEntity.value_id}).then(r => {
        if (!r) return console.log('Could not delete related entity');
        const newEntityValues = [...entity.values[valueKey].related_entities].filter(r => r.id !== selectedEntity.id);
        updateEntityValues(valueKey, {related_entities: newEntityValues});
        setShowDeleteDialog(false);
        setReload(true);
      });
    };

    const deleteEntity = (relatedValueIds) => {
      const filteredEntity = relatedValueIds.find(e => e.id === selectedEntity.id);
      if (!filteredEntity) return;
      client.mutate(`($id:ID!) {entityDelete(id:$id)}`, {id: filteredEntity.id}).then(r => {
        if (!r) return console.log('Could not delete entity');
        const newEntityValues = [...entity.values[valueKey].related_entities].filter(r => r.id !== selectedEntity.id);
        updateEntityValues(valueKey, {related_entities: newEntityValues});
        setShowDeleteDialog(false);
        setReload(true);
      });
    };

    if (f.type.search(/^relations_\d+$/) !== -1) {
      if (relatedEntityValues.length === 0) return field = null;
      const entityTypeId = f.type.split("relations_")[1];

      const transformedColumns = [];
      columns[entityTypeId].forEach(c => c.title !== 'Type' && transformedColumns.push(c));
  
      field = (
        <EnhancedTable
          {...props}
          title={f.name}
          columns={transformedColumns}
          actions={[{
            tooltip: intl.formatMessage({id:"common.button.edit", defaultMessage:"Edit"}), 
            icon: Edit,
            onClick: (row) => {
              history.push('/data/'+row.entity_type.id+'/'+row.id);
            },
            rowClick: true
          }]}
          query="entities"
          filter={"ids: [" + relatedEntityValues.join(",") + "]"}
          fields={'id entity_type{id title} parent{variant_fields{id}} variant_fields{id name} values{id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date}'}
          icon={<Ballot/>}
          children
          reload={reload}
        />
      );
    }
    
    else if (f.type.search(/^entities_\d+$/) !== -1) {
      const entityTypeId = f.type.split("entities_")[1];
      const filter = entity.values[valueKey] ? entity.values[valueKey].related_entities.map(v => v.id) : [];
      const relatedValueIds = entity.values[valueKey] ? entity.values[valueKey].related_entities.map(v => ({id: v.id, value_id: v.entity_value_id})) : [];
      if (!columns[entityTypeId]) {
        return (
          <Typography>{intl.formatMessage({id: "product.edit.label.no_listed_fields", defaultMessage: "No listed fields found for entity"})} {f.name}.</Typography>
        );
      }

      const transformedColumns = [];
      columns[entityTypeId].forEach(c => c.title !== 'Type' && transformedColumns.push(c));
      transformedColumns.shift();

      const addEntitiesHandler = (rows) => {
        let newEntityValues = [];
        if (entity.values[valueKey] && Array.isArray(entity.values[valueKey].related_entities)) {
          newEntityValues = [...entity.values[valueKey].related_entities];
        }
        Promise.all(rows.map(row => {
          const variables = {
            entity_id: "ID",
            field_id: "ID",
            related_entity_id: "ID"
          };
          const payload = {
            entity_id: id,
            field_id: f.id,
            related_entity_id: row.id
          };
          return client.mutation('entityValue', variables, payload, 'id related_entity{id}');
        })).then(results => {
          results.forEach(r => {
            if (r.hasOwnProperty('response') && r.response.hasOwnProperty('related_entity'))
              newEntityValues.push({id: r.response.related_entity.id, entity_value_id: r.response.id});
          });
          updateEntityValues(valueKey, {related_entities: newEntityValues});
          setShowAddDialog(false);
          setReload(true);
        });
      };

      const createEntityHandler = () => {
        setShowAddDialog(false);
        setIsLoading(true);
        setFieldGroups([]);
        history.push('/data/'+entityTypeId+'/create?related=' + id);
        setTimeout(() => window.location.reload(), 100);
      };

      const entityType = entityTypes.find(e => e.id === entityTypeId);

      field = (
        <>
          <EnhancedTable
            {...props}
            title={f.name}
            columns={transformedColumns}
            actions={actions}
            query="entities"
            filter={"ids: [" + filter.join(",") + "]"}
            fields={'id entity_type{id title} parent{variant_fields{id}} variant_fields{id name} values{id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date}'}
            icon={<Ballot/>}
            children
            reload={reload}
          />
          <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)} maxWidth="lg">
            <DialogTitle disableTypography><Typography variant="h2" color="primary">{intl.formatMessage({id:'product.edit.dialog.select_entities'})} {entityType && entityType.title}</Typography></DialogTitle>
            <DialogContent>
              <EnhancedTable
                columns={transformedColumns}
                actions={[
                  {
                    tooltip: intl.formatMessage({id:'common.button.add'}) + " " + (entityType && entityType.title),
                    icon: Add,
                    onSelected: true,
                    onClick: addEntitiesHandler
                  }
                ]}
                query="entities"
                filter={"entity_type_id:"+entityTypeId+", ids_not:[" + filter.join(",") + "]"}
                fields={'id entity_type{id title} parent{variant_fields{id}} variant_fields{id name} values{id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date}'}
              />
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button onClick={createEntityHandler} variant="contained" color="primary" style={{marginRight:10}} disabled={!id}>
                {intl.formatMessage({id:'product.edit.dialog.create_entity'}, {entity: entityType.title})}
              </Button>
              <Button onClick={() => setShowAddDialog(false)}>{intl.formatMessage({id:'common.button.close'})}</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
            <DialogTitle disableTypography><Typography variant="h2" color="primary">{intl.formatMessage({id:'product.edit.dialog.delete_entity'})}</Typography></DialogTitle>
            <DialogContent>{intl.formatMessage({id:'product.edit.dialog.delete.message'}, {name: selectedEntity.entity_type && selectedEntity.entity_type.title ? selectedEntity.entity_type.title : ''})}</DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
              <Box>
                <Button onClick={() => deleteRelation(relatedValueIds)} variant="contained" color="primary" style={{marginRight:10}}>{intl.formatMessage({id:'product.edit.dialog.delete_relation'})}</Button>
                <Button onClick={() => deleteEntity(relatedValueIds)} variant="contained" color="primary">{intl.formatMessage({id:'product.edit.dialog.delete_entity'})}</Button>
              </Box>
              <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
    else switch (f.type) {
      case FieldTypes.BOOLEAN:
        field = (<FormControlLabel
          control={
            <Switch
              id={f.key}
              checked={entity.values[valueKey] ? entity.values[valueKey].bool : false}
              onChange={event => updateEntityValues(valueKey, {bool: event.target.checked})}
              name={valueKey}
              color="primary"
            />
          }
          label={label}
        />);
        break;
      case FieldTypes.STRING:
        field = (<TextField
          fullWidth
          id={f.key}
          label={label}
          value={(entity.values[valueKey] && entity.values[valueKey].string) ? entity.values[valueKey].string : ""}
          onChange={event => updateEntityValues(valueKey, {string: event.target.value})}
        />);
        break;
      case FieldTypes.TEXT:
        field = (<TextField
          fullWidth
          id={f.key}
          label={label}
          multiline
          rows={5}
          value={(entity.values[valueKey] && entity.values[valueKey].text) ? entity.values[valueKey].text : ""}
          onChange={event => updateEntityValues(valueKey, {text: event.target.value})}
        />);
        break;
      case FieldTypes.NUMBER:
        field = (<TextField
          fullWidth
          id={f.key}
          label={label}
          type={"number"}
          inputProps={{step: 1}}
          value={(entity.values[valueKey] && entity.values[valueKey].integer) ? entity.values[valueKey].integer : ""}
          onChange={event => updateEntityValues(valueKey, {integer: parseInt(event.target.value)})}
        />);
        break;
      case FieldTypes.DECIMAL:
        field = (<TextField
          fullWidth
          id={f.key}
          label={label}
          type={"number"}
          inputProps={{step: 0.01}}
          value={(entity.values[valueKey] && entity.values[valueKey].decimal) ? entity.values[valueKey].decimal : ""}
          onChange={event => updateEntityValues(valueKey, {decimal: parseFloat(event.target.value)})}
        />);
        break;
      case FieldTypes.PRICE:
        field = (<Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id={f.key}
              label={label}
              type={"number"}
              inputProps={{step: 0.01}}
              value={(entity.values[valueKey] && entity.values[valueKey].decimal) ? entity.values[valueKey].decimal : ""}
              onChange={event => updateEntityValues(valueKey, {decimal: parseFloat(event.target.value)})}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id={f.key}
              label={intl.formatMessage({
                id: "entity.edit.label.price_currency",
                defaultMessage: "Currency of {field}"
              }, {field: f.name})}
              type={"string"}
              value={(entity.values[valueKey] && entity.values[valueKey].string) ? entity.values[valueKey].string : ""}
              onChange={event => updateEntityValues(valueKey, {string: event.target.value})}
            />
          </Grid>
        </Grid>);
        break;
      case FieldTypes.HTML:
        field = (
        <FormControl
          key={"field-" + valueKey + "-" + languageId}
          id={f.key}
          // error={validation[field.field].length > 0}
          fullWidth
          style={{overflow:"clip"}}
        >
          <InputLabel style={{marginBottom:10,marginTop: "-45px"}}>{label}</InputLabel>
          <JoditEditor
            ref={editor}
            config={joditConfig}
            value={entity.values[valueKey] ? entity.values[valueKey].text : ""}
            onBlur={newContent => updateEntityValues(valueKey, {text: newContent})}
          />
          {/*<FormHelperText>{validation[field.field].join(" ")}</FormHelperText>*/}
        </FormControl>);
        break;
      case FieldTypes.OPTION:
        field = (<Grid container spacing={1} alignItems="flex-end" alignContent="space-between">
          <Grid item style={{flexGrow: 1}}><Autocomplete
            fullWidth
            id={f.key}
            options={f.field_options}
            key={"field-" + valueKey + "-" + languageId}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) => option.id === value.id}
            value={entity.values[valueKey] ? entity.values[valueKey].field_option : null}
            onChange={(event, value) => updateEntityValues(valueKey, {field_option: value})}
            renderInput={(params) => <TextField
              {...params}
              label={label}
            />}
          /></Grid>
          {authUser().isAllowed(rolePriorities.can_fields) && <Grid item><IconButton onClick={() => setShowEditFieldOptions(f)}><Edit/></IconButton></Grid>}
        </Grid>);
        break;
      case FieldTypes.OPTIONS:
        field = (<Grid container spacing={1} alignItems="flex-end" alignContent="space-between">
          <Grid item style={{flexGrow: 1}}><Autocomplete
            fullWidth
            id={f.key}
            multiple
            options={f.field_options}
            key={"field-" + valueKey + "-" + languageId}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) => option.id === value.id}
            value={entity.values[valueKey] ? entity.values[valueKey].field_option_ids : []}
            onChange={(event, values) => updateEntityValues(valueKey, {field_option_ids: values})}
            renderInput={(params) => <TextField
              {...params}
              label={label}
            />}
          /></Grid>
          {authUser().isAllowed(rolePriorities.can_fields) && <Grid item><IconButton onClick={() => setShowEditFieldOptions(f)}><Edit/></IconButton></Grid>}
        </Grid>);
        break;
      case FieldTypes.ASSET:
        field = (<FormControl fullWidth>
          <FormControlLabel control={<div/>} label={
            <span>{label}
              <IconButton color={"primary"} onClick={() => handleShowAssetSelector(valueKey, true)}>
                          <Edit style={{height: 20, width: 20}}/>
                        </IconButton>
                      </span>
          }/>
          {entity.values[valueKey] && entity.values[valueKey].asset ? (
            <GridList
              style={{margin: "2px 5px"}}
              className={classes.gridList}
              cellHeight={100}
            >
              <GridListTile key={"image-" + entity.values[valueKey].asset.id}
                            style={{width: 120, textAlign: "center", cursor: "pointer"}}
                            onClick={() => window.open(entity.values[valueKey].asset.file_uri, '_blank')}>
                {FileTypeClass(null, entity.values[valueKey].asset.type, entity.values[valueKey].asset.file) === "image" ?
                  <img
                    src={entity.values[valueKey].asset.file_uri}
                    alt={entity.values[valueKey].asset.file}
                  />
                  :
                  <FontAwesomeIcon icon={FileTypeIcon(entity.values[valueKey].asset.type)} size="6x"/>
                }
                <GridListTileBar
                  title={entity.values[valueKey].asset.file}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                    titleWrap: classes.titleWrap,
                  }}
                />
              </GridListTile>
            </GridList>
          ) : (
            <FormHelperText style={{marginLeft: 5}}>No assets has been assigned yet!</FormHelperText>
          )}
        </FormControl>);
        break;
      case FieldTypes.ASSETS:
        field = (<FormControl fullWidth>
          <FormControlLabel control={<div/>} label={
            <span>{label}
              <IconButton color={"primary"} onClick={() => handleShowAssetSelector(valueKey)}>
                          <Edit style={{height: 20, width: 20}}/>
                        </IconButton>
                      </span>
          }/>
          {(!entity.values[valueKey] || !entity.values[valueKey].assets || entity.values[valueKey].assets.length === 0) ?
            <FormHelperText style={{marginLeft: 5}}>No assets has been assigned yet!</FormHelperText> :
            <GridList
              style={{margin: "2px 5px"}}
              className={classes.gridList}
              cellHeight={100}
            >
              {entity.values[valueKey].assets.map(asset =>
                <GridListTile
                  key={"image-" + fgIdx + "-" + fIdx + "-" + asset.id}
                  style={{width: 120, textAlign: "center", cursor: "pointer"}}
                  onClick={() => window.open(asset.file_uri, '_blank')}
                >
                  {asset.file_thumb ?
                    <img
                      src={asset.file_thumb}
                      alt={asset.file}
                    />
                    :
                    <FontAwesomeIcon icon={FileTypeIcon(asset.type)} size="6x"/>
                  }
                  <GridListTileBar
                    title={asset.file}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                      titleWrap: classes.titleWrap,
                    }}
                  />
                </GridListTile>
              )}
            </GridList>}
        </FormControl>);
        break;
      default: break;
    }
    return field;
  }

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    client.query('{' +
      'fields(filter:{is_listed:true}){data{id entity_type{id} type name key}}' +
      '}')
      .then(r => {
        if (r && r.hasOwnProperty('fields')) {
          const cols = {};
          r.fields.data.forEach(f => {
            let type = null;
            if (!cols[f.entity_type.id]) cols[f.entity_type.id] = [
              {
                title: intl.formatMessage({id: 'entities.list.column.entity_type', defaultMessage: 'Type'}),
                field: 'entity_type.title'
              },
              {
                title: intl.formatMessage({id: 'entities.list.column.variant_fields', defaultMessage: 'Variant fields'}),
                field: 'variant_fields',
                render: row => {
                  if (row.parent) {
                    return row.parent.variant_fields.map(f => {
                      let value = row.values.find(pv => pv.field.id === f.id);
                      if (value && value.field_option) {
                        return (<Chip key={"variant_field_" + f.id + "_value_" + value.id + "_" + row.id}
                                      style={{marginRight: 3}} size={"small"} variant={"outlined"}
                                      label={value.field_option.title}/>)
                      }
                      return row.name;
                    })
                  } else if (row.variant_fields && row.variant_fields.length) {
                    return row.variant_fields.map(f => {
                      return (<Chip key={"variant_field_" + f.id + "_" + row.id} style={{marginRight: 3}} size={"small"}
                                    variant={"outlined"} label={f.name}/>)
                    })
                  }
                  return '';
                }
              }
            ];
            switch (f.type) {
              case FieldTypes.BOOLEAN:
                type = 'boolean';
                break;
              case FieldTypes.NUMBER:
              case FieldTypes.DECIMAL:
                type = 'number';
                break;
              case FieldTypes.PRICE:
                type = 'currency';
                break;
              default:
                type = 'string';
                break;
            }
            cols[f.entity_type.id].push({
              field: f.key,
              sortable: false,
              title: f.name, sorting: false, search: false, type: type,
              align: [FieldTypes.PRICE, FieldTypes.DECIMAL, FieldTypes.NUMBER].findIndex(t => t === f.type) === -1 ? 'left' : 'right',
              render: (row => {
                let pVal = row.values ? row.values.find(pv => pv.field.id === f.id) : null;
                if (!pVal) return "";
                switch (f.type) {
                  case FieldTypes.BOOLEAN:
                    return pVal.bool;
                  case FieldTypes.STRING:
                    return pVal.string;
                  case FieldTypes.TEXT:
                    return pVal.text.substr(0, 50);
                  case FieldTypes.HTML:
                    return pVal.text === '<p></p>' ? "Empty" : "Filled";
                  case FieldTypes.PRICE:
                    return intl.formatNumber(pVal.decimal, {
                      style: 'currency',
                      currency: pVal.string,
                      currencyDisplay: 'code',
                      minimumFractionDigits: 2
                    });
                  case FieldTypes.DECIMAL:
                    return intl.formatNumber(pVal.decimal);
                  case FieldTypes.NUMBER:
                    return pVal.integer;
                  case FieldTypes.OPTION:
                    return pVal.field_option ? pVal.field_option.title : '';
                  case FieldTypes.ASSET:
                    return <img height={24} src={pVal.asset.file_thumb} alt=''/>;
                  case FieldTypes.ASSETS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => (pv.field.id === f.id && pv.asset && pv.asset.file_thumb !== ""))
                        .map((pv, pvIdx) => <img key={"column-" + f.id + "-image-" + pvIdx} height={24}
                                                 style={{marginLeft: 3}} src={pv.asset.file_thumb} alt=''/>)
                  case FieldTypes.OPTIONS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => pv.field.id === f.id)
                        .map((pv, pvIdx) => <Chip key={"column-" + f.id + "-option-" + pvIdx} size={"small"}
                                                  style={{marginLeft: 3}} label={pv.field_option.title}/>)
                  default:
                    return '';
                }
              }),
            });
          });
          setColumns(cols);
          setIsLoading(false);
        }
      });
  }, []);

  return (<Grid container spacing={2} justifyContent={"flex-end"}>
      <Grid item xs={2}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbarMessage !== null}
          autoHideDuration={6000}
          onClose={() => setSnackbarMessage(null)}
          message={snackbarMessage ? snackbarMessage : ''}
        />
        {(languageSelect && settings) ? <div style={{display: "flex", justifyContent: "space-evenly", marginBottom: "1rem"}}>
          <img onClick={() => handleLanguageChange(null)}
               src={"/flags/" + settings.country_code.toUpperCase() + ".png"}
               title={settings.language}
               style={{boxShadow: language === null ? "0px 0px 7px grey" : "", cursor: "pointer"}} alt=''/>
          {languages.map((l, k) =>
            <img key={'language-select-' + k}
                 onClick={() => handleLanguageChange(l.id)}
                 src={"/flags/" + l.country_code.toUpperCase() + ".png"}
                 title={l.name}
                 style={{boxShadow: language === l.id ? "0px 0px 7px grey" : "", cursor: "pointer"}} alt=''/>
          )}
        </div> : null}
        {/*<div className={classes.root}>*/}
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleChangeTab}
          aria-label="Field groups"
          style={{marginRight: "5px"}}
        >{fieldGroups.map((fg, idx) =>
          <Tab key={'field-group-tab-' + idx} label={fg.name} {...a11yProps(idx)} />
        )}</Tabs>
      </Grid>
      <Grid item xs={10}>
        {fieldGroups.map((fg, fgIdx) => <TabPanel key={'field-group-panel-' + fgIdx} value={tab} index={fgIdx}>
          <Grid container spacing={1}>
            {fgIdx === 0 ?
              <Grid item xs={12}>
                <AutocompleteApi
                  multiple
                  disabled={entity.parent !== null}
                  id={"variant_fields"}
                  label={intl.formatMessage({
                    id: "entity.edit.label.variant_fields"
                  })}
                  query={"fields"}
                  titleField={"name"}
                  filter={'type:"' + FieldTypes.OPTION + '", entity_type_id:' + (entity.entity_type && entity.entity_type.id ? entity.entity_type.id : entityTypeID ? entityTypeID : '')}
                  value={entity.parent ? entity.parent.variant_fields : entity.variant_fields}
                  onChange={(e, v) => setEntity({...entity, variant_fields: v})}
                />
              </Grid> : null}
            {fgIdx === 0 ?
              <Grid item xs={12}>
                <AutocompleteApi
                  disabled={entity.variant_fields.length}
                  id={"parent_id"}
                  label={intl.formatMessage({
                    id: "entity.edit.label.parent",
                    defaultMessage: "Variation of"
                  })}
                  query={"entities"}
                  titleField={["id"]}
                  filter={"is_variant:true" + (id ? ",id_ne:" + id : "")}
                  extraFields={" variant_fields{id name}"}
                  value={entity.parent}
                  onChange={(e, v) => setEntity({...entity, parent: v})}
                />
              </Grid> : null}
            {fg.fields.map((f, fIdx) => {
              if (!languageSelect && f.uses_languages && ![FieldTypes.ENTITY,FieldTypes.ENTITIES,FieldTypes.OPTION,FieldTypes.OPTIONS,FieldTypes.ASSET,FieldTypes.ASSETS].find(t => t === f.type)) {
                return [undefined, ...languages.map(l => l.id)].map(l => <Grid key={"form-field-" + fgIdx + "-" + fIdx + "-" + l} item xs={12}>{getFormField(f, fIdx, fgIdx, l)}</Grid>);
              }
              return <Grid key={"form-field-" + fgIdx + "-" + fIdx} item xs={12}>{getFormField(f, fIdx, fgIdx)}</Grid>;
            })}
          </Grid>
        </TabPanel>)}
        {/*</div>*/}
      </Grid>
      {authUser().isAllowed(rolePriorities.can_fields) && <Grid item>
        <Button onClick={() => setShowAddField(true)}>
          {intl.formatMessage({id: "entity.edit.button.add_field", defaultMessage: "Add field"})}
        </Button>
      </Grid>}
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.history.goBack}>
          {intl.formatMessage({id: "common.button.back"})}
        </Button>
      </Grid>
      {authUser().isAllowed(rolePriorities.can_data) && <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={saveEntity}
          disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={17} color="inherit"/>
          ) : (
            <FormattedMessage id={"common.button.save"}/>
          )}
        </Button>
      </Grid>}
      <Dialog open={showAssetSelector} onClose={() => setShowAssetSelector(false)} maxWidth={"md"}>
        <DialogContent>
          {authUser().isAllowed(rolePriorities.can_assets) && <DropzoneUploader onUploaded={_files => {
            searchAssetsSelector(assetsSearch);
          }} mutation={"asset"}/>}
            <DialogTitle>
              <TextField
                fullWidth
                label={"Search"}
                value={assetsSearch}
                onChange={event => {
                  event.stopPropagation();
                  setAssetsSearch(event.target.value);
                  debouncedSearchAssetsSelector(event.target.value)
                }}
              />
            </DialogTitle>
            <GridList
              cols={4}
              cellHeight={100}
              spacing={5}
            >
              {assets.map((asset) => {
                let isSelected = false;
                if (entity.values[assetSelectorField]) {
                  if (singleAssetSelector) {
                    isSelected = entity.values[assetSelectorField].asset.id === asset.id;
                  } else {
                    isSelected = entity.values[assetSelectorField].assets.findIndex(af => af.id === asset.id) !== -1;
                  }
                }
                return (
                  <GridListTile
                    key={"assets-" + asset.id}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {asset.file_thumb ?
                      <img
                        src={asset.file_thumb}
                        alt={asset.file}
                      />
                      :
                      <FontAwesomeIcon icon={FileTypeIcon(asset.type)} size="6x"/>
                    }
                    <GridListTileBar
                      title={asset.file}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                        titleWrap: classes.titleWrap,
                      }}
                      actionPosition="left"
                      actionIcon={<IconButton className={classes.iconButton} onClick={e => {
                        e.stopPropagation();
                        if (singleAssetSelector) {
                          updateEntityValues(assetSelectorField, {asset: asset});
                        } else {
                          let selectedAssets = entity.values[assetSelectorField] ? [...entity.values[assetSelectorField].assets] : [];
                          if (isSelected) {
                            selectedAssets = selectedAssets.filter(a => a.id !== asset.id);
                          } else {
                            selectedAssets.push(asset);
                          }
                          updateEntityValues(assetSelectorField, {assets: selectedAssets});
                        }
                      }}>
                        {isSelected ?
                          <CheckBox style={{height: 20, width: 20}}/>
                          :
                          <CheckBoxOutlineBlank style={{height: 20, width: 20}}/>
                        }
                      </IconButton>}
                    />
                  </GridListTile>
                )
              })}
            </GridList>
        </DialogContent>
      </Dialog>
      <Dialog open={showAddField !== null} maxWidth={"md"}>
        <DialogContent>
          <Field
            field_group_id={fieldGroups[tab] ? fieldGroups[tab].id : null}
            onSaved={field => {
              if (!fieldGroups.length) return;
              let fg = [...fieldGroups];
              fg[tab].fields = [...fg[tab].fields, {
                ...field,
                field_options: [],
              }];
              setFieldGroups(fg);
              setShowAddField(null);
            }}
            buttons={[
              {label: intl.formatMessage({id: "common.button.cancel"}), onClick: () => setShowAddField(null)}
            ]}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={showEditFieldOptions !== null} maxWidth={"xl"}>
        <DialogTitle>{intl.formatMessage({
          id: "entity.edit.dialog.edit-options.title",
          defaultMessage: "Edit field options"
        })}</DialogTitle>
        <DialogContent>
          <FieldOptions field={showEditFieldOptions} ref={fieldOptionsRef} isSaving={setFieldOptionsIsLoading}/>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (fieldOptionsRef.current && fieldOptionsRef.current.hasOwnProperty('addOption'))
                fieldOptionsRef.current.addOption();
            }}>{intl.formatMessage({id: "field_options.button.add", defaultMessage: "Add option"})}</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setShowEditFieldOptions(null)}>{intl.formatMessage({id: "common.button.cancel"})}</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (fieldOptionsRef.current && fieldOptionsRef.current.hasOwnProperty('saveOptions')) {
                fieldOptionsRef.current.saveOptions().then(opts => {
                  let fIdx = fieldGroups[tab].fields.findIndex(f => f.id === showEditFieldOptions.id);
                  let fg = [...fieldGroups];
                  fg[tab].fields[fIdx] = {
                    ...fg[tab].fields[fIdx],
                    field_options: opts,
                  };
                  setFieldGroups(fg);
                  // Update the field selected value, in case a selected value has been deleted
                  let valueKey = getEntityValueFieldKey(showEditFieldOptions, language);
                  if (entity.values[valueKey]
                    && entity.values[valueKey].field_option_ids) {
                    if (Array.isArray(entity.values[valueKey].field_option_ids)) {
                      updateEntityValues(valueKey, {
                        field_option_ids: entity.values[valueKey].field_option_ids.filter(o => opts.findIndex(o2 => o2.id === o.id) !== -1)
                      });
                    } else {
                      if (!opts.find(o => o.id === entity.values[valueKey].field_option_ids.id)) {
                        updateEntityValues(valueKey, null);
                      }
                    }
                  }
                  setShowEditFieldOptions(null);
                });
              } else {
                setShowEditFieldOptions(null);
              }
            }}>{intl.formatMessage({id: "common.button.save"})}</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}