
export function SmartwebTest(intl) {
  return {
    'username': [intl.formatMessage({id: "connector.driver.smartweb.test.username", defaultMessage: "Connection test failed, check username setting."})],
    'password': [intl.formatMessage({id: "connector.driver.smartweb.test.password", defaultMessage: "Connection test failed, check password setting."})],
  }
}

export default function Smartweb(intl) {
  let fields = [
    {
      field: "username",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smartweb.username", defaultMessage: "Username"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "password",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.smartweb.password", defaultMessage: "Password"}),
      input: "password",
      inputProps: {autoComplete: "off"}
    }
  ];
  return fields;
}
