import React from "react";
import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";
import {Extension} from "@material-ui/icons";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {getFieldTypeOptions} from "variables/fields";

export default function FieldList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'fields.list.column.name', defaultMessage: 'Name'}), field: 'name'},
    {title: intl.formatMessage({id: 'fields.list.column.type', defaultMessage: 'Type'}), field: 'type', render: row => {
        let type = getFieldTypeOptions(intl).find(t => t.id === row.type);
        if (type) return type.name;
        return "-";
      }},
    {title: intl.formatMessage({id: 'fields.list.column.is_listed', defaultMessage: 'Listed'}), field: 'is_listed', type: 'boolean'},
    {
      title: intl.formatMessage({id: 'fields.list.column.entity_type', defaultMessage: 'Data type'}),
      field: 'entity_type.title',
    },
    {
      title: intl.formatMessage({id: 'fields.list.column.created_at', defaultMessage: 'Created at'}),
      field: 'created_at',
    }
  ];

  return (<Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'fields.list.table.heading', defaultMessage: "Fields list"})}
        columns={columns}
        query="fields"
        mutations="field"
        fields='id entity_type{title} type name key uses_languages is_listed created_at'
        icon={<Extension/>}
        urlState
        {...props}
      />
    </Paper>
  );
}