import React from "react";
import {Box, LinearProgress, Paper} from "@material-ui/core";
import {SwapHoriz} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";

// function CircularProgressWithLabel(props) {
//   return (
//     <Box position="relative" display="inline-flex">
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         top={0}
//         left={0}
//         bottom={0}
//         right={0}
//         position="absolute"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//       >
//         {`${Math.round(props.value,)}%`}
//       </Box>
//     </Box>
//   );
// }

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box width="100%" mr={1} alignItems="center" justifyContent="center">
        <LinearProgress variant="determinate" style={{marginBottom:0}} {...props} />
      </Box>
      <Box minWidth={35}>
        {props.value}
      </Box>
    </Box>
  );
}

export default function ChannelList(props) {
  const intl = useIntl();

  const renderConnectorTitle = (row, type) => {
    if (row[type+'_connector']) {
      return row[type+'_connector'].title;
    }
    else {
      return 'OpenDIMS';
    }
  }

  const columns = [
    {title: intl.formatMessage({id: 'channels.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'channels.list.column.source', defaultMessage: 'Source'}),
      field: 'source_connector.title',
      type: 'string',
      filtering: false,
      render: (row) => renderConnectorTitle(row, 'source')
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.destination', defaultMessage: 'Destination'}),
      field: 'destination_connector.title',
      type: 'string',
      filtering: false,
      render: (row) => renderConnectorTitle(row, 'destination')
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.list', defaultMessage: 'List'}),
      render: row => row.listing ? row.listing.title : '',
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.schedule', defaultMessage: 'Schedule'}),
      field: 'schedule',
      render: row => row.schedule ? row.schedule_options.find(v => v.id === row.schedule).title : ''
    },
    {
      title: intl.formatMessage({id: 'channels.list.column.job_progress', defaultMessage: 'Job Progress'}),
      field: 'progress_total',
      render: row => row.job_id > 0 ? <LinearProgressWithLabel value={row.progress_total ? Math.round(row.progress / row.progress_total * 100) : row.progress} variant={row.progress_total ? "determinate" : "indeterminate"}/> : ''
    }
  ];

  return (
    <Paper>
      <EnhancedTable
        {...props}
        title={intl.formatMessage({id: 'channels.list.table.heading', defaultMessage: "Channels"})}
        columns={columns}
        query="channels"
        mutations="channel"
        fields='id title listing{title} source_connector{title} destination_connector{title} schedule schedule_options{id title} job_id progress progress_total'
        icon={<SwapHoriz/>}
        urlState
      />
    </Paper>
  );
}