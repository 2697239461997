import CsvFile from "./ConnectorDrivers/CsvFile";
import XmlFile from "./ConnectorDrivers/XmlFile";
import FtpFileServer, {FtpFileServerTest} from "./ConnectorDrivers/FtpFileServer";
import NettButikk, {NettButikkTest} from "./ConnectorDrivers/NettButikk";
import Smartweb, {SmartwebTest} from "views/Admin/Settings/ConnectorDrivers/Smartweb";
import DxlFile from "views/Admin/Settings/ConnectorDrivers/DxlFile";

export function getConnectorDrivers(intl) {
  return [
    // {
    //   id: "Test",
    //   name: intl.formatMessage({id: "connector.driver.test.title", defaultMessage: "Test"})
    // },
    {
      id: "CsvFile",
      name: intl.formatMessage({id: "connector.driver.csv_file.title", defaultMessage: "CSV File"})
    },
    {
      id: "XmlFile",
      name: intl.formatMessage({id: "connector.driver.xml_file.title", defaultMessage: "XML File"})
    },
    {
      id: "DxlFile",
      name: intl.formatMessage({id: "connector.driver.dxl_file.title", defaultMessage: "Lotus Notes DXL File"})
    },
    {
      id: "FtpFileServer",
      name: intl.formatMessage({id: "connector.driver.ftp_file_upload.title", defaultMessage: "File on an FTP server"})
    },
    {
      id: "Smartweb",
      name: intl.formatMessage({id: "connector.driver.smartweb.title", defaultMessage: "Smartweb Shop API"})
    },
    {
      id: "NettButikk",
      name: intl.formatMessage({id: "connector.driver.nett_butikk.title", defaultMessage: "24NettButikk Shop API"})
    },
  ]
}
export const driverFields = {
  // "App\\Services\\ConnectorDrivers\\Test": () => [],
  "DxlFile": DxlFile,
  "CsvFile": CsvFile,
  "XmlFile": XmlFile,
  "FtpFileServer": FtpFileServer,
  "Smartweb": Smartweb,
  "NettButikk": NettButikk,
};
export const driverTestFields = {
  // "App\\Services\\ConnectorDrivers\\Test": () => [],
  "DxlFile": () => {},
  "CsvFile": () => {},
  "XmlFile": () => {},
  "FtpFileServer": FtpFileServerTest,
  "Smartweb": SmartwebTest,
  "NettButikk": NettButikkTest,
};
