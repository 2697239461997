import React, {useEffect, useRef, useState} from "react";
import {Chip, CircularProgress, Grid, Paper, Snackbar, Typography} from "@material-ui/core";
import {Ballot, Print} from "@material-ui/icons";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {FieldTypes} from "variables/fields";
import EnhancedTable from "components/DataTable/EnhancedTable";
import Listings from "./components/Listings";
import SelectPrintoutDialog from "views/Entities/components/SelectPrintoutDialog";
import {config} from "config";

export default function EntityList(props) {
  const intl = useIntl();
  const tableRef = useRef();

  let entityTypeID = props.match.params.entityTypeID ? props.match.params.entityTypeID : null;

  const [listingId, setListingId] = useState(null);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = new graphQLApi(useAuthDispatch(), props.history);
  useEffect(() => {
    if (!entityTypeID) return;
    setIsLoading(true);
    setListingId(null);
    client.query('{' +
      'entities(filter:{entity_type_id:' + entityTypeID + ' parent_id_ne:null},limit:1){total}' +
      'fields(filter:{entity_type_id:' + entityTypeID + ' is_listed:true}){data{id entity_type{id} type name key}}' +
      '}')
      .then(r => {
        let cols = [];
        if (!listingId && !entityTypeID) {
          cols.push({
            title: intl.formatMessage({id: 'entities.list.column.entity_type', defaultMessage: 'Type'}),
            field: 'entity_type.title'
          });
        }
        if (r && r.hasOwnProperty('entities') && r.entities.total > 0) {
          cols.push({
            title: intl.formatMessage({id: 'entities.list.column.variant_fields', defaultMessage: 'Variant fields'}),
            field: 'variant_fields',
            render: row => {
              if (row.parent) {
                return row.parent.variant_fields.map(f => {
                  let value = row.values.find(pv => pv.field.id === f.id);
                  if (value && value.field_option) {
                    return (<Chip key={"variant_field_" + f.id + "_value_" + value.id + "_" + row.id}
                                  style={{marginRight: 3}} size={"small"} variant={"outlined"}
                                  label={value.field_option.title}/>)
                  }
                  return row.name;
                })
              } else if (row.variant_fields && row.variant_fields.length) {
                return row.variant_fields.map(f => {
                  return (<Chip key={"variant_field_" + f.id + "_" + row.id} style={{marginRight: 3}} size={"small"}
                                variant={"outlined"} label={f.name}/>)
                })
              }
              return '';
            }
          });
        }
        if (r && r.hasOwnProperty('fields')) {
          r.fields.data.forEach(f => {
            let type = null;
            if (entityTypeID && entityTypeID !== f.entity_type.id) return;
            switch (f.type) {
              case FieldTypes.BOOLEAN:
                type = 'boolean';
                break;
              case FieldTypes.NUMBER:
              case FieldTypes.DECIMAL:
                type = 'number';
                break;
              case FieldTypes.PRICE:
                type = 'currency';
                break;
              default:
                type = 'string';
                break;
            }
            cols.push({
              field: 'field.' + f.id,
              title: f.name, sorting: false, search: false, type: type,
              align: [FieldTypes.PRICE, FieldTypes.DECIMAL, FieldTypes.NUMBER].findIndex(t => t === f.type) === -1 ? 'left' : 'right',
              render: (row => {
                let pVal = row.values ? row.values.find(pv => pv.field.id === f.id) : null;
                if (!pVal) return "";
                switch (f.type) {
                  case FieldTypes.BOOLEAN:
                    return pVal.bool;
                  case FieldTypes.STRING:
                    return pVal.string;
                  case FieldTypes.TEXT:
                    return pVal.text.substring(0, 50);
                  case FieldTypes.HTML:
                    return pVal.text === '<p></p>' ? "Empty" : "Filled";
                  case FieldTypes.PRICE:
                    return intl.formatNumber(pVal.decimal, {
                      style: 'currency',
                      currency: pVal.string,
                      currencyDisplay: 'code',
                      minimumFractionDigits: 2
                    });
                  case FieldTypes.DECIMAL:
                    return intl.formatNumber(pVal.decimal);
                  case FieldTypes.NUMBER:
                    return pVal.integer;
                  case FieldTypes.OPTION:
                    return pVal.field_option ? pVal.field_option.title : '';
                  case FieldTypes.ASSET:
                    return <img height={24} src={pVal.asset.file_thumb} alt=''/>;
                  case FieldTypes.ASSETS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => (pv.field.id === f.id && pv.asset && pv.asset.file_thumb !== ""))
                        .map((pv, pvIdx) => <img key={"column-" + f.id + "-image-" + pvIdx} height={24}
                                                 style={{marginLeft: 3}} src={pv.asset.file_thumb} alt=''/>)
                  case FieldTypes.OPTIONS:
                    return !row.values ? '' :
                      row.values
                        .filter(pv => pv.field.id === f.id)
                        .map((pv, pvIdx) => <Chip key={"column-" + f.id + "-option-" + pvIdx} size={"small"}
                                                  style={{marginLeft: 3}} label={pv.field_option.title}/>)
                  default:
                    return '';
                }
              }),
            });
          });
        }
        setColumns(cols);
        // setIsLoading(false);
      });
  }, [entityTypeID]);


  const [showSelectLayout, setShowSelectLayout] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});

  const actions = [
    {
      tooltip: intl.formatMessage({id: "common.button.print", defaultMessage: "Print"}),
      icon: Print,
      onClick: row => {
        setShowSelectLayout(true);
        setSelectedEntity(row);
      }
    }
  ];

  const selectLayoutHandler = (printoutId) => {
    setShowSelectLayout(false);
    window.open('/printout/'+printoutId+'?id='+selectedEntity.id, '_blank');
  };

  return (entityTypeID > 0 ? <>
      <Listings
        entityTypeId={entityTypeID}
        history={props.history}
        onChange={listing => {
          setListingId(listing ? listing.id : null);
          if (tableRef.current && listing && listing.id)
            tableRef.current.update();
        }}
        setIsLoading={setIsLoading}
      />
      {!isLoading ? <Paper style={{marginLeft: 250}}>
        <Snackbar
          open={isLoading}
          anchorOrigin={{vertical: "top", horizontal: "center"}}
          message={"Loading..."}
          action={<CircularProgress color="inherit"/>}
        />
        <EnhancedTable
          {...props}
          ref={tableRef}
          title={intl.formatMessage({id: 'entities.list.table.heading', defaultMessage: "Data list"})}
          columns={columns}
          query="entities"
          filter={listingId > 0 ? ("listing_id:" + listingId) : ("entity_type_id:" + entityTypeID)}
          mutations="entity"
          actions={actions}
          fields={'id entity_type{id title} parent{variant_fields{id}} variant_fields{id name} values(locale:"' + window.language + '"){id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date}'}
          icon={<Ballot/>}
          children
          urlState
        />
        <SelectPrintoutDialog open={showSelectLayout} onClose={() => setShowSelectLayout(false)} entity={selectedEntity} onSelect={selectLayoutHandler} />
      </Paper> : <Grid container justifyContent="center" style={{marginLeft: 250, marginTop:250}}><Grid item><CircularProgress/></Grid></Grid> }
    </> : <Grid container justifyContent="center" style={{height: 200, marginTop: 200}}>
      <Grid item style={{textAlign: "center"}}>
        <img alt={"OpenDIMS - Data Information Management System"} src={config.brandLogo.default} height={175}/>
        <Typography variant="h1" style={{marginTop: 25}}>{intl.formatMessage({
          id: "entities.list.select_type",
          defaultMessage: "Please select a data type in the data sub menu."
        })}</Typography>
      </Grid>
    </Grid>
  );
}