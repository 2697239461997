const themeColors = {
  white: {
    main: "#FFFFFF",
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  primary: {
    main: "#202946",
    light: "#4a5172",
    dark: "#00001f",
    snackbar: "#4a5172",
    badgeBg: "#eaecfb",
    badgeBgHover: "#4a5172",
    badge: "#202946",
    text: "#f3f3f3"
  },
  secondary: {
    main: "#880e4f",
    light: "#bc477b",
    dark: "#560027",
    snackbar: "#c7acb8",
    badgeBgHover: "#a42f64",
    btnOutline: "#bc477b",
    btnActive: "#d2e3ee",
    text: "#1c1c1c"
  },
  warning: {
    light: "#ffd600",
    main: "#febc00",
    snackbar: "#fff394",
    badgeBg: "#fffde5",
    badgeBgHover: "#fce400",
    badge: "#febc00",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#11cdef",
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#f8f9fe",
  },
  text: {
    primary: "#525f7f",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#172b4d",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  success: {
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
};

export default themeColors;
