import PropTypes from 'prop-types';
import {Dialog, DialogTitle, Button, Typography, InputLabel, Select, MenuItem, Grid, FormControl} from '@material-ui/core';
import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  }
}))

export default function EditFieldDialog({open, title, label, fields, onClickClose, initialValue}) {
  const intl = useIntl();
  const [select, setSelect] = useState(null);
  const classes = useStyles();

  function handleClose() {
    onClickClose();
  }

  function handleSelect() {
    select
        ? onClickClose(select)
      : onClickClose();
  }

  return (
    <Dialog PaperProps={{style: {padding: "20px"}}} maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="edit-dialog-title">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DialogTitle id="edit-dialog-title">
            <Typography variant="h2" component="span">{title}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="edit-dialog-form-label">{label}</InputLabel>
              <Select
                labelId="edit-dialog-form-label"
                onChange={e => setSelect(e.target.value)}
                value={(!select ? fields.find(field => field.id === initialValue.id) : select)}
              >
                {fields.map((field, index) => {
                return <MenuItem key={`edit-${index}`} value={field}>{field.name} | {field.type}</MenuItem>;
                })}
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={handleClose}>{intl.formatMessage({id: "common.button.close", defaultMessage: "Close"})}</Button>
          <Button color="primary" onClick={handleSelect}>{intl.formatMessage({id: "common.button.select", defaultMessage: "Select"})}</Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditFieldDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}
