import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { CloudUpload, Delete, OpenInNew } from '@material-ui/icons'
import AutocompleteApi from '../AutocompleteApi/AutocompleteApi'
import JoditEditor from 'jodit-react'

import { joditConfig } from 'variables/general'
import moment from 'moment'
import { alpha2ToAlpha3, getNames, registerLocale } from 'i18n-iso-countries'

registerLocale(require("i18n-iso-countries/langs/en.json"));
registerLocale(require("i18n-iso-countries/langs/da.json"));

export default function EditForm(props) {
  const editor = React.useRef(null);

  React.useEffect(() => {
    const elements = document.getElementsByClassName("jodit-status-bar-link");
    if (elements.length === 1) elements[0].parentElement.remove();
  }, []);

  const initialCountries = getNames(window.language, {select: "official"});
  const countries = Object.keys(initialCountries).map((key) => ({code: key, value: initialCountries[key]}));

  const intl = useIntl();
  const {
    fields,
    data,
    setData,
    cols = 2,
    colSizes,
    isLoading,
    save,
    validation,
    buttons = [],
    extraComponent = null,
  } = props;

  const onChangeSetData = (field, value, key = null) => {
    if (field.key) {
      setData({
        ...data,
        [field.field]: {
          ...data[field.field],
          [key]: value ? value : field.initial,
        },
      });
    } else {
      setData({
        ...data,
        [field.field]: value !== undefined ? value : field.initial,
      });
    }
    if (field.onChange) {
      field.onChange(value, data, setData);
    }
  };
  let fullWidths = [];
  const chunks = (arr, chunkSize) => {
    var R = [];
    var a = arr.filter((v) => !v.hasOwnProperty("column") && !v.fullWidth);
    fullWidths = arr.filter((v) => v.fullWidth);
    for (var i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(a.slice(i, i + chunkSize));
    }
    for (let c = 0; c < arr.length; c++) {
      for (i = 0; i < R.length; i++) {
        if (arr[c].hasOwnProperty("column") && arr[c].column === i + 1)
          R[i].push(arr[c]);
      }
    }
    return R;
  };
  const fieldChunks = chunks(fields, Math.ceil(fields.length / cols));
  let columnSizes = [];
  if (Array.isArray(colSizes)) {
    columnSizes = colSizes;
  } else {
    for (let i = 0; i < cols; i++) {
      columnSizes[i] = Math.floor(12 / cols);
    }
  }

  const handleFileUpload = (field, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let n = {};
      n[field] = file.name;
      n[field + "_data"] = reader.result;
      setData({...data, ...n});
    };
  };

  const getFormField = (field, key) => {
    if (field.type === "ID" || field.type === "[ID]") {
      let filter = field.filter ? field.filter : "";
      if (field.filterBy) {
        if (
          data[field.filterBy] !== null &&
          data[field.filterBy].id !== undefined
        ) {
          filter +=
            field.filterBy + ":" + JSON.stringify(data[field.filterBy].id);
        } else {
          filter += field.filterBy + ":" + JSON.stringify(data[field.filterBy]);
        }
      }
      if (field.disabled === undefined) {
        field.disabled = false;
      }
      if (field.filterBy) {
        field.disabled = data[field.filterBy] === null;
      }
      return (
        <AutocompleteApi
          multiple={field.type === "[ID]"}
          key={"form-field-" + field.field + "-" + key}
          disabled={
            isLoading ||
            (typeof field.disabled === "function"
              ? field.disabled(data)
              : field.disabled)
          }
          id={field.field}
          label={field.label}
          query={field.query}
          filter={filter}
          titleField={field.titleField}
          renderOption={field.renderOption}
          value={data[field.field]}
          sorting={field.sorting}
          extraFields={field.extraFields}
          onChange={(e, v) => onChangeSetData(field, v)}
          textFieldProps={{
            error: validation[field.field].length > 0,
          }}
        />
      );
    } else if (field.query !== undefined && field.key !== undefined) {
      if (data[field.field + "_" + field.key] === undefined) return "";
      return data[field.field + "_" + field.key].map((value, k) => (
        <FormControl
          fullWidth
          error={validation[field.field].length > 0}
          key={"form-field-" + field.field + "-" + key + "-" + k}
        >
          <InputLabel id={"form-field-" + field.field + "-label-" + k}>
            {field.label(value)}
          </InputLabel>
          <Select
            disabled={
              typeof field.disabled === "function" && field.disabled(data)
            }
            labelId={"form-field-" + field.field + "-label-" + k}
            id={field.field + "_" + value[field.key]}
            value={
              data[field.field][field.keyValueFromQuery(value)]
                ? data[field.field][field.keyValueFromQuery(value)]
                : ""
            }
            onChange={(e) =>
              onChangeSetData(
                field,
                e.target.value,
                field.keyValueFromQuery(value)
              )
            }
          >
            {field.options(value).map((prop, key) => (
              <MenuItem
                key={"form-field-" + field.field + "-item-" + k + "-" + key}
                value={prop.id}
              >
                {prop.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{validation[field.field].join(" ")}</FormHelperText>
        </FormControl>
      ));
    } else if (Array.isArray(field.options) && field.options.length) {
      return (
        <FormControl fullWidth error={validation[field.field].length > 0}>
          <InputLabel id={"form-field-" + field.field + "-label"}>
            {field.label}
          </InputLabel>
          <Select
            disabled={
              typeof field.disabled === "function" && field.disabled(data)
            }
            key={"form-field-" + field.field + "-" + key}
            labelId={"form-field-" + field.field + "-label"}
            id={field.field}
            value={data[field.field]}
            onChange={(e) => onChangeSetData(field, e.target.value)}
          >
            {field.options.map((prop, key) => (
              <MenuItem
                key={"form-field-" + field.field + "-item-" + key}
                value={prop.id}
              >
                {prop.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{validation[field.field].join(" ")}</FormHelperText>
        </FormControl>
      );
    } else if (field.input === "file") {
      return (
        <FormControl fullWidth error={validation[field.field].length > 0}>
          {data[field.field] ? (
            <TextField
              label={field.label}
              id={field.field}
              defaultValue={data[field.field]}
              InputProps={{
                readOnly: true,
                startAdornment: data[field.field + "_uri"] ? (
                  <OpenInNew
                    cursor="pointer"
                    fontSize="small"
                    onClick={() => window.open(data[field.field + "_uri"])}
                  />
                ) : undefined,
                endAdornment: (
                  <Delete
                    cursor="pointer"
                    fontSize="small"
                    onClick={() => setData({...data, [field.field]: ""})}
                  />
                ),
              }}
            />
          ) : (
            <><Button startIcon={<CloudUpload/>} variant="contained" component="label">
              <FormattedMessage
                id="common.button.upload-label"
                defaultMessage="Upload {label}"
                values={{label: field.label}}
              />
              <Input
                style={{display: "none"}}
                id={field.field}
                type="file"
                inputProps={{
                  accept: field.accept,
                }}
                onChange={(e) =>
                  handleFileUpload(field.field, e.target.files[0])
                }
              />
            </Button>
              <FormHelperText>{validation[field.field].join(" ")}</FormHelperText>
            </>
          )}
        </FormControl>
      );
    } else if (field.type === "Boolean" || field.input === "switch") {
      return (<FormGroup error={validation[field.field].length > 0 ? validation[field.field].join('\n') : undefined}>
          <FormControlLabel
            key={"form-field-" + field.field + "-" + key}
            label={field.label}
            id={field.field}
            disabled={typeof field.disabled === "function" ? !!field.disabled(data) : !!field.disabled}
            control={
              <Switch
                checked={data[field.field]}
                onChange={(e) => onChangeSetData(field, e.target.checked)}
                name={"form-field-switch-" + field.field + "-" + key}
                color="primary"
              />
            }
            style={{margin:"5px 0 5px 0"}}
          />
          {(field.help || validation[field.field]) ?
            <FormHelperText>{(field.help ? field.help : '') + validation[field.field].join(" ")}</FormHelperText> :
            ''
          }
        </FormGroup>
      );
    } else if (field.input === "date") {
      return (
        <TextField
          fullWidth
          key={"form-field-" + field.field + "-" + key}
          id={field.field}
          label={field.label}
          type="date"
          InputLabelProps={{shrink: true}}
          value={data[field.field] ? moment(data[field.field]).format('yyyy-MM-DD') : ""}
          error={validation[field.field].length > 0}
          helperText={validation[field.field].join(" ")}
          disabled={
            typeof field.disabled === "function" && field.disabled(data)
          }
          onChange={(event) => onChangeSetData(field, event.target.value)}
        />
      );
    } else if (field.input === "datetime") {
      return (
        <TextField
          fullWidth
          type="datetime-local"
          key={"form-field-" + field.field + "-" + key}
          label={field.label}
          id={field.field}
          value={data[field.field] ? moment(data[field.field]).format('yyyy-MM-DDThh:mm') : ""}
          error={validation[field.field].length > 0}
          helperText={validation[field.field].join(" ")}
          disabled={
            typeof field.disabled === "function" && field.disabled(data)
          }
          format={intl.formatMessage({id: "common.datetime.format"})}
          onChange={(date) => onChangeSetData(field, date.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    } else if (field.input === "time") {
      return (
        <TextField
          fullWidth
          type="time"
          key={"form-field-" + field.field + "-" + key}
          label={field.label}
          id={field.field}
          value={data[field.field]}
          error={validation[field.field].length > 0}
          helperText={validation[field.field].join(" ")}
          disabled={
            typeof field.disabled === "function" && field.disabled(data)
          }
          onChange={(date) => onChangeSetData(field, date.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      );
    } else if (field.input === "number") {
      return (
        <FormControl
          key={"form-field-" + field.field + "-" + key}
          fullWidth
        >
          <InputLabel id={"form-label-" + field.field + "-" + key}>
            {field.label}
          </InputLabel>
          <Input
            disabled={
              typeof field.disabled === "function" && field.disabled(data)
            }
            id={field.field}
            value={data[field.field]}
            onChange={(e) => {
              let val = e.target.value;
              if (field.input === "number" && field.inputProps) {
                if (field.inputProps.min && val < field.inputProps.min) val = field.inputProps.min;
                if (field.inputProps.max && val > field.inputProps.max) val = field.inputProps.max;
              }
              onChangeSetData(field, val);
            }}
            endAdornment={field.unit !== undefined &&
              <InputAdornment position="end">{field.unit}</InputAdornment>
            }
            aria-describedby={"form-label-" + field.field + "-" + key}
            error={validation[field.field].length > 0}
            inputProps={{
              ...field.inputProps,
              "aria-label": field.unit,
              type: field.input,
              style: {textAlign: "right"},
            }}
          />
          <FormHelperText id="standard-weight-helper-text">
            {validation[field.field].join(" ")}
          </FormHelperText>
        </FormControl>
      );
    } else if (field.input === "html") {
      return (
        <>
        <InputLabel style={{marginBottom:10}}>{field.label}</InputLabel>
        <FormControl
          key={"form-field-" + field.field + "-" + key}
          id={field.field}
          error={validation[field.field].length > 0}
          fullWidth
          style={{overflow:"clip"}}
        >
          <JoditEditor
            ref={editor}
            config={joditConfig}
            tabIndex={1}
            value={data[field.field]}
		        onBlur={newContent => !onChangeSetData(field, newContent)}
          />
          <FormHelperText>{validation[field.field].join(" ")}</FormHelperText>
        </FormControl>
        </>
      );
    } else if (field.input === "heading") {
      return (
        <div key={"form-field-heading-" + key} style={{marginTop: "13px"}}>
          <Typography variant="subtitle2"><strong>{field.label}</strong></Typography>
        </div>
      );
    } else if (field.input === "country") {
      return (
        <FormControl fullWidth error={validation[field.field].length > 0}>
          <InputLabel id={"form-field-" + field.field + "-label"}>
            {field.label}
          </InputLabel>
          <Select
            disabled={
              typeof field.disabled === "function" && field.disabled(data)
            }
            key={"form-field-" + field.field + "-" + key}
            labelId={"form-field-" + field.field + "-label"}
            id={field.field}
            value={data[field.field]}
            onChange={(e) => onChangeSetData(field, e.target.value)}
          >
            {countries
              .sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
              .map((country, key) => (
              <MenuItem
                key={"form-field-" + country.code + "-item-" + key}
                value={field.inputCode && field.inputCode === "Alpha-2" ? country.code : field.inputCode && field.inputCode === "Alpha-3" ? alpha2ToAlpha3(country.code) : country.value}
              >
                {country.value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{validation[field.field].join(" ")}</FormHelperText>
        </FormControl>
      );
    } else if (typeof field.render === "function") {
      return field.render(key, data, setData);
    } else {
      return (
        <TextField
          key={"form-field-" + field.field + "-" + key}
          label={field.label}
          id={field.field}
          error={validation[field.field].length > 0}
          helperText={(field.help ? field.help : '') + (validation[field.field].length ? validation[field.field].join(" ") : '')}
          fullWidth
          disabled={
            typeof field.disabled === "function" && field.disabled(data)
          }
          onChange={(e) => {
            let val = e.target.value;
            if (field.input === "number" && field.inputProps) {
              if (field.inputProps.min && val < field.inputProps.min) val = field.inputProps.min;
              if (field.inputProps.max && val > field.inputProps.max) val = field.inputProps.max;
            }
            onChangeSetData(field, val);
          }}
          value={data[field.field] !== null ? data[field.field] : ""}
          rows={field.lines > 1 ? field.lines : 1}
          multiline={field.lines > 1}
          inputProps={{
            ...field.inputProps,
            type: field.input,
            style: {textAlign: field.input === "number" ? "right" : "left"},
          }}
        />
      );
    }
  };

  return (
    <div>
      <Grid container spacing={2} key={"edit-form-container"}
            alignItems={"flex-start"}
            alignContent={"flex-end"}>
        {fieldChunks.map((chunk, cCnt) => (
          <Grid item key={"form-col-" + cCnt} xs={12} sm={columnSizes[cCnt]}>
            <Grid container spacing={1} key={"form-col-" + cCnt + "-container"}>
              {chunk.map((field, index) => (
                <Grid
                  item
                  key={"form-field-" + index + cCnt * fieldChunks[0].length}
                  xs={12}
                  style={field.style}
                >
                  {getFormField(field, index + cCnt * fieldChunks[0].length)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
        {fullWidths.map((field, index) => (
          <Grid item key={"form-field-fullWidth-" + index} xs={12}>
            {getFormField(field, index + fields.length)}
          </Grid>
        ))}
        {!extraComponent ? '' : <Grid item xs={12}>
          {extraComponent}
        </Grid>}
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={"flex-end"}
        style={{marginTop: "20px"}}
      >
        {buttons.map((b, k) => (
          <Grid item key={"form_buttons_" + k}>
            <Button
              onClick={(e) => {
                b.onClick(e);
              }}
            >
              {b.label}
            </Button>
          </Grid>
        ))}
        {props.history ? (
          <Grid item>
            <Button
              onClick={() => {
                props.history.goBack();
              }}
            >
              {intl.formatMessage({id: "common.button.back"})}
            </Button>
          </Grid>
        ) : (
          ""
        )}
        {save ? (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => save(data)}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={17} color="inherit"/>
              ) : (
                <FormattedMessage id={"common.button.save"}/>
              )}
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

EditForm.propTypes = {
  id: PropTypes.number,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      initial: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.number,
      ]),
      type: PropTypes.oneOf([
        "ID",
        "[ID]",
        "String",
        "Email",
        "Json",
        "Int",
        "Float",
        "Boolean",
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      fullWidth: PropTypes.bool,
      required: PropTypes.bool,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      input: PropTypes.string,
      inputCode: PropTypes.oneOf([
        "Alpha-2",
        "Alpha-3"
      ]),
      titleField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      query: PropTypes.string,
      filter: PropTypes.string,
      accept: PropTypes.string,
      lines: PropTypes.number,
      style: PropTypes.object
    })
  ),
  cols: PropTypes.number,
  colSizes: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  setData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
};
