import React from "react";
import {Paper} from "@material-ui/core";
import {List} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";

export default function ConnectorLogList(props) {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({id: 'connectors_logs.list.column.connector.title', defaultMessage: 'Title'}),
      field: 'connector.title',
      type: 'string',
      filtering: false,
      width: 'auto'
    },
    {
      title: intl.formatMessage({id: 'connectors_logs.list.column.connector.driver', defaultMessage: 'Driver'}),
      type: 'string',
      filtering: false,
      width: 'auto',
      render: row => row.connector ? row.connector.driver : 'OpenDIMS'
    },
    {
      title: intl.formatMessage({id: 'connectors_logs.list.column.message', defaultMessage: 'Message'}),
      field: 'message'
    },
    {
      title: intl.formatMessage({id: 'connectors_logs.list.column.severity', defaultMessage: 'Severity'}),
      field: 'level',
      initial: false,
      render: row => {
        switch (row.level) {
          case 0:
            return intl.formatMessage({id: 'connectors_logs.list.level.emergency', defaultMessage: 'Emergency'})
          case 1:
            return intl.formatMessage({id: 'connectors_logs.list.level.alert', defaultMessage: 'Alert'})
          case 2:
            return intl.formatMessage({id: 'connectors_logs.list.level.critical', defaultMessage: 'Critical'})
          case 3:
            return intl.formatMessage({id: 'connectors_logs.list.level.error', defaultMessage: 'Alert'})
          case 4:
            return intl.formatMessage({id: 'connectors_logs.list.level.warning', defaultMessage: 'Warning'})
          case 5:
            return intl.formatMessage({id: 'connectors_logs.list.level.notice', defaultMessage: 'Notice'})
          case 6:
            return intl.formatMessage({id: 'connectors_logs.list.level.info', defaultMessage: 'Info'})
          case 7:
            return intl.formatMessage({id: 'connectors_logs.list.level.debug', defaultMessage: 'Debug'})
          case 8:
            return intl.formatMessage({id: 'connectors_logs.list.level.user', defaultMessage: 'User'})
          default:
            return intl.formatMessage({id: 'connectors_logs.list.level.unknown', defaultMessage: '-'})
        }
      }
    },
    {
      title: intl.formatMessage({id: 'connectors_logs.list.column.connector.created_at', defaultMessage: 'Created At'}),
      field: 'created_at',
      type: 'date',
      defaultSort: 'desc'
    },
  ];

  return (
    <Paper>
      <EnhancedTable
        {...props}
        title={intl.formatMessage({id: 'connectors_logs.list.table.heading', defaultMessage: "Connectors Logs list"})}
        columns={columns}
        sorting="id"
        direction="desc"
        query="connectorLogs"
        fields='id connector{title driver} created_by_user{name} message level created_at'
        icon={<List/>}
        urlState
      />
    </Paper>
  );
}