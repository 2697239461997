import {useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {useHistory} from "react-router-dom";

export default function SelectPrintoutDialog({open, onClose, entity, onSelect}) {
  const intl = useIntl();
  const client = useCallback(new graphQLApi(useAuthDispatch(), useHistory()), []);

  const [layouts, setLayouts] = useState([]);

  useEffect(() => {
    if (entity && entity.entity_type) {
      client.query('{' +
      'printouts(filter:{entity_type_id:'+entity.entity_type.id+'}){data{id title}}' +
      '}')
      .then(r => {
        if (r && r.hasOwnProperty('printouts')) {
          setLayouts(r.printouts.data);
        }
      });
    }
  }, [entity]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography><Typography variant="h2" color="primary">{intl.formatMessage({id:"entities.list.select_layout"})}</Typography></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {layouts.map((layout, index) => (
            <Grid key={index} item xs={6}>
              <Button color="primary" fullWidth variant="contained" onClick={() => onSelect(layout.id)}>{layout.title}</Button>
            </Grid>
          ))}
        </Grid>
        {layouts.length === 0 && <Typography>{intl.formatMessage({id:"entities.list.no_layouts_found"})}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage({id:"common.button.close"})}</Button>
      </DialogActions>
    </Dialog>
  );
}