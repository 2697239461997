import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/icons components
import componentStyles from "assets/theme/layouts/admin.js";
import {useIntl} from "react-intl";
import ViewPrintout from "views/Printouts/ViewPrintout.js";
import {Print} from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

export default function Printout() {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const routes = [
    {
      path: "",
      name: intl.formatMessage({
        id: "menu.printouts.list",
        defaultMessage: "Printouts",
      }),
      icon: Print,
      iconColor: "Primary",
      component: ViewPrintout,
      layout: "/admin"
    }
  ];
  const brandText = intl.formatMessage({id:"navbar.brand_text"});

  const getBreadcrumb = (routes, prefix = "", path = []) => {
    routes.forEach(r => {
      if (location.pathname.search(
        // Transform a path like /admin/products/:id/values/:id to something able to regexp:
        // like /admin/products/[0-9]+/values/[0-9]+
        String(prefix + r.path).replaceAll(new RegExp('/:[a-z_-]+', 'g'), '/[0-9]+')
      ) === 0) {
        path.push({...r, href: prefix + r.path});
      }
      if (r.views) {
        path = getBreadcrumb(r.views, prefix + r.path, path);
      }
    });
    return path;
  };
  const breadcrumb = getBreadcrumb(routes, "");

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
    document.title = brandText + " - " + (breadcrumb.length ? breadcrumb[breadcrumb.length-1].name : 'Unknown');
  }, [location]);

  return (
    <>
      <>
        <Box position="relative" className={classes.mainContentNoSidebar}>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Container classes={{ root: classes.mainRoot }}>
              <Switch>
                <Route path={"/printout/:id"} component={ViewPrintout} exact/>
              </Switch>
            </Container>
          </Container>
        </Box>
      </>
    </>
  );
};