import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Delete, DragHandle} from "@material-ui/icons";
import {graphQLApi} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {updateItemInArray} from "variables/general";

const DraggableHandle = sortableHandle(() => <DragHandle cursor="row-resize"/>);

const SortableItem = sortableElement(({value, setValue, languages, settings, onDelete}) => {
  return <ListItem>
    <ListItemIcon><DraggableHandle/></ListItemIcon>
    <ListItemText>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12} sm>
          <TextField fullWidth
                     label={languages.length ? settings.language : settings.translations.title}
                     value={value.title}
                     onChange={e => setValue(value.id, e.target.value)}
                     InputProps={{
                       startAdornment: languages.length ? (
                         <InputAdornment position="start">
                           <img alt={settings.country_code} title={settings.language}
                                src={"/flags/" + settings.country_code + ".png"}/>
                         </InputAdornment>
                       ) : null,
                     }}/>
        </Grid>
        {languages.map((l, k) => {
          let tIdx = -1;
          if (value.titles) {
            tIdx = value.titles.findIndex(t => t.language_id === l.id);
          }
          return <Grid item xs={12} sm key={"option-lang-" + k}>
            <TextField fullWidth
                       label={l.name}
                       value={tIdx !== -1 ? value.titles[tIdx].translation : ""}
                       onChange={e => setValue(value.id, e.target.value, l.id)}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <img alt={l.country_code}
                                  src={"/flags/" + l.country_code.replace(/\d+/, '').toUpperCase() + ".png"}/>
                           </InputAdornment>
                         ),
                       }}/>
          </Grid>;
        })}
      </Grid>
    </ListItemText>
    {onDelete ? (<ListItemSecondaryAction>
      <IconButton onClick={() => onDelete(value.id)}><Delete/></IconButton>
    </ListItemSecondaryAction>) : ''}
  </ListItem>;
});
const SortableContainer = sortableContainer(({children}) => {
  return <List dense>{children}</List>;
});

const FieldOptions = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const {field, onChange} = props;

  const [options, setOptions] = useState([]);
  const [settings, setSettings] = useState({
    translations: {
      title: intl.formatMessage({id: "fields.edit.option.title", defaultMessage: "Title"})
    }
  });
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [validation, setValidation] = useState([]);
  const setValidationFromErrors = (errors) => {
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {
      setValidation(...errors[0].extensions.validation);
    }
  };
  const client = new graphQLApi(
    useAuthDispatch(),
    props.history,
    null,
    {handleErrors: setValidationFromErrors}
  );
  const stableClient = useCallback(client, []);
  useEffect(() => {
    if (field && field.id) {
      setIsLoading(true);
      const query = '{' +
          'fieldOptions(sorting:"sorting",direction:"asc",filter:{field_id:' + field.id + '}) {data{ id title titles{id translation language{id name}} }}' +
          'settings(filter:{key_in:["language","country_code","locale"]}){ data {key type data} }' +
          'languages{data{ id name country_code }}' +
          '}';
      stableClient
        .query(query)
        .then(r => {
          setIsLoading(false);
          if (r && r.hasOwnProperty('fieldOptions')) {
            let opts = r.fieldOptions.data.map(o => {
              return {
                ...o,
                titles: o.titles.map(t => {
                  return {
                    ...t,
                    language_id: t.language.id,
                  };
                })
              };
            });
            setOptions(opts);
          }
          if (r && r.languages) {
            setLanguages(r.languages.data);
          }
          if (r && r.settings) {
            let set = {};
            r.settings.data.forEach(s => {
              set[s.key] = s.data;
            });
            setSettings({...settings, ...set});
          }
        });
    }
  }, [stableClient, field]);

  const onSortEnd = ({oldIndex, newIndex}) => {
    let opts = arrayMove(options, oldIndex, newIndex);
    setOptions(opts);
  };

  const setOption = (id, title, language_id = null) => {
    let attributes = {};
    if (language_id) {
      let index = options.findIndex(x => x.id === id);
      attributes.titles = updateItemInArray(options[index].titles, 'language_id', language_id, {translation: title});
    } else {
      attributes.title = title;
    }
    let os = updateItemInArray(options, 'id', id, attributes);
    setOptions(os);
  };

  const remapOptionsForApi = (opts, fieldId) => {
    return opts.filter(o => o.title && o.title !== "").map((o, c) => {
      o.field_id = fieldId ? fieldId : field.id;
      o.sorting = c + 1;
      o.titles = o.titles.filter(t => t.translation && t.translation !== "").map(t => {
        return {translation: t.translation, language_id: t.language_id}
      });
      return o;
    });
  }

  const saveOptions = (fieldId) => {
    let oa = remapOptionsForApi(options, fieldId);
    if (typeof onChange === "function") {
      onChange(oa);
    }
    const variables = {
      options: oa
    };
    // console.log('Saving options', variables);
    // variables = { options: '[FieldOptionInput]', id: 'ID!'};
    // data = { options: fieldOptions, id: id };
    // fields = 'id titles {id translation language{id name}}';
    // mutation('fieldOptions', variables, data, fields)
    return client.mutate('($options: [FieldOptionInput]) {fieldOptionsUpdate(options:$options) { id title titles{id translation language{id name}} }}', variables)
      .then(r => {
        if (r && r.hasOwnProperty('fieldOptionsUpdate')) {
          return r.fieldOptionsUpdate.map(o => {
            return {
              ...o,
              titles: o.titles.map(t => {
                return {
                  ...t,
                  language_id: t.language.id,
                };
              })
            };
          });
        }
      });
  };

  const deleteOption = (optionId) => {
    if (/^new\d+$/.test(optionId)) {
      return;
    }
    client.mutate('{fieldOptionDelete(id:' + optionId + ')}').then(r => {
      if (r && r.fieldOptionDelete) {
        let os = [...options.filter(o => o.id !== optionId)];
        setOptions(os);
        if (typeof onChange === "function") {
          onChange(os);
        }
      }
    });
  }

  useImperativeHandle(ref, () => ({
    addOption() {
      setOptions([...options, {
        id: "new" + options.length,
        field_id: field.id,
        title: "",
        titles: []
      }]);
    },
    getOptions() {
      return remapOptionsForApi(options);
    },
    saveOptions(fieldId) {
      return saveOptions(fieldId);
    }
  }));

  return (
    <div style={{textAlign: "center"}}>
      {validation ? validation.map((v, k) => <span key={'validation-key-' + k}>{v}</span>) : ""}
      {isLoading || !field ? (
        <CircularProgress/>
      ) : (
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
          {options.map((value, index) => (
            <SortableItem key={'option-' + index} index={index} value={value}
                          setValue={setOption}
                          languages={field.uses_languages ? languages : []} settings={settings}
                          onDelete={deleteOption}/>
          ))}
        </SortableContainer>
      )}
    </div>
  );
});
export default FieldOptions;
