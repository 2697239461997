import {
  AssignmentInd,
  CallSplit,
  Domain,
  FormatListBulleted,
  Language,
  List,
  ListAlt,
  Person, Print,
  Share,
  ViewColumn
} from "@material-ui/icons";
import ViewEdit from "views/Admin/Settings/Views/ViewEdit";
import ViewList from "views/Admin/Settings/Views/ViewList";

import UserList from "views/Admin/Users/UserList";
import UserEdit from "views/Admin/Users/UserEdit";
import LanguageList from "views/Admin/Settings/LanguageList";
import LanguageEdit from "views/Admin/Settings/LanguageEdit";
import SiteList from "views/Admin/MutliSite/SiteList";
import SiteEdit from "views/Admin/MutliSite/SiteEdit";
import DomainEdit from "views/Admin/MutliSite/DomainEdit";
import RoleList from "views/Admin/Settings/RoleList";
import RoleEdit from "views/Admin/Settings/RoleEdit";
import ConnectorList from "../views/Admin/Settings/ConnectorList";
import ConnectorEdit from "../views/Admin/Settings/ConnectorEdit";
import ConnectorLogList from "../views/Admin/Settings/ConnectorLogList";
import FieldList from "views/Admin/Fields/FieldList";
import FieldEdit from "views/Admin/Fields/FieldEdit";
import FieldGroupList from "views/Admin/Settings/FieldGroupList";
import FieldGroupEdit from "views/Admin/Settings/FieldGroupEdit";
import ListingList from "../views/Admin/Listings/ListingList";
import LabelList from "views/Admin/Labels/LabelList";
import LabelEdit from "views/Admin/Labels/LabelEdit";
import EntityTypeList from "../views/Admin/Settings/EntityTypeList";
import EntityTypeEdit from "../views/Admin/Settings/EntityTypeEdit";
import {rolePriorities} from "config";
import PrintoutList from "../views/Printouts/PrintoutList";
import PrintoutEdit from "../views/Printouts/PrintoutEdit";

export default function routes(intl) {
  const prefix = '/admin';
  return [
    // {
    //   path: "",
    //   exact: true,
    //   name: intl.formatMessage({
    //     id: "menu.home",
    //     defaultMessage: "Home",
    //   }),
    //   icon: HomeIcon,
    //   component: Dashboard,
    // },
    {
      path: "/sites",
      name: intl.formatMessage({
        id: "menu.sites.list",
        defaultMessage: "Sites",
      }),
      icon: Domain,
      component: SiteList,
      allow_priority: rolePriorities.site_manager,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.sites.create",
            defaultMessage: "Create Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/create",
          name: intl.formatMessage({
            id: "menu.domains.create",
            defaultMessage: "Create Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:site_id/domains/:id",
          name: intl.formatMessage({
            id: "menu.domains.edit",
            defaultMessage: "Edit Domain",
          }),
          component: DomainEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.sites.edit",
            defaultMessage: "Edit Site",
          }),
          component: SiteEdit,
          hidden: true,
        },
      ],
    },
    {
      path: "/lists",
      name: intl.formatMessage({id: "menu.lists.list", defaultMessage: "Lists"}),
      icon: List,
      component: ListingList,
      iconColor: "Info",
      allow_priority: rolePriorities.can_fields,
      // views: [
      //   {
      //     path: "/create",
      //     name: intl.formatMessage({id: "menu.lists.create", defaultMessage: "Create list"}),
      //     component: CategoryEdit,
      //     hidden: true,
      //   },
      //   {
      //     path: "/:id",
      //     name: intl.formatMessage({id: "menu.lists.edit", defaultMessage: "Edit list"}),
      //     component: CategoryEdit,
      //     hidden: true,
      //   },
      // ]
    },
    {
      path: "/views",
      name: intl.formatMessage({id: "menu.views.list", defaultMessage: "Views"}),
      icon: ViewColumn,
      component: ViewList,
      iconColor: "Info",
      layout: "/admin",
      allow_priority: rolePriorities.developer, // Change to can_fields when implemented
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.views.create", defaultMessage: "Create View"}),
          component: ViewEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.views.edit", defaultMessage: "Edit View"}),
          component: ViewEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/data-types",
      name: intl.formatMessage({id: "menu.data-types.list", defaultMessage: "Data types"}),
      icon: CallSplit,
      component: EntityTypeList,
      iconColor: "default",
      layout: "/admin",
      allow_priority: 25,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.data-types.create", defaultMessage: "Create data type"}),
          component: EntityTypeEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.data-types.edit", defaultMessage: "Edit data type"}),
          component: EntityTypeEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/fields",
      name: intl.formatMessage({
        id: "menu.fields.list",
        defaultMessage: "Fields",
      }),
      icon: FormatListBulleted,
      iconColor: "Warning",
      component: FieldList,
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.fields.edit",
            defaultMessage: "Field edit",
          }),
          component: FieldEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.fields.create",
            defaultMessage: "Field create",
          }),
          component: FieldEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/field_groups",
      name: intl.formatMessage({id: "menu.field_groups.list", defaultMessage: "Field Groups"}),
      icon: ListAlt,
      iconColor: "Warning",
      component: FieldGroupList,
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.field_groups.create",
            defaultMessage: "Create Field Group"
          }),
          component: FieldGroupEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.field_groups.edit",
            defaultMessage: "Edit Field Group"
          }),
          component: FieldGroupEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/exports",
      name: intl.formatMessage({id: "menu.labels.list", defaultMessage: "Labels"}),
      icon: ListAlt,
      component: LabelList,
      allow_priority: rolePriorities.developer, // Change to can_fields when implemented
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.labels.create",
            defaultMessage: "Create Label"
          }),
          component: LabelEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.labels.edit",
            defaultMessage: "Edit Label"
          }),
          component: LabelEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/printouts",
      name: intl.formatMessage({
        id: "menu.printouts.list",
        defaultMessage: "Print layouts",
      }),
      icon: Print,
      iconColor: "Info",
      component: PrintoutList,
      allow_priority: rolePriorities.can_fields,
      views: [
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.printouts.edit",
            defaultMessage: "Edit print layout",
          }),
          component: PrintoutEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.printouts.create",
            defaultMessage: "Create print layout",
          }),
          component: PrintoutEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/connectors",
      name: intl.formatMessage({id: "menu.settings.connectors.list", defaultMessage: "Connectors"}),
      icon: Share,
      component: ConnectorList,
      iconColor: "Info",
      allow_priority: rolePriorities.can_import_export,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.settings.connectors.create", defaultMessage: "Create integration"}),
          component: ConnectorEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.settings.connectors.edit", defaultMessage: "Edit integration"}),
          component: ConnectorEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/connector/logs",
      name: intl.formatMessage({id: "menu.settings.connector.logs", defaultMessage: "Connector Logs"}),
      component: ConnectorLogList,
      icon: Share,
      iconColor: "Info",
      allow_priority: rolePriorities.can_import_export,
    },
    {
      path: "/languages",
      name: intl.formatMessage({id: "menu.languages.list", defaultMessage: "Languages"}),
      icon: Language,
      component: LanguageList,
      allow_priority: rolePriorities.admin,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.languages.create", defaultMessage: "Create Language"}),
          component: LanguageEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.languages.edit", defaultMessage: "Edit Language"}),
          component: LanguageEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/roles",
      name: intl.formatMessage({id: "menu.roles.list", defaultMessage: "Roles"}),
      icon: AssignmentInd,
      component: RoleList,
      allow_priority: rolePriorities.admin,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({id: "menu.roles.create", defaultMessage: "Create Role"}),
          component: RoleEdit,
          hidden: true,
        },
        {
          path: "/:id",
          name: intl.formatMessage({id: "menu.roles.edit", defaultMessage: "Edit Role"}),
          component: RoleEdit,
          hidden: true,
        },
      ]
    },
    {
      path: "/users",
      name: intl.formatMessage({
        id: "menu.users.list",
        defaultMessage: "Users",
      }),
      icon: Person,
      component: UserList,
      allow_priority: rolePriorities.can_users,
      views: [
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.users.create",
            defaultMessage: "Create user",
          }),
          component: UserEdit,
          hidden: true
        },
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.users.edit",
            defaultMessage: "Edit user",
          }),
          component: UserEdit,
          hidden: true
        },
      ],
    },
  ].map(r => ({...r, path: prefix + r.path}));
}