import React from "react";
import {Card, CardContent, CardHeader, Grid} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Extension} from "@material-ui/icons";
import Field from "components/Fields/Field";

export default function FieldEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);

  return (<Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" avatar={<Extension/>} title={intl.formatMessage({
            id: "fields.edit.heading",
            defaultMessage: "Edit field"
          })}/>
          <CardContent>
            <Field id={id} history={props.history}/>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}