import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {ViewColumn} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function ViewEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            column: 1,
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "views.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            column: 1,
            field: "listing_id",
            initial: null,
            type: "ID",
            query: "listings",
            titleField: "title",
            filter: "user_id:null",
            label: intl.formatMessage({id: "labels.edit.label.listing", defaultMessage: "List"}),
        },
        {
            column: 1,
            field: "sorting",
            initial: 0,
            type: "Int",
            label: intl.formatMessage({id: "views.edit.label.sorting", defaultMessage: "Sorting"}),
            input: "number"
        },
        {
            column: 2,
            field: "description",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "views.edit.label.description", defaultMessage: "Description"}),
            lines: 7
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        color="primary"
                        avatar={<ViewColumn/>}
                        title={intl.formatMessage({id: "views.edit.heading", defaultMessage:"Views edit"})}/>
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"views"}
                                         mutations={"view"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
