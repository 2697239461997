import {Paper} from "@material-ui/core";
import {List} from "@material-ui/icons";
import EnhancedTable from "components/DataTable/EnhancedTable";
import React from "react";
import {useIntl} from "react-intl";

export default function ListingList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'listings.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {
      title: intl.formatMessage({id: 'listings.list.column.listing_operands_count', defaultMessage: 'Filters'}),
      field: 'listing_operands_count',
      render: row => parseInt(row.listing_operands_count)
    },
    {
      title: intl.formatMessage({id: 'listings.list.column.usages_count', defaultMessage: 'Usages'}),
      field: 'usages_count',
      render: row => parseInt(row.usages_count)
    },
    {
      title: intl.formatMessage({id: 'listings.list.column.created_at', defaultMessage: 'Created at'}),
      field: 'created_at',
    }
  ];

  return (<Paper>
      <EnhancedTable
        title={intl.formatMessage({id: 'listings.list.table.heading', defaultMessage: "Lists"})}
        columns={columns}
        query="listings"
        // mutations="listing"
        fields='id title usages_count listing_operands_count created_at'
        icon={<List/>}
        urlState
        {...props}
      />
    </Paper>
  );
}