const componentStyles = (theme) => ({
  mainContent: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 250,
    },
  },
  mainContentNoSidebar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      marginTop: "4.5rem",
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  mainRoot: {
    minHeight: "calc(100vh - 118px);",
    maxWidth: "100% !important",
    padding: 0,
  },
});

export default componentStyles;
