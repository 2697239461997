export const getEntityValueFromFieldType = (value) => {
  if (!value || !value.field) return null;
  switch (value.field.type) {
    case FieldTypes.BOOLEAN:
    case FieldTypes.COMPUTED_IS_NULL:
    case FieldTypes.COMPUTED_EQUAL:
    case FieldTypes.COMPUTED_NOT_EQUAL:
    case FieldTypes.COMPUTED_GREATER:
    case FieldTypes.COMPUTED_GREATER_EQUAL:
    case FieldTypes.COMPUTED_LESS:
    case FieldTypes.COMPUTED_LESS_EQUAL:
    case FieldTypes.COMPUTED_IS_BLANK:
    case FieldTypes.COMPUTED_SEARCH:
    case FieldTypes.COMPUTED_SAME:
    case FieldTypes.COMPUTED_DIFFERENT:
      return value.bool;
    case FieldTypes.STRING:
      return value.string;
    case FieldTypes.TEXT:
    case FieldTypes.HTML:
    case FieldTypes.COMPUTED_APPEND:
    case FieldTypes.COMPUTED_PREFIX:
    case FieldTypes.COMPUTED_JOIN:
    case FieldTypes.COMPUTED_TRIM:
    case FieldTypes.COMPUTED_LOWER:
    case FieldTypes.COMPUTED_UPPER:
    case FieldTypes.COMPUTED_TITLE:
    case FieldTypes.COMPUTED_STUDLY:
    case FieldTypes.COMPUTED_CAMEL:
    case FieldTypes.COMPUTED_SNAKE:
      return value.text;
    case FieldTypes.NUMBER:
      return value.integer;
    case FieldTypes.DECIMAL:
    case FieldTypes.PRICE:
    case FieldTypes.COMPUTED_SUM:
    case FieldTypes.COMPUTED_AVERAGE:
    case FieldTypes.COMPUTED_MAX:
    case FieldTypes.COMPUTED_MIN:
    case FieldTypes.COMPUTED_MULTIPLY:
    case FieldTypes.COMPUTED_DIVIDE:
    case FieldTypes.COMPUTED_SUBTRACT:
    case FieldTypes.COMPUTED_ROUND:
    case FieldTypes.COMPUTED_COMPLETION:
      return value.decimal;
    case FieldTypes.OPTION:
    case FieldTypes.OPTIONS:
      return value.field_option.title;
    case FieldTypes.ASSET:
    case FieldTypes.ASSETS:
      return value.asset.file_uri;
    default:
      return null;
  }
}

export const FieldTypes = {
    BOOLEAN: 'boolean',
    STRING: 'string',
    TEXT: 'text',
    HTML: 'html',
    NUMBER: 'integer',
    DECIMAL: 'decimal',
    DATE: 'date',
    DATETIME: 'datetime',
    PRICE: 'price',
    ASSET: 'asset',
    ASSETS: 'assets',
    OPTION: 'option',
    OPTIONS: 'options',
    ENTITY: 'entity',
    ENTITIES: 'entities',
    COMPUTED_SUM: 'computation_sum',
    COMPUTED_AVERAGE: 'computation_average',
    COMPUTED_MAX: 'computation_max',
    COMPUTED_MIN: 'computation_min',
    COMPUTED_MULTIPLY: 'computation_multiply',
    COMPUTED_DIVIDE: 'computation_divide',
    COMPUTED_SUBTRACT: 'computation_subtract',
    COMPUTED_ROUND: 'computation_round',
    COMPUTED_COMPLETION: 'computation_completion',
    COMPUTED_APPEND: 'computation_append',
    COMPUTED_PREFIX: 'computation_prefix',
    COMPUTED_JOIN: 'computation_join',
    COMPUTED_TRIM: 'computation_trim',
    COMPUTED_LOWER: 'computation_lower',
    COMPUTED_UPPER: 'computation_upper',
    COMPUTED_TITLE: 'computation_title',
    COMPUTED_STUDLY: 'computation_studly',
    COMPUTED_CAMEL: 'computation_camel',
    COMPUTED_SNAKE: 'computation_snake',
    COMPUTED_IS_NULL: 'computation_is_null',
    COMPUTED_EQUAL: 'computation_equal',
    COMPUTED_NOT_EQUAL: 'computation_not_equal',
    COMPUTED_GREATER: 'computation_greater',
    COMPUTED_GREATER_EQUAL: 'computation_greater_equal',
    COMPUTED_LESS: 'computation_less',
    COMPUTED_LESS_EQUAL: 'computation_less_equal',
    COMPUTED_IS_BLANK: 'computation_is_blank',
    COMPUTED_SEARCH: 'computation_search',
    COMPUTED_SAME: 'computation_same',
    COMPUTED_DIFFERENT: 'computation_different',
}

export const listingsFilterableFieldTypes = [
  FieldTypes.BOOLEAN,
  FieldTypes.STRING,
  FieldTypes.TEXT,
  FieldTypes.HTML,
  FieldTypes.NUMBER,
  FieldTypes.DECIMAL,
  FieldTypes.PRICE,
  FieldTypes.DATE,
  FieldTypes.DATETIME,
  FieldTypes.OPTION,
  FieldTypes.OPTIONS,
  FieldTypes.COMPUTED_SUM,
  FieldTypes.COMPUTED_AVERAGE,
  FieldTypes.COMPUTED_MAX,
  FieldTypes.COMPUTED_MIN,
  FieldTypes.COMPUTED_MULTIPLY,
  FieldTypes.COMPUTED_DIVIDE,
  FieldTypes.COMPUTED_SUBTRACT,
  FieldTypes.COMPUTED_ROUND,
  FieldTypes.COMPUTED_COMPLETION,
  FieldTypes.COMPUTED_APPEND,
  FieldTypes.COMPUTED_PREFIX,
  FieldTypes.COMPUTED_JOIN,
  FieldTypes.COMPUTED_TRIM,
  FieldTypes.COMPUTED_LOWER,
  FieldTypes.COMPUTED_UPPER,
  FieldTypes.COMPUTED_TITLE,
  FieldTypes.COMPUTED_STUDLY,
  FieldTypes.COMPUTED_CAMEL,
  FieldTypes.COMPUTED_SNAKE,
  FieldTypes.COMPUTED_IS_NULL,
  FieldTypes.COMPUTED_EQUAL,
  FieldTypes.COMPUTED_NOT_EQUAL,
  FieldTypes.COMPUTED_GREATER,
  FieldTypes.COMPUTED_GREATER_EQUAL,
  FieldTypes.COMPUTED_LESS,
  FieldTypes.COMPUTED_LESS_EQUAL,
  FieldTypes.COMPUTED_IS_BLANK,
  FieldTypes.COMPUTED_SEARCH,
  FieldTypes.COMPUTED_SAME,
  FieldTypes.COMPUTED_DIFFERENT,
]

export const stringFieldTypes = [
  FieldTypes.STRING,
  FieldTypes.TEXT,
  FieldTypes.HTML,
  FieldTypes.COMPUTED_APPEND,
  FieldTypes.COMPUTED_PREFIX,
  FieldTypes.COMPUTED_JOIN,
  FieldTypes.COMPUTED_TRIM,
  FieldTypes.COMPUTED_LOWER,
  FieldTypes.COMPUTED_UPPER,
  FieldTypes.COMPUTED_TITLE,
  FieldTypes.COMPUTED_STUDLY,
  FieldTypes.COMPUTED_CAMEL,
  FieldTypes.COMPUTED_SNAKE,
]

export const FieldColumns = {
    Product: {
        id: "id",
        sku: "sku",
        name: "name",
        category: "category",
        categories: "categories",
        variants: "variants",
        variant_fields: "variant_fields",
        // Child variants have this
        parent: "parent",
    },
    Category: {
        id: "id",
        parent: "parent",
    },
    Customer: {
        id: "id",
    },
    Order: {
        id: "id",
        customer: "customer",
    },
    OrderLine: {
        id: "id",
        order: "order",
    },
}

export const getFieldColumns = (intl) => {
  return [
      {id: FieldColumns.Product.id, type: "integer", name: intl.formatMessage({id:"field_columns.product.id", defaultMessage:"ID"})},
      {id: FieldColumns.Product.variants, type: "Product[]", name: intl.formatMessage({id:"field_columns.product.variants", defaultMessage:"Variants"})},
      {id: FieldColumns.Product.variant_fields, type: "Field[]", name: intl.formatMessage({id:"field_columns.product.variant_fields", defaultMessage:"Variant fields"})},
      {id: FieldColumns.Product.parent, type: "Product", name: intl.formatMessage({id:"field_columns.product.parent", defaultMessage:"Parent product"})},
  ];
}

export const getFieldTypeOptions = (intl) => {
    return [
        {id: FieldTypes.BOOLEAN, name: intl.formatMessage({id:"fields.types.boolean", defaultMessage:"ON/OFF"})},
        {id: FieldTypes.STRING, name: intl.formatMessage({id:"fields.types.string", defaultMessage:"String"})},
        {id: FieldTypes.TEXT, name: intl.formatMessage({id:"fields.types.text", defaultMessage:"Text"})},
        {id: FieldTypes.HTML, name: intl.formatMessage({id:"fields.types.html", defaultMessage:"Formatted text"})},
        {id: FieldTypes.NUMBER, name: intl.formatMessage({id:"fields.types.number", defaultMessage:"Number"})},
        {id: FieldTypes.DECIMAL, name: intl.formatMessage({id:"fields.types.decimal", defaultMessage:"Decimal"})},
        {id: FieldTypes.PRICE, name: intl.formatMessage({id:"fields.types.price", defaultMessage:"Price"})},
        {id: FieldTypes.DATE, name: intl.formatMessage({id:"fields.types.date", defaultMessage:"Date"})},
        {id: FieldTypes.DATETIME, name: intl.formatMessage({id:"fields.types.datetime", defaultMessage:"Date and time"})},
        {id: FieldTypes.ASSET, name: intl.formatMessage({id:"fields.types.asset", defaultMessage:"Asset"})},
        {id: FieldTypes.ASSETS, name: intl.formatMessage({id:"fields.types.assets", defaultMessage:"SinglePage"})},
        {id: FieldTypes.OPTION, name: intl.formatMessage({id:"fields.types.option", defaultMessage:"Option"})},
        {id: FieldTypes.OPTIONS, name: intl.formatMessage({id:"fields.types.options", defaultMessage:"Options"})},
        {id: FieldTypes.ENTITY, name: intl.formatMessage({id:"fields.types.product", defaultMessage:"Product"})},
        {id: FieldTypes.ENTITIES, name: intl.formatMessage({id:"fields.types.products", defaultMessage:"Products"})},
        {id: FieldTypes.COMPUTED_SUM, name: intl.formatMessage({id:"fields.types.computation_sum", defaultMessage:"Computation Sum"})},
        {id: FieldTypes.COMPUTED_AVERAGE, name: intl.formatMessage({id:"fields.types.computation_average", defaultMessage:"Computation Average"})},
        {id: FieldTypes.COMPUTED_MAX, name: intl.formatMessage({id:"fields.types.computation_max", defaultMessage:"Computation Max"})},
        {id: FieldTypes.COMPUTED_MIN, name: intl.formatMessage({id:"fields.types.computation_min", defaultMessage:"Computation Min"})},
        {id: FieldTypes.COMPUTED_MULTIPLY, name: intl.formatMessage({id:"fields.types.computation_multiply", defaultMessage:"Computation Multiply"})},
        {id: FieldTypes.COMPUTED_DIVIDE, name: intl.formatMessage({id:"fields.types.computation_divide", defaultMessage:"Computation Divide"})},
        {id: FieldTypes.COMPUTED_SUBTRACT, name: intl.formatMessage({id:"fields.types.computation_subtract", defaultMessage:"Computation Subtract"})},
        {id: FieldTypes.COMPUTED_ROUND, name: intl.formatMessage({id:"fields.types.computation_round", defaultMessage:"Computation Round"})},
        {id: FieldTypes.COMPUTED_COMPLETION, name: intl.formatMessage({id:"fields.types.computation_completion", defaultMessage:"Computation Completion"})},
        {id: FieldTypes.COMPUTED_APPEND, name: intl.formatMessage({id:"fields.types.computation_append", defaultMessage:"Computation Append"})},
        {id: FieldTypes.COMPUTED_PREFIX, name: intl.formatMessage({id:"fields.types.computation_prefix", defaultMessage:"Computation Prefix"})},
        {id: FieldTypes.COMPUTED_JOIN, name: intl.formatMessage({id:"fields.types.computation_join", defaultMessage:"Computation Join"})},
        {id: FieldTypes.COMPUTED_TRIM, name: intl.formatMessage({id:"fields.types.computation_trim", defaultMessage:"Computation Trim"})},
        {id: FieldTypes.COMPUTED_LOWER, name: intl.formatMessage({id:"fields.types.computation_lower", defaultMessage:"Computation Lower"})},
        {id: FieldTypes.COMPUTED_UPPER, name: intl.formatMessage({id:"fields.types.computation_upper", defaultMessage:"Computation Upper"})},
        {id: FieldTypes.COMPUTED_TITLE, name: intl.formatMessage({id:"fields.types.computation_title", defaultMessage:"Computation Title"})},
        {id: FieldTypes.COMPUTED_STUDLY, name: intl.formatMessage({id:"fields.types.computation_studly", defaultMessage:"Computation Studly"})},
        {id: FieldTypes.COMPUTED_CAMEL, name: intl.formatMessage({id:"fields.types.computation_camel", defaultMessage:"Computation Camel"})},
        {id: FieldTypes.COMPUTED_SNAKE, name: intl.formatMessage({id:"fields.types.computation_snake", defaultMessage:"Computation Snake"})},
        {id: FieldTypes.COMPUTED_EQUAL, name: intl.formatMessage({id:"fields.types.computation_equal", defaultMessage:"Computation Equal"})},
        {id: FieldTypes.COMPUTED_GREATER, name: intl.formatMessage({id:"fields.types.computation_greater", defaultMessage:"Computation Greater"})},
        {id: FieldTypes.COMPUTED_LESS, name: intl.formatMessage({id:"fields.types.computation_less", defaultMessage:"Computation Less"})},
        {id: FieldTypes.COMPUTED_SEARCH, name: intl.formatMessage({id:"fields.types.computation_search", defaultMessage:"Computation Search"})},
        {id: FieldTypes.COMPUTED_SAME, name: intl.formatMessage({id:"fields.types.computation_same", defaultMessage:"Computation Same"})},
        {id: FieldTypes.COMPUTED_DIFFERENT, name: intl.formatMessage({id:"fields.types.computation_different", defaultMessage:"Computation Different"})},
        {id: FieldTypes.COMPUTED_IS_NULL, name: intl.formatMessage({id:"fields.types.computation_is_null", defaultMessage:"Computation Is Null"})},
        {id: FieldTypes.COMPUTED_IS_BLANK, name: intl.formatMessage({id:"fields.types.computation_is_blank", defaultMessage:"Computation Is Blank"})},
        {id: FieldTypes.COMPUTED_NOT_EQUAL, name: intl.formatMessage({id:"fields.types.computation_not_equal", defaultMessage:"Computation Not Equal"})},
        {id: FieldTypes.COMPUTED_GREATER_EQUAL, name: intl.formatMessage({id:"fields.types.computation_greater_equal", defaultMessage:"Computation Greater Equal"})},
        {id: FieldTypes.COMPUTED_LESS_EQUAL, name: intl.formatMessage({id:"fields.types.computation_less_equal", defaultMessage:"Computation Less Equal"})},
    ];
}
