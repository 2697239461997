//TODO: Refactor calculated into computation
//TODO: Implement fields: `Value in computation` & `Language`
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";

import {graphQLApi, graphQLReduceFields} from "services/GraphQLApi";
import {useAuthDispatch} from "contexts/Auth";
import EditForm from "components/Form/EditForm";
import {Typography} from "@material-ui/core";
import FieldOptions from "./FieldOptions";
import ComputationFields from "./ComputationFields";
import ErrorAlert from '../Alerts/ErrorAlert';
import {isValueString} from "variables/general";

//constants
const DEFAULT_LANGUAGE_ID = '0';

export default function Field(props) {
  const intl = useIntl();
  const {
    id,
    field_group_id = null,
    onSaved,
    onChange,
    buttons
  } = props;

  const [fieldDefinitions, setFieldDefinitions] = useState([{id: '-', name: '...'}]);
  const fieldsArray = [
    {
      field: "name",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "fields.edit.label.name", defaultMessage: "Name"}),
      input: "text"
    },
    {
      field: "computation_value",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "fields.edit.label.computation_value", defaultMessage: "Computation Value"}),
      input: "text",
      disabled: d => !d.type.includes('computation')
    },
    {
      field: "computation_language_id",
      initial: "",
      type: "Int",
      label: intl.formatMessage({id: "fields.edit.label.language", defaultMessage: "Language"}),
      options: [{id: '-', name: '...'}],
      disabled: d => {
        console.log('computation_language_id.disabled = ', d.type.includes('computation'), d.uses_languages,
          'return = ', !(d.type.includes('computation') && d.uses_languages));
        return !(d.type.includes('computation') && d.uses_languages);
      }
    },
    {
      field: "entity_type_id",
      initial: null,
      type: "ID",
      query: "entityTypes",
      titleField: "title",
      label: intl.formatMessage({id: "fields.edit.label.entity_type", defaultMessage: "Data type"}),
    },
    {
      field: "type",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "fields.edit.label.type", defaultMessage: "Type"}),
      options: [{id: '-', name: '...'}],
      disabled: d => d.usage_count > 0
    },
    {
      column: 3,
      field: "uses_languages",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "fields.edit.label.uses_languages", defaultMessage: "Use language layer"}),
      input: "switch"
    },
    {
      column: 3,
      field: "is_listed",
      initial: false,
      type: "Boolean",
      label: intl.formatMessage({id: "fields.edit.label.is_listed", defaultMessage: "Shown in entities list?"}),
      input: "switch"
    }
  ];
  const [fields, setFields] = useState(fieldsArray);
  function filterFields(fields) {
    return fields.filter(field => field.field !== 'computation_language_id');
  }

  const [data, setData] = useState(graphQLReduceFields(fields, "initial"));
  const handleDataChange = (d) => {
    setFields(fields.map(field => {
      if (field.field === 'computation_value') return {
        ...field,
        input: isValueString(fieldDefinitions, d.type) ? "text" : "number"
      };
      return field;
    }));
    d = {...data, ...d};
    setData(d);
    if (typeof onChange === "function") {
      onChange(d);
    }
  }

  const [isLoading, setIsLoading] = useState(false);

  const initialValidation = graphQLReduceFields(fields, "validation");
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    console.error(errors);
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {

      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(
    useAuthDispatch(),
    props.history,
    null,
    {handleErrors: setValidationFromErrors}
  );
  const stableClient = useCallback(client, []);
  const stableId = useCallback(id, []);
  useEffect(() => {
    setIsLoading(true);
    let query = 'fieldDefinitions{id, column, title}' +
      'languages{data{id name locale}}' +
      'settings{data{id key data}}';
    if (stableId) {
      query += 'fields(filter:{id: ' + stableId + '}){ data {id computation_language{id name locale} ' + graphQLReduceFields(filterFields(fields), "fields") +
        'computation_fields { id name type }' +
        '} }';
    }
    stableClient.query('{' + query + '}').then(r => {
      setIsLoading(false);
      if (r) {
        if (r.hasOwnProperty('fieldDefinitions') && r.hasOwnProperty('languages') && r.hasOwnProperty('settings')) {
          setFieldDefinitions(r.fieldDefinitions.map(f => ({id: f.id, name: f.title})));
          const settings = {};
          r.settings.data.forEach(s => {
            settings[s.key] = s.data;
          })
          r.languages.data.unshift({id:DEFAULT_LANGUAGE_ID, locale:settings.locale, name:settings.language});
          let newFields = fields.map(f => {
            if (f.field === "type") return {...f, options: r.fieldDefinitions.map(f => ({id: f.id, name: f.title}))};
            if (f.field === "computation_language_id") return {...f, options: r.languages.data};
            return f;
          });
          console.log('setFields();', newFields)
          setFields(newFields);
        }
        if (r.hasOwnProperty('fields')) {
          setData({
            ...r.fields.data[0],
            // computation_language_id: r.fields.data[0].computation_language,
            entity_type_id: r.fields.data[0].entity_type,
            field_group_id: r.fields.data[0].field_group,
          });
        }
      }
    })
      .catch(e => console.trace(e));
  }, [stableClient, stableId]);

  const [alert, setAlert] = useState(null);
  function handleAlerts(message) {
    setAlert(message);
  }

  const whenSaved = (response) => {
    setIsLoading(false);
    if (typeof onSaved === "function") {
      onSaved(response);
    } else {
      if (id) {
        props.history.goBack();
      } else {
        props.history.replace(props.history.location.pathname.replace('create', response.id));
      }
    }
  }

  const save = () => {
    setIsLoading(true);
    setValidation(initialValidation);

    if (data.type.includes('computation') && computationRef && computationRef.current) {
      if (computationRef.current.hasOwnProperty('setComputationFields')) {
        computationRef.current.setComputationFields();
      }

      if (computationRef.current.hasOwnProperty('fieldsAreCompatible')) {
        if (!computationRef.current.fieldsAreCompatible()) {
          handleAlerts("Theres incompatible types in your fields");
          setTimeout(() => handleAlerts(null), 5000);
          setIsLoading(false);
          return;
        }
      }
    }

    let query =
      "(" +
      graphQLReduceFields(fields, "vars_def") +
      ' $computation_fields:[ID]!' +
      ") " +
      "{ response: " +
      "fieldCreate(" +
      graphQLReduceFields(fields, "vars") +
      `, computation_fields:$computation_fields` +
      ") " +
      "{ id } }";
    const variables = {
      entity_type_id: data.entity_type_id ? data.entity_type_id.id : null,
      type: data.type,
      is_listed: data.is_listed,
      uses_languages: data.uses_languages,
      name: data.name,
      key: data.key,
      sorting: data.sorting,
      computation_fields: data.computation_fields ? data.computation_fields.map(field => field.id) : [],
      computation_value: `${data.computation_value}`,
      computation_language_id: data.computation_language_id ? parseInt(data.computation_language_id.id) : null,
    };
    if (id) {
      variables.id = id;
      // modify query from a create to an update mutation
      query =
        "($id:ID!, " + query.substr(1).replace("Create(", "Update(id:$id, ");
    }
    if (field_group_id) {
      variables.field_groups = [field_group_id];
      query = query
        .replace("$type:String", "$type:String, $field_groups:[ID]")
        .replace("type:$type", "type:$type, field_groups:$field_groups");
    }

    client.mutate(query, variables).then(r => {
      if (r && r.hasOwnProperty('response') && r.response) {
        if (optionsRef && optionsRef.current && optionsRef.current.hasOwnProperty('saveOptions')) {
          optionsRef.current.saveOptions(r.response.id).then(() => {
            whenSaved(r.response);
          });
        } else {
          whenSaved(r.response);
        }
      }
    });
  }

  const optionsRef = useRef();
  const addOptionButton = {
    label: intl.formatMessage({id: "field_options.button.add", defaultMessage: "Add option"}),
    onClick: () => {
      if (optionsRef.current
        && optionsRef.current.hasOwnProperty('addOption')
        && typeof optionsRef.current.addOption === "function") {
        optionsRef.current.addOption();
      }
    }
  };

  const computationRef = useRef();
  const addComputationButton = {
    label: intl.formatMessage({id: "field_computation.button.add", defaultMessage: "Add computation"}),
    onClick: () => {
      if (computationRef.current && computationRef.current.hasOwnProperty('addComputation') && typeof computationRef.current.addComputation === 'function') {
        computationRef.current.addComputation();
      }
    }
  }

  function addButtons(additionalButtons) {
    if (Array.isArray(buttons)) {
      formButtons.push(additionalButtons);
    } else {
      formButtons = [additionalButtons];
    }
  }

  let formButtons = buttons;
  if (typeof data === "object") {
    if (data.type === 'option' || data.type === 'options') {
      addButtons(addOptionButton);
    }
    if (data.type.includes('computation')) {
      addButtons(addComputationButton);
    }
  }

  return (
    <>
      <EditForm {...props}
                data={data}
                setData={handleDataChange}
                query={"fields"}
                mutations={"field"}
                fields={fields}
                save={save}
                isLoading={isLoading}
                validation={validation}
                cols={3}
                buttons={formButtons}
                extraComponent={
                  (typeof data === "object" && (data.type === 'option' || data.type === 'options')) ?
                    <div style={{marginTop: "13px"}}>
                      <Typography variant="subtitle1"
                                  style={{borderBottom: "1px solid lightgrey"}}><strong>{intl.formatMessage({
                        id: "field_options.heading",
                        defaultMessage: "Options"
                      })}</strong></Typography>
                      <FieldOptions field={data} ref={optionsRef}/>
                    </div> : (typeof data === "object" && data.type.includes('computation')) ?
                      <div className={{marginTop: "13px"}}>
                        <Typography variant="subtitle1"
                                    style={{borderBottom: "1px solid lightgrey"}}>
                          <strong>{intl.formatMessage({
                            id: "field_computation.heading",
                            defaultMessage: "Computation Fields"
                          })}</strong>
                        </Typography>
                        <ComputationFields field={data} ref={computationRef}/>
                      </div> : ''
                }
      />
      {alert && <ErrorAlert message={alert}/>}
    </>
  );
}
