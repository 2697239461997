import {WebAsset} from "@material-ui/icons";

import AssetList from "views/Assets/AssetList";
import AssetEdit from "views/Assets/AssetEdit";
import {rolePriorities} from "config";

export default function routes(intl) {
  const prefix = '/assets';
  return [
    {
      path: "",
      name: intl.formatMessage({
        id: "menu.assets.list",
        defaultMessage: "SinglePage",
      }),
      icon: WebAsset,
      iconColor: "Info",
      component: AssetList,
      layout: "/admin",
      allow_priority: rolePriorities.can_assets,
      views: [
        {
          path: "/:id",
          name: intl.formatMessage({
            id: "menu.assets.edit",
            defaultMessage: "Edit asset",
          }),
          component: AssetEdit,
          hidden: true,
        },
        {
          path: "/create",
          name: intl.formatMessage({
            id: "menu.assets.create",
            defaultMessage: "Create asset",
          }),
          component: AssetEdit,
          hidden: true,
        },
      ]
    },
  ].map(r => ({...r, path: prefix + r.path}));
}