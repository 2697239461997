import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord
} from "@fortawesome/free-solid-svg-icons";

export function FileTypeIcon(mimeType) {
  const mimeTypes = {
    'image': faFileImage,
    'audio': faFileAudio,
    'video': faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileAlt,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive,
  }
  for (let key in mimeTypes) {
    if (mimeType && mimeType.search(key) === 0) {
      return mimeTypes[key];
    }
  }
  return faFile;
}

export function FileTypeClass(intl, mimeType, filename) {
  const mimeTypes = {
    "image": "image",
    "audio": "audio",
    "video": "video",
    // Documents
    "application/pdf": "pdf",
    "application/msword": "word",
    "application/vnd.ms-word": "word",
    "application/vnd.oasis.opendocument.text": "word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml": "word",
    "application/vnd.ms-excel": "excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml": "excel",
    "application/vnd.oasis.opendocument.spreadsheet": "excel",
    "application/vnd.ms-powerpoint": "powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml": "powerpoint",
    "application/vnd.oasis.opendocument.presentation": "powerpoint",
    "text/plain": "text",
    "text/html": "code",
    "application/json": "code",
    // Archives
    "application/gzip": "archive",
    "application/zip": "archive"
  }
  for (let key in mimeTypes) {
    if (mimeType && mimeType.search(key) === 0) {
      if (!intl) {
        return mimeTypes[key];
      }
      switch (mimeTypes[key]) {
        case 'image':
          return intl.formatMessage({id:"common.filetype.class.image", defaultMessage:"Image"});
        case 'audio':
          return intl.formatMessage({id:"common.filetype.class.audio", defaultMessage:"Audio"});
        case 'video':
          return intl.formatMessage({id:"common.filetype.class.video", defaultMessage:"Video"});
        case 'pdf':
          return intl.formatMessage({id:"common.filetype.class.pdf", defaultMessage:"Portable document"});
        case 'word':
          return intl.formatMessage({id:"common.filetype.class.document", defaultMessage:"Document"});
        case 'excel':
          return intl.formatMessage({id:"common.filetype.class.spreadsheet", defaultMessage:"Spreadsheet"});
        case 'powerpoint':
          return intl.formatMessage({id:"common.filetype.class.presentation", defaultMessage:"Presentation"});
        case 'text':
          if (filename) {
            switch (filename.split('.').pop().toLowerCase()) {
              case 'tsv':
              case 'csv':
                return intl.formatMessage({id:"common.filetype.class.data", defaultMessage:"Data"});
              case 'css':
              case 'ts':
              case 'js':
              case 'php':
                return intl.formatMessage({id:"common.filetype.class.code", defaultMessage:"Code"});
              default:
            }
          }
          return intl.formatMessage({id:"common.filetype.class.text", defaultMessage:"Text"});
        case 'code':
          return intl.formatMessage({id:"common.filetype.class.code", defaultMessage:"Code"});
        case 'archive':
          return intl.formatMessage({id:"common.filetype.class.archive", defaultMessage:"Archive"});
        default:
      }
    }
  }
  if (intl) {
    return intl.formatMessage({
      id: "common.filetype.class.unknown",
      defaultMessage: "{ext} file"
    }, {ext: filename ? filename.split('.').pop().toLocaleUpperCase() : 'Unknown'});
  }
  return 'Unknown';
}