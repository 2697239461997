import React from "react";
import {useIntl} from "react-intl";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {VideoLabel} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";

export default function LabelEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "title",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "labels.edit.label.title", defaultMessage: "Title"}),
            input: "text"
        },
        {
            field: "entity_type_id",
            initial: null,
            type: "ID",
            query: "entityTypes",
            titleField: "title",
            label: intl.formatMessage({id: "labels.edit.label.entity_type", defaultMessage: "Entity type"}),
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        avatar={<VideoLabel color="primary"/>}
                        title={intl.formatMessage({id: "labels.edit.heading", defaultMessage:"Labels edit"})}
                        titleTypographyProps={{color:"primary"}}
                    />
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"labels"}
                                         mutations={"label"}
                                         fields={fields}
                                         cols={2}
                                         {...props}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
