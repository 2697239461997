import {IconButton} from "@material-ui/core";
import {Delete, Create} from "@material-ui/icons";
import {EditTableClickProps} from "./EditTableTypes";
import PropTypes from "prop-types";

export default function EditTableButtons({onClickEdit, onClickDelete, rowId}) {
    return (
        <>
            <IconButton onClick={() => onClickEdit(rowId)}>
                <Create />
            </IconButton>
            <IconButton onClick={() => onClickDelete(rowId)}>
                <Delete />
            </IconButton>
        </>
    );
}

EditTableButtons.propTypes = {
    ...EditTableClickProps(),
    rowId: PropTypes.string.isRequired
};