import React from "react";
import {useIntl} from "react-intl";
import {Box, Card, CardContent, CardHeader, Grid, Slider, Typography} from "@material-ui/core";
import {People} from "@material-ui/icons";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import {authUser} from "contexts/Auth";
import {roles} from "config";

export default function RoleEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);
  const rolesFiltered = roles(intl).filter(r => r.id >= authUser().roles[0].priority).reverse();
  console.log(rolesFiltered);

  const fields = [
    {
      field: "title",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "roles.edit.label.title", defaultMessage: "Title"}),
      input: "text"
    },
    {
      field: "description",
      initial: "",
      type: "String",
      label: intl.formatMessage({id: "roles.edit.label.description", defaultMessage: "Description"}),
      input: "text"
    },
    {
      field: "priority",
      initial: 250,
      type: "Int",
      render: (key, row, setData) => <>
        <Typography id={"form-field-" + key + "-label"}>
          {intl.formatMessage({id: "roles.edit.label.priority", defaultMessage: "Rettigheder for rollen"})}
        </Typography>
        <Box height={rolesFiltered.length*25} margin={2}>{/*roles(intl).filter(r => r.id >= authUser().roles[0].priority).length*30*/}
        <Slider
          aria-labelledby={"form-field-" + key + "-label"}
          orientation="vertical"
          value={rolesFiltered.findIndex(r => r.id === parseInt(row.priority))}
          onChange={(_e, value) => {
            setData(cur=>({...cur, priority: rolesFiltered[value].id}));
          }}
          marks={rolesFiltered.map((r,i) => ({value: i, label: r.name}))}
          min={0}
          max={rolesFiltered.length-1}
          step={null}
        /></Box></>
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            avatar={<People color="primary"/>}
            title={intl.formatMessage({id: "roles.edit.heading", defaultMessage: "Roles edit"})}
            titleTypographyProps={{color: "primary"}}
          />
          <CardContent>
            <GraphQLEditForm id={id}
                             query={"roles"}
                             mutations={"role"}
                             fields={fields}
                             cols={2}
                             {...props}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
