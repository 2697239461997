import React from "react";
import {Paper} from "@material-ui/core";
import {Share} from "@material-ui/icons";
import {useIntl} from "react-intl";
import EnhancedTable from "components/DataTable/EnhancedTable";

export default function ConnectorList(props) {
  const intl = useIntl();
  const columns = [
    {title: intl.formatMessage({id: 'connectors.list.column.title', defaultMessage: 'Title'}), field: 'title'},
    {title: intl.formatMessage({id: 'connectors.list.column.driver', defaultMessage: 'Driver'}), field: 'driver'},
    {
      title: intl.formatMessage({id: 'connectors.list.column.is_active', defaultMessage: 'Is Active'}),
      field: 'is_active',
      type: 'boolean',
      initial: false
    }
  ];

  return (
    <Paper>
      <EnhancedTable
        {...props}
        title={intl.formatMessage({id: 'connectors.list.table.heading', defaultMessage: "Connectors list"})}
        columns={columns}
        query="connectors"
        mutations="connector"
        fields='id driver title is_active'
        icon={<Share/>}
        urlState
      />
    </Paper>
  );
}