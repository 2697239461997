import React from "react";
import {Grid, Card, CardHeader, CardContent} from "@material-ui/core";
import {useIntl} from "react-intl";
import GraphQLEditForm from "components/GraphQL/GraphQLEditForm";
import {Ballot} from "@material-ui/icons";

export default function AssetEdit(props) {
    const intl = useIntl();

    let id = Number(props.match.params.id);

    const fields = [
        {
            field: "type",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assets.edit.label.type", defaultMessage: "Type"}),
            input: "text"
        },
        {
            field: "file",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assets.edit.label.file", defaultMessage: "File"}),
            input: "file",
            accepts: "*",
        },
        {
            field: "thumbnail",
            initial: "",
            type: "String",
            label: intl.formatMessage({id: "assets.edit.label.thumbnail", defaultMessage: "Thumbnail"}),
            input: "file",
            accepts: "*",
        },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardHeader color="primary" avatar={<Ballot/>} title={intl.formatMessage({id: "assets.edit.heading"})}/>
                    <CardContent>
                        <GraphQLEditForm id={id}
                                         query={"assets"}
                                         mutations={"asset"}
                                         fields={fields} cols={2} {...props} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}