export function FtpFileServerTest(intl) {
  return {
    'host': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.host", defaultMessage: "Connection test failed, check host setting."})],
    'port': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.port", defaultMessage: "Connection test failed, check port setting."})],
    'username': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.username", defaultMessage: "Connection test failed, check username setting."})],
    'password': [intl.formatMessage({id: "connector.driver.ftp_file_upload.test.password", defaultMessage: "Connection test failed, check password setting."})],
  }
}

export default function FtpFileServer(intl) {
  return [
    {
      field: "filetype",
      initial: "xml",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.filetype", defaultMessage: "Filetype"}),
      options: [
        {id:"xml", name:"XML file"},
        {id:"csv", name:"Comma separated file"},
        {id:"tsv", name:"Tab separated file"},
      ]
    },
    {
      field: "filepath",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.filepath", defaultMessage: "Filepath"}),
      help: intl.formatMessage({
        id: "connector.driver.ftp_file_upload.full_filepath_help",
        defaultMessage: "The full file path including the files name. Example: products/export_from_pim/products.xml"
      }),
    },
    {
      field: "host",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.host", defaultMessage: "Host"}),
    },
    {
      field: "port",
      initial: 21,
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.port", defaultMessage: "Port"}),
      input: "number",
    },
    {
      field: "encryption",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.encryption", defaultMessage: "Secure transport"}),
      options: [
        {id:"", name:"None"},
        {id:"ssl", name:"SSL"},
      ]
    },
    {
      field: "username",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.username", defaultMessage: "Username"}),
      inputProps: {autoComplete: "off"},
    },
    {
      field: "password",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.ftp_file_upload.password", defaultMessage: "Password"}),
      input: "password",
      inputProps: {autoComplete: "off"}
    }
  ];
}
