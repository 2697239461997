import React from "react";
import {Chip, Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {CallSplit} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function EntityTypeList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'entity_types.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'entity_types.list.column.fields', defaultMessage:'Fields'}), field: 'fields_count', type:'integer'},
        {title: intl.formatMessage({id:'entity_types.list.column.roles', defaultMessage:'Roles'}), render: row => row.roles.map(r => <Chip label={r.title} size="small" style={{marginRight:3}}/>)},
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'entity_types.list.table.heading', defaultMessage:"Data Types list"})}
          columns={columns}
          query="entityTypes"
          mutations="entityType"
          fields='id title fields_count roles{title}'
          icon={<CallSplit/>}
          urlState
          {...props}
        />
      </Paper>
    );
}