import React, { useCallback, useEffect, useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
// @material-ui/icons components
// core components
import componentStyles from 'assets/theme/components/sidebar.js'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { useIntl } from 'react-intl'
import { Delete } from '@material-ui/icons'
import { graphQLApi } from 'services/GraphQLApi'
import { authUser, useAuthDispatch } from 'contexts/Auth'
import { listingsFilterableFieldTypes, stringFieldTypes } from 'variables/fields'

const useStyles = makeStyles(componentStyles);

export default function Listings({entityTypeId, history, onChange, setIsLoading}) {
  const classes = useStyles();
  const intl = useIntl();

  const defaultListings = [
    {
      id: "0",
      entity_type: {id: entityTypeId},
      user: {id: authUser().id},
      title: intl.formatMessage({id: "products.sidebar.listing-select.own-list", defaultMessage: "Your own list"}),
    },
  ];
  const operators = [
    {value: "like", title: intl.formatMessage({id: "products.sidebar.operator.contains", defaultMessage: "Contains"})},
    {
      value: "not like",
      title: intl.formatMessage({id: "products.sidebar.operator.does_not_contain", defaultMessage: "Does not contain"})
    },
    {
      value: "like starts",
      title: intl.formatMessage({id: "products.sidebar.operator.starts_with", defaultMessage: "Starts with"})
    },
    {
      value: "not like starts",
      title: intl.formatMessage({id: "products.sidebar.operator.not_starts_with", defaultMessage: "Does not start with"})
    },
    {
      value: "like ends",
      title: intl.formatMessage({id: "products.sidebar.operator.ends_with", defaultMessage: "Ends with"})
    },
    {
      value: "not like ends",
      title: intl.formatMessage({id: "products.sidebar.operator.not_ends_with", defaultMessage: "Does not end with"})
    },
    {value: "=", title: intl.formatMessage({id: "products.sidebar.operator.equal", defaultMessage: "Equal to"})},
    {
      value: "!=",
      title: intl.formatMessage({id: "products.sidebar.operator.not_equal", defaultMessage: "Not equal to"})
    },
    {value: "<", title: intl.formatMessage({id: "products.sidebar.operator.less", defaultMessage: "Less than"})},
    {
      value: "<=",
      title: intl.formatMessage({id: "products.sidebar.operator.less_or_equal", defaultMessage: "Less or equal"})
    },
    {value: ">", title: intl.formatMessage({id: "products.sidebar.operator.greater", defaultMessage: "Greater than"})},
    {
      value: ">=",
      title: intl.formatMessage({id: "products.sidebar.operator.greater_or_equal", defaultMessage: "Greater or equal"})
    },
  ];
  const [listings, setListings] = useState(defaultListings);
  const [listing, setListing] = useState(defaultListings[0].id);

  const [addFilterDialogOpen, setAddFilterDialogOpen] = useState(false);
  const [filterFields, setFilterFields] = useState([]);
  const [filterField, setFilterField] = useState("");
  const [filterOperator, setFilterOperator] = useState(operators[0].value);
  const [filterValue, setFilterValue] = useState("");
  const [listingOperands, setListingOperands] = useState([]);

  const client = new graphQLApi(useAuthDispatch(), history);
  useEffect(() => {
    typeof setIsLoading === "function" && setIsLoading(true);
    client.query('{' +
      ' fields(sorting:"name",direction:"asc"){data{' +
      '   id name type entity_type{id title} field_options{id title}' +
      ' }} ' +
      ' listings(sorting:"title",direction:"asc",filter:{entity_type_id:' + entityTypeId + '}){data{' +
      '   id title comparator user{id} entity_type{id}' +
      ' }}' +
      '}')
      .then(result => {
        if (result) {
          if (result.hasOwnProperty('fields')) {
            let fieldTypeIndexed = {};
            let entityTypes = [];
            let fields = [];
            result.fields.data
              .filter(f => listingsFilterableFieldTypes.indexOf(f.type) !== -1)
              .forEach(f => {
                if (!fieldTypeIndexed[f.entity_type.id]) fieldTypeIndexed[f.entity_type.id] = [];
                if (entityTypes.indexOf(f.entity_type.id) === -1) entityTypes.push(f.entity_type.id);
                fieldTypeIndexed[f.entity_type.id].push(f);
              });
            entityTypes.forEach(e => fieldTypeIndexed[e].sort((a, b) => {
              if (a.title < b.title) return -1;
              if (a.title > b.title) return 1;
              return 0;
            }).forEach(f => fields.push(f)));
            setFilterFields(fields);
          }
          setListingOperands([]);
          if (result.hasOwnProperty('listings')) {
            let lists = result.listings.data;
            let list = lists.findIndex(l => l.user && parseInt(l.user.id) === authUser().id);
            if (list === -1) {
              lists.unshift(defaultListings[0]);
              if (onChange) onChange(defaultListings[0]);
            } else {
              setListing(lists[list].id);
              if (onChange) onChange(lists[list]);
              getListingOperands(lists[list].id);
              lists.unshift(lists.splice(list, 1).pop());
            }
            setListings(lists);
          }
        }
      })
      .finally(() => {
        typeof setIsLoading === "function" && setIsLoading(false);
      });
  }, [entityTypeId]);

  const getListingOperands = (listingId) => {
    client.query('{' +
      ' listingOperands(filter:{listing_id:' + listingId + '}){data{' +
      '   id field{id name type field_options{id title} } column operator value ' +
      ' }}' +
      '}')
      .then(result => {
        if (result && result.hasOwnProperty('listingOperands')) {
          setListingOperands(result.listingOperands.data);
          const listing = listings.find(l => l.id === listingId);
          if (onChange && listing) onChange(listing);
        }
      });
  }

  const openAddFilterDialog = (event, filter = null) => {
    setFilterField(filter ? filter.field ? filter.field.id : filter.column : "");
    setFilterValue(filter ? filter.value : "");
    setFilterOperator(filter ? operators.find(f => f.operator === filter.operator).value : operators[0].value);
    setAddFilterDialogOpen(true);
  }

  const removeFilter = (index) => {
    let temp = [...listingOperands];
    temp.splice(index, 1);
    setListingOperands(temp);
    saveListingOperands(temp);
  }

  const addFilter = () => {
    const field = filterFields.find(f => f.id === filterField);
    const filter = {
      id: null,
      field: field,
      column: field && field.id.search(/[a-z_]+/) !== -1 ? field.id : null,
      operator: filterOperator,
      value: filterValue,
    };
    let temp = [...listingOperands];
    temp = [...temp, filter];
    saveListingOperands(temp);
    setAddFilterDialogOpen(false);
  }

  const saveListingOperands = (listOperands) => {
    let vars = {
      entity_type_id: "ID",
      title: "String",
      comparator: "String",
      listing_operands: "[ListingOperandInput]",
    };
    let data = {...listings.find(l => l.id === listing), comparator: "and"};
    if (listing > 0) {
      vars.id = "ID!";
    } else {
      data.id = undefined;
    }
    if (data.user) {
      data.user_id = data.user.id;
      vars.user_id = "ID";
    }
    data.listing_operands = listOperands.map(f => ({
      id: f.id,
      field_id: !f.column && f.field ? f.field.id : null,
      column: f.column,
      operator: f.operator,
      value: f.value,
    }));
    typeof setIsLoading === "function" && setIsLoading(true);
    client.mutation('listing', vars, data, "id entity_type{id} title comparator user{id} listing_operands{id field{id name type field_options{id title}} column operator value}")
      .then(result => {
        if (result) {
          setListings(cur => {
            let i = cur.findIndex(l => l.id === listing);
            let t = [...cur];
            t[i] = result.response;
            return t;
          });
          setListing(result.response.id);
          setListingOperands(result.response.listing_operands);
          if (onChange) onChange(result.response);
        }
      })
      .finally(() => {
        typeof setIsLoading === "function" && setIsLoading(false);
      });
  }

  const [saveListDialogOpen, setSaveListDialogOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [listTitle, setListTitle] = useState("");

  const openSaveListDialog = () => {
    typeof setIsLoading === "function" && setIsLoading(true);
    client.query('{roles{data{id title}} listings(filter:{id:' + listing + '}){data{title roles{id}}} }')
      .then(result => {
        if (result && result.roles && result.listings) {
          setRoles(result.roles.data);
          if (result.listings.data.length) {
            setListTitle(result.listings.data[0].title);
            setSelectedRoles(result.listings.data[0].roles ? result.listings.data[0].roles.map(r => r.id) : []);
          } else {
            setListTitle("");
            setSelectedRoles(authUser().roles.map(r => String(r.id)));
          }
          setSaveListDialogOpen(true);
        }
      })
      .finally(() => {
        typeof setIsLoading === "function" && setIsLoading(false);
      });
  }

  const saveList = useCallback(() => {
    let vars = {
      id: "ID!",
      entity_type_id: "ID",
      title: "String",
      user_id: "ID",
      roles: "[ID]",
    };
    typeof setIsLoading === "function" && setIsLoading(true);
    client.mutation('listing', vars, {
      id: listing,
      title: listTitle,
      entity_type_id: entityTypeId,
      user_id: null,
      roles: selectedRoles
    }, 'id')
      .then(result => {
        if (result && result.response.id) {
          setSaveListDialogOpen(false);
          setListings(cur => {
            let i = cur.findIndex(l => l.id === listing);
            if (i === -1) return cur;
            let n = [...cur];
            n[i].title = listTitle;
            n[i].user = null;
            setListings(n);
            setListing(result.response.id);
          });
        }
      })
      .finally(() => {
        typeof setIsLoading === "function" && setIsLoading(false);
      });
  }, [listTitle, listing, selectedRoles]);

  const handleSelectedRoles = (e, id) => {
    let rolesTemp = [...selectedRoles];
    if (rolesTemp.find(role => role === id) !== undefined) {
      rolesTemp = rolesTemp.filter(role => role !== id);
    } else {
      rolesTemp.push(id);
    }
    setSelectedRoles(rolesTemp);
  }

  return (
    <>
      <Drawer variant="permanent" anchor="left" open classes={{paper: classes.drawerPaper}}>
        <Paper style={{padding: 5, margin: 10}} aria-roledescription="List filter box">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="listing-select-label">{intl.formatMessage({
                  id: "products.sidebar.listing-select.label",
                  defaultMessage: "Selected list"
                })}</InputLabel>
                <Select
                  labelId="listing-select-label"
                  id="listing-select"
                  value={listings.findIndex(l => l.id === listing) !== -1 ? listing : ""}
                  onChange={e => {
                    setListing(e.target.value);
                    getListingOperands(e.target.value);
                    const index = listings.findIndex(l => l.id === e.target.value);
                    if (index === -1) return;
                    setListTitle(listings[index].title);
                  }}
                >
                  {listings.map((l, k) => <MenuItem key={"listing-select-item-" + k}
                                                    value={l.id}>{l.title}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{textAlign: "center"}}>
              {!listingOperands.length ? <Typography color="secondary">{intl.formatMessage({
                  id: "products.sidebar.listing.no-filters",
                  defaultMessage: "There are no filters!"
                })}</Typography> :
                <TableContainer component={Box}><Table size="small"><TableBody>
                  {listingOperands.map((f, k) => <TableRow key={"listing-operand-" + k}>
                      <TableCell
                        style={{padding: 2}}>{f.column ? filterFields.find(field => field.id === f.column).name : f.field.name}</TableCell>
                      <TableCell style={{padding: 2}}>{operators.find(o => o.value === f.operator).title}</TableCell>
                      <TableCell
                        style={{padding: 2}}>{f.field.field_options.length ? f.field.field_options.find(o => o.id === f.value).title : f.value}</TableCell>
                      <TableCell style={{padding: 2}} align="right">
                        <IconButton size="small" onClick={_e => removeFilter(k)}>
                          <Delete style={{width: 18, height: 18}}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody></Table></TableContainer>
              }
            </Grid>
            <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}>
              {listings.find(l => l.id === listing) && listings.find(l => l.id === listing).user ?
                <Button variant="contained" color="primary" size="small" style={{padding: "2px 4px"}}
                        onClick={openSaveListDialog}>
                  {intl.formatMessage({id: "products.sidebar.listing.save-list", defaultMessage: "Save"})}
                </Button> :
                <Button variant="contained" color="primary" size="small" style={{padding: "2px 4px"}}
                        onClick={openSaveListDialog}>
                  {intl.formatMessage({id: "products.sidebar.listing.edit-list", defaultMessage: "Edit"})}
                </Button>}
              <Button variant="contained" color="primary" style={{padding: "2px 4px"}} size="small"
                      onClick={openAddFilterDialog}>
                {intl.formatMessage({id: "products.sidebar.listing.add-filter", defaultMessage: "Add"})}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <List classes={{root: classes.listRoot}}>
          {/* Show list of views as menu items */}
        </List>
      </Drawer>
      <Dialog open={addFilterDialogOpen} onClose={_e => setAddFilterDialogOpen(false)}>
        <DialogTitle disableTypography>{intl.formatMessage({
          id: "products.sidebar.listing.filter-dialog.title",
          defaultMessage: "Define filter"
        })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="filter-field-label">{intl.formatMessage({
                    id: "products.sidebar.filter-dialog.filter-field.label",
                    defaultMessage: "Field"
                  })}</InputLabel>
                  <Select
                    labelId="filter-field-label"
                    id="filter-field"
                    value={filterField}
                    onChange={e => setFilterField(e.target.value)}
                  >
                    {filterFields.map((f, k) => <MenuItem key={"filter-field-select-item-" + k}
                                                          value={f.id}>{f.entity_type.title + ': ' + f.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="filter-operator-label">{intl.formatMessage({
                    id: "products.sidebar.filter-dialog.filter-operator.label",
                    defaultMessage: "Operator"
                  })}</InputLabel>
                  <Select
                    disabled={!filterField}
                    labelId="filter-operator-label"
                    id="filter-operator"
                    value={filterOperator}
                    onChange={e => setFilterOperator(e.target.value)}
                  >
                    {operators.filter(o => {
                      let field = filterFields.find(f => f.id === filterField);
                      if (!field) return false;
                      if (stringFieldTypes.indexOf(field.type) !== -1) {
                        return ['=', '!=', 'like', 'not like', 'like starts', 'not like starts', 'like ends', 'not like ends'].indexOf(o.value) !== -1
                      } else {
                        return ['=', '!=', '<', '<=', '>', '>='].indexOf(o.value) !== -1
                      }
                    }).map((o, k) =>
                      <MenuItem key={"filter-operator-select-item-" + k} value={o.value}>{o.title}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                {(filterFields.find(f => f.id === filterField) && filterFields.find(f => f.id === filterField).field_options.length)
                  ? <FormControl fullWidth>
                    <InputLabel id="filter-value-label">{intl.formatMessage({
                      id: "products.sidebar.filter-dialog.filter-value.label",
                      defaultMessage: "Value"
                    })}</InputLabel>
                    <Select
                      disabled={!filterField}
                      labelId="filter-value-label"
                      id="filter-value"
                      value={filterValue}
                      onChange={e => setFilterValue(e.target.value)}
                    >
                      {filterFields.find(f => f.id === filterField).field_options.map((o, k) =>
                        <MenuItem key={"filter-option-select-item-" + k} value={o.id}>{o.title}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  : <TextField
                    fullWidth
                    label={intl.formatMessage({
                      id: "products.sidebar.filter-dialog.filter-value.label",
                      defaultMessage: "Value"
                    })}
                    id="filter-value"
                    value={filterValue}
                    onChange={e => setFilterValue(e.target.value)}
                  />}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_e => setAddFilterDialogOpen(false)}>
            {intl.formatMessage({
              id: "common.button.close",
              defaultMessage: "Close"
            })}
          </Button>
          <Button disabled={!filterField || !filterOperator || !filterValue || !filterValue.length} color="primary"
                  variant="contained"
                  onClick={addFilter}>
            {intl.formatMessage({
              id: "common.button.save",
              defaultMessage: "Save"
            })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={saveListDialogOpen} onClose={_e => setSaveListDialogOpen(false)}>
        <DialogTitle disableTypography>{intl.formatMessage({
          id: "products.sidebar.listing.save-dialog.title",
          defaultMessage: "Save as a list"
        })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage({
                    id: "products.sidebar.listing.save-dialog.label.title",
                    defaultMessage: "Title"
                  })}
                  value={listTitle}
                  onChange={e => setListTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend">{intl.formatMessage({
                    id: "products.sidebar.listing.save-dialog.label.roles",
                    defaultMessage: "Show the list to these roles"
                  })}</FormLabel>
                  <Grid container spacing={2}>
                    {[{start: 0, len: Math.ceil(roles.length / 2)},
                      {start: Math.ceil(roles.length / 2), len: Math.floor(roles.length / 2)}].map((count, k) => <Grid
                      item xs={6} key={"role-col-" + k}>
                      <FormGroup>
                        {roles.slice(count.start, count.start + count.len).map((role, idx) => <FormControlLabel
                          key={"role-" + idx}
                          control={<Checkbox
                            checked={selectedRoles.includes(role.id)}
                            onChange={e => handleSelectedRoles(e, role.id)}
                            name={role.id}/>}
                          label={role.title}
                        />)}
                      </FormGroup>
                    </Grid>)}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={_e => setSaveListDialogOpen(false)}>
            {intl.formatMessage({
              id: "common.button.close",
              defaultMessage: "Close"
            })}
          </Button>
          <Button disabled={!listTitle || !listTitle.length} color="primary" variant="contained"
                  onClick={saveList}>
            {intl.formatMessage({
              id: "common.button.save",
              defaultMessage: "Save"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
