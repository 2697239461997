import React from "react";
import {useIntl} from "react-intl";
import {Grid, Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";

export default function PrintoutList(props) {
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({id: 'printouts.list.column.title', defaultMessage: 'Title'}),
      field: 'title'
    },
    {
      title: intl.formatMessage({id: 'printouts.list.column.created_at', defaultMessage: 'Created'}),
      field: 'created_at',
      type: 'datetime'
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
            <EnhancedTable
                columns={columns}
                query={"printouts"}
                mutations={"printout"}
                fields="title created_at"
                title={intl.formatMessage({id: "menu.printouts.list"})}
                {...props}
            />
        </Paper>
      </Grid>
    </Grid>
  );
}