import React from "react";
import {Grid} from "@material-ui/core";
import {ViewColumn} from "@material-ui/icons";
import GraphQLTable from "components/GraphQL/GraphQLTable";
import {useIntl} from "react-intl";

export default function ViewList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'views.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {title: intl.formatMessage({id:'views.list.column.listing', defaultMessage:'List'}), field: 'listing.title'},
        {title: intl.formatMessage({id:'views.list.column.sorting', defaultMessage:'Sorting'}), field: 'sorting', type: 'numeric'}
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <GraphQLTable
                    title={intl.formatMessage({id:'views.list.table.heading', defaultMessage:"Views list"})}
                    columns={columns}
                    query="views"
                    mutations="view"
                    fields='id title listing{title} sorting'
                    icon={<ViewColumn/>}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}