import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/views/auth/login.js";
import {authResend, authSignOut, authUser, getApiDomain, useAuthDispatch} from "contexts/Auth";
import {CardHeader, CircularProgress} from "@material-ui/core";
import {useIntl} from "react-intl";

const useStyles = makeStyles(componentStyles);

function Resend(props) {
    const intl = useIntl();
    const dispatch = useAuthDispatch();
    const user = authUser();

    if (user && user.email_verified_at) props.history.push('/');

    const [isLoading, setIsLoading] = React.useState(false);
    const [sent, setSent] = React.useState(false);

    const [domainCheck, setDomainCheck] = React.useState(null);

    React.useEffect(() => {
        if (domainCheck === null) {
            fetch(getApiDomain() + "/", {}).then((response) => {
                if (response.status === 200) {
                    return response.text().then((text) => {
                        if (text.indexOf(process.env.REACT_APP_NAME) !== -1 &&
                            text.indexOf("TINX/DK") !== -1) {
                            setDomainCheck(true);
                        } else {
                            setDomainCheck(false);
                            console.error("Response from api check did not validate", text);
                        }
                    });
                } else {
                    setDomainCheck(false);
                }
            }).catch(reason => {
                console.error('Failed to validate the API domain', getApiDomain() + "/", reason);
                setDomainCheck(false);
            });
        }
    });

    const getValidation = (apiValidation) => {
        if (apiValidation.message === "Unauthorized") {
            authSignOut(dispatch);
            return props.history.push('/');
        }
        setSent(true);
    };
    
    const performVerify = (e) => {
        e.preventDefault();
        authResend(dispatch, props.history, setIsLoading, user.email, getValidation);
    };

    const classes = useStyles();
    return (
        <>
            <Grid item xs={12} lg={5} md={7}>
              <form onSubmit={performVerify}>
                    <Card classes={{root: classes.cardRoot}}>
                      <CardHeader title={intl.formatMessage({id:"auth.verification_required",defaultMessage:"Verification required"})} classes={{root: classes.cardRoot}} />
                        <CardContent classes={{root: classes.cardContent}} style={{padding:"20px 1.5rem"}}>
                            <div
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({
                                    id: "auth.verification_message",
                                    defaultMessage: `To continue to the site it is necessary to verify your e-mail address.
                                    Click the button to send a verification link to your e-mail {email}.
                                    When you receive the e-mail, you have to click the link in it to verify your e-mail, after that you will be able to log in.`
                                }, {email: user.email})}}
                            />
                            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                {isLoading ?
                                    <CircularProgress/>
                                    :
                                    <Button disabled={sent} color="primary" variant="contained" onClick={performVerify}>
                                        {intl.formatMessage({id: !sent ? "auth.send_verification_email" : "auth.sent_verification_email"})}
                                    </Button>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </form>
            </Grid>
        </>
    );
}

export default Resend;