import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";


const AssetTagFilterDialog = ((props) => {
    const {open, close, showTags} = props;

    return <Dialog open={open}>
        <DialogTitle disableTypography><Typography variant="h2">Tag filter</Typography></DialogTitle>
        <DialogContent>{showTags()}</DialogContent>
        <DialogActions>
            <Button onClick={close}>Close</Button>
        </DialogActions>
    </Dialog>

})

AssetTagFilterDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    showTags: PropTypes.func.isRequired,
    // tags: PropTypes.arrayOf(PropTypes.shape({
    //     id: PropTypes.number,
    //     name: PropTypes.string,
    // })).isRequired
}

export default AssetTagFilterDialog;