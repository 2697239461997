import {Ballot, Print, Settings, SwapHoriz, WebAsset} from "@material-ui/icons";
import Entities from "layouts/Entities";
import SinglePage from "layouts/SinglePage";
import assetRoutes from "routes/assets";
import channelRoutes from "routes/channels";
import {authUser} from "contexts/Auth";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";
import Printout from "./layouts/Printout";

export const rolePriorities = {
  developer: 5,
  site_manager: 10,
  admin: 25,
  can_users: 50,
  can_fields: 75,
  can_import_export: 100,
  can_assets: 125,
  can_data: 150,
  view_data: 175,
};

export const roles = (intl) => [
    {id: 5, name: intl.formatMessage({id: "roles.priority.5", defaultMessage: "Is system developer"})},
    {id: 10, name: intl.formatMessage({id: "roles.priority.10", defaultMessage: "Can edit sites"})},
    {id: 25, name: intl.formatMessage({id: "roles.priority.25", defaultMessage: "Can edit settings"})},
    {id: 50, name: intl.formatMessage({id: "roles.priority.50", defaultMessage: "Can edit users"})},
    {id: 75, name: intl.formatMessage({id: "roles.priority.75", defaultMessage: "Can edit fields"})},
    {id: 100, name: intl.formatMessage({id: "roles.priority.100", defaultMessage: "Can import/export"})},
    {id: 125, name: intl.formatMessage({id: "roles.priority.125", defaultMessage: "Can edit assets"})},
    {id: 150, name: intl.formatMessage({id: "roles.priority.150", defaultMessage: "Can edit data"})},
    {id: 175, name: intl.formatMessage({id: "roles.priority.175", defaultMessage: "Can view data"})},
  ];

export const features = {}
// const hasFeature = (feature) => this.features.findIndex(f => f.key === feature) !== -1;

export function getLayouts(intl) {
  let userPrio = authUser().roles.length ? authUser().roles[0].priority : 1000;
  let adminDefRoute = "/admin/connectors";
  if (userPrio <= rolePriorities.can_fields) {
    adminDefRoute = "/admin/fields";
  }
  if (userPrio <= rolePriorities.can_users) {
    adminDefRoute = "/admin/users";
  }
  if (authUser().roles.length === 1 && authUser().roles[0].key === "sites_manager") {
    return {
      auth: {
        title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
        authenticated: false,
        component: Auth,
        acl: null,
        features: [],
        path: "/",
        defaultRoute: "/login"
      },
      admin: {
        title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
        icon: Settings,
        authenticated: true,
        component: Admin,
        acl: rolePriorities.site_manager,
        features: [],
        path: "/admin",
        defaultRoute: "/admin/sites"
      },
    }
  }
  return {
    auth: {
      title: intl.formatMessage({id: "layouts.auth", defaultMessage: "Frontpage"}),
      authenticated: false,
      component: Auth,
      acl: null,
      features: [],
      path: "/",
      defaultRoute: "/login"
    },
    entities: {
      title: intl.formatMessage({id: "layouts.entities", defaultMessage: "Data"}),
      icon: Ballot,
      authenticated: true,
      component: Entities,
      acl: rolePriorities.view_data,
      features: [],
      path: "/data/:entityTypeID?",
      defaultRoute: "/data",
      contextQuery: "entityTypes{data{id title}}",
      contextResultTitles: (result) => {
        return result.entityTypes.data;
      },
    },
    assets: {
      title: intl.formatMessage({id: "layouts.assets", defaultMessage: "Assets"}),
      icon: WebAsset,
      authenticated: true,
      component: SinglePage,
      acl: rolePriorities.can_assets,
      features: [],
      path: "/assets",
      routes: assetRoutes(intl),
    },
    channels: {
      title: intl.formatMessage({id: "layouts.channels", defaultMessage: "Channels"}),
      icon: SwapHoriz,
      authenticated: true,
      component: SinglePage,
      acl: rolePriorities.can_import_export,
      features: [],
      path: "/channels",
      routes: channelRoutes(intl)
    },
    admin: {
      title: intl.formatMessage({id: "layouts.admin", defaultMessage: "Administration"}),
      icon: Settings,
      authenticated: true,
      component: Admin,
      acl: rolePriorities.can_import_export,
      features: [],
      path: "/admin",
      defaultRoute: adminDefRoute
    },
    printout: {
      title: intl.formatMessage({id: "layouts.printout", defaultMessage: "Printout"}),
      icon: Print,
      authenticated: true,
      hidden: true,
      component: Printout,
      acl: rolePriorities.can_import_export,
      features: [],
      path: "/printout/:id"
    },
  };
}

export const config = {
  brandLogo: require("assets/img/brand/logo-black-no-background.svg"),
  emailVerification: true,
  layoutNavigation: "horisontal",  // context = in the user profile context menu, horisontal = top navbar with no breadcrumb
  viewNavigation: "left",       // left = left vertical, top = top horisontal instead of breadcrumb
  auth: {
    register: false,
    rememberMe: false,
    passwordReset: true,
  },
  admin: {}
}
