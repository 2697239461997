import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import {ArrowDownward, ArrowForward, Delete, SwapHoriz} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import Field from "components/Fields/Field";
import {useAuthDispatch} from "contexts/Auth";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {graphQLApi} from "services/GraphQLApi";

export default function ChannelEdit(props) {
  const intl = useIntl();

  let id = Number(props.match.params.id);

  const [channel, setChannel] = useState({
    id: id,
    is_active: false,
    title: "",
    schedule: "",
    source_connector: '',
    source_language: '',
    destination_connector: '',
    destination_language: '',
    listing: null,
    job_id: null,
    job_message: null,
    progress: null,
    progress_total: null,
    progress_message: null,
    file: null,
    file_uri: "",
  });
  // const [fileUploadProgress, setFileUploadProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [connectors, setConnectors] = useState([]);
  // const [languages, setLanguages] = useState([]);
  // const [settings, setSettings] = useState({});
  const [sourceAnalysis, setSourceAnalysis] = useState([]);
  const [_destinationAnalysis, setDestinationAnalysis] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [listings, setListings] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [validation, setValidation] = useState([]);
  const [channelMappings, setChannelMappings] = useState([]);
  const [showAddFieldDialog, setShowAddFieldDialog] = useState(false);
  const [channelSchedules, setChannelSchedules] = useState([]);

  const updateMapping = (index, key, value) => {
    // console.log('Updating mapping on mapping[' + index + '].' + key + '=', value);
    let maps = [...mappings];
    // let type = key.test(/^source_.+/) ? 'source' : 'destination';
    // maps[index]['conversion'] = "";
    maps[index][key] = value;
    setMappings(maps);
  }

  // const updatePrimary = (index, type) => {
  //   setMappings(curMapping => curMapping.map((m, i) => ({...m, [type + '_primary']: i === index})));
  // }
  //
  // const updateParent = (index, type) => {
  //   setMappings(curMapping => curMapping.map((m, i) => ({...m, [type + '_parent']: i === index})));
  // }

  const deleteMapping = (index) => {
    setMappings(mappings.filter((v, k) => k !== index));
  }

  const handleErrors = (errors) => {
    let vs = {};
    if (errors) {
      errors.forEach(e => {
        if (e && e.extensions && e.extensions.validation) {
          vs = {...vs, ...e.extensions.validation};
        }
      });
    }
    setValidation(vs);
  }

  const DEFAULT_LANGUAGE_ID = "0";
  const client = new graphQLApi(useAuthDispatch(), props.history, handleErrors);
  const stableClient = useCallback(client, []);
  const queryChannelMappings = 'channel_mappings{id conversion update_on_match ' +
    'source_element source_type source_column source_field{id name type entity_type{title} uses_languages}' +
    'destination_element destination_type destination_column destination_field{id name type entity_type{title} uses_languages}' +
    '}';

  const setMappingsFromQuery = (channel_mappings, fields) => {
    setMappings(channel_mappings.map(cm => {
      return {
        ...cm,
        conversion: cm.conversion ? JSON.parse(cm.conversion) : null,
        source_field: cm.source_column !== null
          ? fields.find(ff => ff.id === cm.source_column)
          : cm.source_field,
        destination_field: cm.destination_column !== null
          ? fields.find(ff => ff.id === cm.destination_column)
          : cm.destination_field,
      };
    }));
  }

  const buildMappingObject = (type, element, defaultField = null) => {
    return {
      id: null,
      conversion: "",
      source_field: type === 'destination' ? defaultField : null,
      source_element: type === 'source' ? element.name : '',
      destination_field: type === 'source' ? defaultField : null,
      destination_element: type === 'destination' ? element.name : '',
    };
  }

  const setCommonDataStates = (response) => {
    let conArray = [];
    let s = {};
    if (response.hasOwnProperty('channelSchedules')) {
      setChannelSchedules(response.channelSchedules);
    }
    if (response.hasOwnProperty('settings')) {
      response.settings.data.forEach(st => {
        s[st.key] = st.data;
      });
      // setSettings(s);
    }
    let fetchedFields = [];
    if (response.hasOwnProperty('listings')) {
      setListings(response.listings.data);
    }
    if (response.hasOwnProperty('fields')) {
      fetchedFields = [
        {
          id: "variant_fields",
          name: intl.formatMessage({id: "channels.edit.field.variant_fields", defaultMessage: "Variant fields"}),
          type: "variant_fields",
          uses_languages: false,
        },
        {
          id: "variants",
          name: intl.formatMessage({id: "channels.edit.field.variants", defaultMessage: "Variants"}),
          type: "variants",
          uses_languages: false,
        },
        ...response.fields.data
      ];
    }
    if (response.hasOwnProperty('entityTypes')) {
      setEntityTypes(response.entityTypes.data);
      response.entityTypes.data.forEach(e =>
        fetchedFields.unshift({
          id: "_create_" + e.id,
          name: intl.formatMessage({id: "channels.label.create_new_field", defaultMessage: "> Create field in {entity_type} <"}, {entity_type: e.title}),
          type: "-",
          uses_languages: null,
        })
      );
    }
    setFields(fetchedFields);
    let languages = [];
    if (response.hasOwnProperty('languages')) {
      languages = response.languages.data.map(v => ({type: "language", id: v.id, name: v.name}));
      languages.unshift({type: "language", id: DEFAULT_LANGUAGE_ID, name: s.language});
    }
    // setLanguages(languages);
    if (response.hasOwnProperty('connectors')) {
      conArray = [{
        id: null,
        title: "OpenDIMS",
        driver_needs_type: false,
        driver_can_update: true,
        languages: languages.map(l => ({name: l.name, external_id:l.id})),
      }, ...response.connectors.data];
      setConnectors(conArray);
    }
    return {connectorsArray: conArray, languages: languages, fetchedFields: fetchedFields};
  }

  useEffect(() => {
    const commonQueries =
      'channelSchedules{id title}' +
      'entityTypes(sorting:"id"){data{id title}}' +
      'listings(sorting:"title",filter:{user_id:null}){data{id title entity_type{id}}}' +
      'languages{data{id name}}' +
      'connectors{data{id title languages{external_id name} driver_needs_type driver_can_update}}' +
      'settings{data{ id key data }}';
    if (id) {
      stableClient.query('{' +
        'channels(filter:{id:' + id + '}) {data{' +
        '  source_connector{id} source_language ' +
        '  destination_connector{id} destination_language ' +
        '  id entity_type{id title} listing{id title} title progress progress_total progress_message job_id job_message schedule file file_uri source_analysis destination_analysis' +
        '  ' + queryChannelMappings +
        '}}' +
        'fields{data{ id name type uses_languages entity_type{title} }}' +
        commonQueries +
        '}').then(response => {
        if (response) {
          let {connectorsArray, _languages, fetchedFields} = setCommonDataStates(response);
          if (response.hasOwnProperty('channels') && response.channels.data[0]) {
            let data = response.channels.data[0];
            setChannel(cur => {
              data.schedule = data.schedule ? data.schedule : "";
              data.listing_id = data.listing ? data.listing.id : "";
              data.entity_type_id = data.entity_type ? data.entity_type.id : "";
              if (!data.source_connector) {
                data.source_connector = connectorsArray[0];
              } else {
                data.source_connector = connectorsArray.find(c => c.id === data.source_connector.id);
              }
              if (!data.destination_connector) {
                data.destination_connector = connectorsArray[0];
              } else {
                data.destination_connector = connectorsArray.find(c => c.id === data.destination_connector.id);
              }
              cur = {...cur, ...data};
              if (cur.source_language) {
                if (cur.source_connector) {
                  cur.source_language = cur.source_connector.languages.find(l => l.external_id === cur.source_language);
                }
              }
              else {
                cur.source_language = '';
              }
              if (cur.destination_language) {
                if (cur.destination_connector) {
                  cur.destination_language = cur.destination_connector.languages.find(l => l.external_id === cur.destination_language);
                }
              }
              else {
                cur.destination_language = '';
              }
              return cur;
            });
            if (data.job_id !== null) {
              startProgressChecker();
            }
            let analysis = null;
            if (data.hasOwnProperty('source_analysis') && data.source_analysis) {
              analysis = JSON.parse(data.source_analysis);
              setSourceAnalysis(analysis);

            }

            if (data.hasOwnProperty('channel_mappings') && data.channel_mappings.length) {
              setChannelMappings(data.channel_mappings);
              setMappingsFromQuery(data.channel_mappings, fetchedFields);
            }
            else if (analysis && analysis.hasOwnProperty('elements')) {
              setMappings(analysis.elements.map(fe => {
                return buildMappingObject('source', fe, fetchedFields[0]);
              }));
            }

            if (data.hasOwnProperty('destination_analysis') && data.destination_analysis) {
              analysis = JSON.parse(data.destination_analysis);
              setDestinationAnalysis(analysis);
            }
          }
        }
        setIsLoading(false);
      });
    } else {
      stableClient.query('{' +
        commonQueries +
        '}').then(response => {
        setIsLoading(false);
        if (response) {
          setCommonDataStates(response);
        }
      });
    }
  }, [stableClient, id]);

  const handleSave = (event, start_job = false) => {
    setIsLoading(true);
    setValidation([]);
    if (channel.id) {
      client.mutate('($id:ID!, $listing_id:ID, $entity_type_id:ID, $title:String, $is_active:Boolean, $schedule:String, $source_language:String, $destination_language:String, $channel_mappings:[ChannelMappingInput], $start_job:Boolean)' +
        '{channelUpdate(id:$id, is_active:$is_active, listing_id:$listing_id, entity_type_id:$entity_type_id, title:$title,source_language:$source_language,destination_language:$destination_language,schedule:$schedule,channel_mappings:$channel_mappings,start_job:$start_job) {id ' + queryChannelMappings + '}}', {
        id: id,
        title: channel.title,
        is_active: channel.is_active,
        schedule: channel.schedule,
        listing_id: channel.listing_id,
        entity_type_id: channel.entity_type_id,
        source_language: channel.source_language ? channel.source_language.external_id : '',
        destination_language: channel.destination_language ? channel.destination_language.external_id : '',
        start_job: start_job,
        channel_mappings: mappings.map(cm => ({
          id: cm.id,
          channel_id: channel.id,
          source_element: cm.source_element,
          source_type: cm.source_type,
          source_field_id: cm.source_field && parseInt(cm.source_field.id) > 0 ? cm.source_field.id : null,
          source_column: cm.source_field && isNaN(parseInt(cm.source_field.id)) ? cm.source_field.id : null,
          destination_element: cm.destination_element,
          destination_type: cm.destination_type,
          destination_field_id: cm.destination_field && parseInt(cm.destination_field.id) > 0 ? cm.destination_field.id : null,
          destination_column: cm.destination_field && isNaN(parseInt(cm.destination_field.id)) ? cm.destination_field.id : null,
          update_on_match: cm.update_on_match,
          conversion: JSON.stringify(cm.conversion)
        })),
      }).then(response => {
        if (response && response.hasOwnProperty('channelUpdate')) {
          let data = response.channelUpdate;
          setChannel(cur => {
            if (start_job) {
              startProgressChecker();
              return {...cur, ...data, job_id: 0, progress: 0, progress_total: 0, progress_message: 'Dispatching job to queue'};
            }
            return {...cur, ...data};
          });
          let analysis = {};
          if (data.hasOwnProperty('source_analysis')) {
            analysis = JSON.parse(data.source_analysis);
          }
          if (data.hasOwnProperty('channel_mappings') && data.channel_mappings.length) {
            setChannelMappings(data.channel_mappings);
            setMappingsFromQuery(data.channel_mappings, fields, analysis);
          } else if (analysis && analysis.hasOwnProperty('elements')) {
            setMappings(analysis.elements.map(fe => {
              return buildMappingObject('source', fe);
            }));
          }
        }
        setIsLoading(false);
      });
    } else {
      client.mutate('($listing_id:ID, $entity_type_id:ID, $title:String, $is_active:Boolean, $schedule:String, $source_connector_id:ID, $source_language:String, $destination_connector_id:ID, $destination_language:String)' +
        '{channelCreate(is_active:$is_active, listing_id:$listing_id, entity_type_id:$entity_type_id, title:$title, schedule:$schedule, source_connector_id:$source_connector_id, source_language:$source_language, destination_connector_id:$destination_connector_id, destination_language:$destination_language){id}}', {
        is_active: channel.is_active,
        title: channel.title,
        listing_id: channel.listing_id,
        entity_type_id: channel.entity_type_id,
        schedule: channel.schedule,
        source_connector_id: channel.source_connector ? channel.source_connector.id : null,
        source_language: channel.source_language ? channel.source_language.external_id : '',
        destination_connector_id: channel.destination_connector ? channel.destination_connector.id : null,
        destination_language: channel.destination_language ? channel.destination_language.external_id : null,
      }).then(response => {
        if (response && response.hasOwnProperty('channelCreate')) {
          // if (channel.connector === null && channel.file instanceof File) {
          //   let data = new FormData();
          //   data.append('model', 'Channel');
          //   data.append('field', 'file');
          //   data.append('id', response.importCreate.id);
          //   data.append('file', channel.file);
          //   axios.post('/upload', data, {
          //     baseURL: getApiDomain(),
          //     credentials: "include",
          //     headers: {
          //       "Accept-Language": window.language,
          //       "Accept": "application/json",
          //       ...authHeader(),
          //     },
          //     onUploadProgress: event => {
          //       setFileUploadProgress(Number(event.loaded / event.total * 100).toFixed(2));
          //     }
          //   }).then(r => {
          //     setIsLoading(false);
          //     setFileUploadProgress(null);
          //     props.history.replace(props.history.location.pathname.replace('create', response.importCreate.id));
          //   });
          // } else {
          setIsLoading(false);
          props.history.replace(props.history.location.pathname.replace('create', response.channelCreate.id));
          // }
        } else {
          setIsLoading(false);
        }
      });
    }
  }

  // const getLanguageOptions = (analysis, mapping) => {
  //   let options = [];
  //   if (analysis && analysis.elements) {
  //     let element = analysis.elements.find(elm => elm.name === mapping.element);
  //     if (element && element.attributes) {
  //       options = element.attributes.map(attr => ({type: "attribute", id: attr, name: attr}));
  //     }
  //   }
  //   return options.concat(languages);
  // }

  let intervalProgressTimer = null;
  const startProgressChecker = () => {
    intervalProgressTimer = window.setInterval(() => {
      client.query('{channels(filter:{id:' + id + '}) {data{progress progress_total progress_message job_id job_message}}}').then(r => {
        if (r && r.channels) {
          setChannel(cur => {
            return {...cur, ...r.channels.data[0]};
          });
          if (r.channels.data[0].job_id === null) {
            window.clearInterval(intervalProgressTimer);
          }
        }
      });
    }, 2500);
  }

  const isSourceConnected = typeof channel.source_connector === "object" && channel.source_connector.id > 0;
  const isDestinationConnected = typeof channel.destination_connector === "object" && channel.destination_connector.id > 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<SwapHoriz/>}
            title={intl.formatMessage({id: "channels.edit.heading", defaultMessage: "Channels edit"})}/>
          <form>
            <CardContent>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item md={5} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({id: "channels.edit.label.title", defaultMessage: "Title"})}
                        value={channel.title}
                        onChange={e => setChannel(cur => {
                          return {...cur, title: e.target.value};
                        })}
                        error={!!validation.title}
                        helperText={validation.title ? validation.title.join(" ") : ""}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={!!validation.entity_type_id}>
                        <InputLabel id={"entity_type-label"}>{intl.formatMessage(
                          {id: "channels.edit.label.entity_type", defaultMessage: "Entity type"}
                        )}</InputLabel>
                        <Select
                          // disabled={!channel.source_connector || !!channel.source_connector.id || channel.file !== null}
                          fullWidth
                          labelId={"entity_type-label"}
                          id={"entity_type"}
                          name={"entity_type"}
                          value={channel.entity_type_id ? channel.entity_type_id : ""}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, entity_type_id: e.target.value};
                          })}
                        >
                          {entityTypes.map(c => <MenuItem key={"entity_type_" + c.id} value={c.id}>{c.title}</MenuItem>)}
                        </Select>
                        {!!validation.entity_type_id && <FormHelperText>{validation.entity_type_id.join(" ")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth
                                   error={!!validation.listing_id}>
                        <InputLabel id={"listing-label"}>{intl.formatMessage({id: "channels.edit.label.listing", defaultMessage: "List"})}</InputLabel>
                        <Select
                          disabled={!channel.source_connector || !!channel.source_connector.id || channel.file !== null}
                          fullWidth
                          labelId={"listing-label"}
                          id={"listing"}
                          name={"listing"}
                          value={channel.listing_id ? channel.listing_id : ""}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, listing_id: e.target.value};
                          })}
                        >
                          {listings.filter(l => {
                            if (channel.entity_type_id) {
                              return (l.entity_type && l.entity_type.id === channel.entity_type_id);
                            }
                            return true;
                          }).map(c => <MenuItem key={"listing_" + c.id} value={c.id}>{c.title}</MenuItem>)}
                        </Select>
                        {!!validation.listing_id && <FormHelperText>{validation.listing_id.join(" ")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth
                                   error={!!validation.schedule}>
                        <InputLabel id={"schedule-label"}>{intl.formatMessage(
                          {id: "channels.edit.label.schedule", defaultMessage: "Schedule"}
                        )}</InputLabel>
                        <Select
                          fullWidth
                          labelId={"schedule-label"}
                          id={"schedule"}
                          name={"schedule"}
                          value={channel.schedule}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, schedule: e.target.value};
                          })}
                        >
                          {channelSchedules.map(c => <MenuItem key={"schedule_" + c.id} value={c.id}>{c.title}</MenuItem>)}
                        </Select>
                        {!!validation.schedule && <FormHelperText>{validation.schedule.join(" ")}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={5}>
                  <Grid container spacing={1} alignItems={"flex-start"}>
                    <Grid item xs={12}>
                      <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                        id: "channels.edit.label.source",
                        defaultMessage: "Source"
                      })}</FormLabel>
                      <FormControl fullWidth
                                   error={!!validation.source_connector_id}>
                        <InputLabel id={"source-connector-label"}>{intl.formatMessage({
                          id: "channels.edit.label.connector",
                          defaultMessage: "Connector"
                        })}</InputLabel>
                        <Select
                          fullWidth
                          labelId={"source-connector-label"}
                          id={"source-connector"}
                          disabled={!!id || channel.file !== null}
                          value={channel.source_connector}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, source_connector: e.target.value};
                          })}
                        >
                          {connectors.map(c => <MenuItem key={"source_" + c.id} value={c}>{c.title}</MenuItem>)}
                        </Select>
                        <FormHelperText>{!!validation.source_connector_id ? validation.source_connector_id.join(" ") : " "}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth
                                   error={!!validation.source_language_id}>
                        <InputLabel id={"source-language-label"}>{intl.formatMessage({
                          id: "channels.edit.label.language",
                          defaultMessage: "Language"
                        })}</InputLabel>
                        <Select
                          fullWidth
                          labelId={"source-language-label"}
                          id={"source-language"}
                          disabled={!channel.source_connector || channel.file !== null}
                          value={channel.source_language}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, source_language: e.target.value};
                          })}
                        >
                          {channel.source_connector ?
                            channel.source_connector.languages.map(l =>
                              <MenuItem key={"source_language_" + l.external_id} value={l}>{l.name}</MenuItem>)
                            : <></>
                          }
                        </Select>
                        <FormHelperText>{!!validation.source_language_id ? validation.source_language_id.join(" ") : " "}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1} xs={2} style={{textAlign: "center", verticalAlign: "middle"}}>
                  <ArrowForward/>
                </Grid>
                <Grid item md={3} xs={5}>
                  <Grid container spacing={1} alignItems={"flex-end"}>
                    <Grid item xs={12}>
                      <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                        id: "channels.edit.label.destination",
                        defaultMessage: "Destination"
                      })}</FormLabel>
                      <FormControl fullWidth
                                   error={!!validation.destination_connector_id}>
                        <InputLabel id={"destination-connector-label"}>{intl.formatMessage({
                          id: "channels.edit.label.connector",
                          defaultMessage: "Connector"
                        })}</InputLabel>
                        <Select
                          labelId={"destination-connector-label"}
                          id={"destination-connector"}
                          fullWidth
                          error={!!validation.destination_connector}
                          label={intl.formatMessage({
                            id: "channels.edit.label.destination",
                            defaultMessage: "Destination"
                          })}
                          disabled={!!id || channel.file !== null}
                          value={channel.destination_connector}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, destination_connector: e.target.value};
                          })}
                        >
                          {connectors.map(c => <MenuItem key={"destination_" + c.id} value={c}>{c.title}</MenuItem>)}
                        </Select>
                        <FormHelperText>{!!validation.destination_connector_id ? validation.destination_connector_id.join(" ") : " "}</FormHelperText>
                      </FormControl>
                      <FormControl fullWidth
                                   error={!!validation.destination_language_id}>
                        <InputLabel id={"destination-language-label"}>{intl.formatMessage({
                          id: "channels.edit.label.language",
                          defaultMessage: "Language"
                        })}</InputLabel>
                        <Select
                          fullWidth
                          labelId={"destination-language-label"}
                          id={"destination-language"}
                          disabled={!channel.destination_connector || channel.file !== null}
                          value={channel.destination_language}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, destination_language: e.target.value};
                          })}
                        >
                          {channel.destination_connector ?
                            channel.destination_connector.languages.map(l =>
                              <MenuItem key={"destination_language_" + l.external_id} value={l}>{l.name}</MenuItem>)
                            : <></>
                          }
                        </Select>
                        <FormHelperText>{!!validation.destination_language_id ? validation.destination_language_id.join(" ") : " "}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={12} hidden={1}>
                  {fileUploadProgress === null ? '' :
                    <LinearProgress variant={fileUploadProgress < 100 ? "determinate" : "indeterminate"} color="primary"
                                    value={fileUploadProgress}/>}
                  {channel.file ?
                    <TextField
                      fullWidth
                      disabled={!!id}
                      label={intl.formatMessage({
                        id: "channels.edit.label.selected-import-file",
                        defaultMessage: "Selected file for import"
                      })}
                      defaultValue={typeof channel.file === "object" ? channel.file.name : channel.file ? channel.file : ""}
                      InputProps={{
                        readOnly: true,
                        startAdornment: channel.file_uri ? <IconButton
                          onClick={() => window.open(channel.file_uri, '_blank')}><CloudDownload/></IconButton> : null,
                        endAdornment: (
                          <IconButton
                            disabled={!!id}
                            onClick={() => setChannel(cur => {
                              return {...cur, file: null};
                            })}
                          >
                            <Delete
                              cursor="pointer"
                              fontSize="small"
                            />
                          </IconButton>
                        ),
                      }}
                    />
                    :
                    <Button
                      disabled={channel.connector !== null}
                      fullWidth
                      variant="contained"
                      component="label">
                      <CloudUpload/>&nbsp;
                      <FormattedMessage
                        id="channels.edit.label.import-file"
                        defaultMessage="Choose a csv or xml file for import"
                      />
                      <Input
                        style={{display: "none"}}
                        type="file"
                        inputProps={{
                          accept: "text/csv,application/xml",
                        }}
                        onChange={(e) => {
                          setChannel(cur => {
                            return {...cur, file: e.target.files[0]};
                          });
                        }}
                      />
                    </Button>}
                </Grid>*/}
              </Grid>
            </CardContent>
            {!id ? '' :
              <CardActions style={{alignContent: "flex-start", alignItems: "flex-start", flexDirection: "column"}}>
                <TableContainer style={{display: "flex"}}>
                  <Table size="small" padding="checkbox">
                    <TableHead><TableRow>
                      <TableCell width={"20%"}>{
                        isSourceConnected
                          ? intl.formatMessage({
                            id: "channels.mapping.column.source_element",
                            defaultMessage: "Source element"
                          })
                          : intl.formatMessage({
                            id: "channels.mapping.column.source_field",
                            defaultMessage: "Source field"
                          })
                      }</TableCell>
                      {isSourceConnected && channel.source_connector && channel.source_connector.driver_needs_type ?
                        <TableCell width={"20%"}>{intl.formatMessage({
                        id: "channels.mapping.column.source_type",
                        defaultMessage: "Source type"
                      })}</TableCell> : ''}
                      {/*<TableCell>&nbsp;</TableCell>
                      <TableCell>{intl.formatMessage({
                        id: "channels.mapping.column.conversion",
                        defaultMessage: "Conversions"
                      })}</TableCell>*/}
                      <TableCell width={25}>&nbsp;</TableCell>
                      <TableCell width={"20%"}>{
                        isDestinationConnected
                          ? intl.formatMessage({
                            id: "channels.mapping.column.destination_element",
                            defaultMessage: "Destination element"
                          })
                          : intl.formatMessage({
                            id: "channels.mapping.column.destination_field",
                            defaultMessage: "Destination field"
                          })
                      }</TableCell>
                      {isDestinationConnected && channel.destination_connector && channel.destination_connector.driver_needs_type ?
                        <TableCell width={"20%"}>{intl.formatMessage({
                        id: "channels.mapping.column.destination_type",
                        defaultMessage: "Destination type"
                      })}</TableCell> : ''}
                      {channel.destination_connector && channel.destination_connector.driver_can_update ?
                        <TableCell width={150}>{intl.formatMessage({
                          id: "channels.mapping.column.update_on_match",
                          defaultMessage: "Update on match"
                        })}</TableCell> :
                        ''}
                      <TableCell width={50}>&nbsp;</TableCell>
                    </TableRow></TableHead>
                    <TableBody>
                      {mappings.map((mapping, mapIndex) => <TableRow key={"field-" + mapIndex}>
                        <TableCell>{isSourceConnected
                          ? <Select
                            id={"source_element_select_" + (mapIndex + 1)}
                            fullWidth
                            value={mapping.source_element ? mapping.source_element : ''}
                            onChange={event => updateMapping(mapIndex, 'source_element', event.target.value)}
                            name="source_element"
                          >
                            {(sourceAnalysis && sourceAnalysis.elements) ? sourceAnalysis.elements.map((e, i) =>
                              <MenuItem key={"mapping-" + mapIndex + "-source_element-" + i} value={e.name}>
                                {e.name}
                              </MenuItem>
                            ) : <></>}
                          </Select>
                          : <Autocomplete
                            name="source_field"
                            fullWidth
                            id={"field_select_" + (mapIndex + 1)}
                            value={fields.length ? mapping.source_field : null}
                            onChange={(event, value) => {
                              updateMapping(mapIndex, 'source_field', value);
                              if (isDestinationConnected)
                                updateMapping(mapIndex, 'destination_element', value.name)
                            }}
                            options={fields.filter(f => {
                              if (f.id.indexOf("_create") !== -1) return false;
                              if (!mapping.source_elementType) return true;
                              if (mapping.source_elementCreatable) return true;
                              let type = mapping.source_elementType.split('|');
                              return !!type.find(t => t === f.type && (mapping.source_elementLanguage === null || mapping.source_elementLanguage === f.uses_languages));
                            })}
                            getOptionLabel={option => option ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : ""}
                            getOptionSelected={(option, value) => (value !== "" && option.id === value.id)}
                            renderOption={(option) => <React.Fragment>{(parseInt(option.id) > 0 ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : <em>{option.name}</em>)}</React.Fragment>}
                            renderInput={(params) => <TextField
                              {...params}
                              style={{fontStyle: (mapping.source_field && parseInt(mapping.source_field.id) > 0) ? "normal" : "italic"}}
                            />}
                          />
                        }</TableCell>
                        {(isSourceConnected && channel.source_connector && channel.source_connector.driver_needs_type) ? <TableCell>
                          <Select
                            fullWidth
                            id={"source_type_select_" + (mapIndex + 1)}
                            value={mapping.source_type ? mapping.source_type : 'value'}
                            onChange={event => updateMapping(mapIndex, 'source_type', event.target.value)}
                            name="source_type"
                          >
                            <MenuItem value="value">{intl.formatMessage({
                              id: "channels.mapping.column.simple_value",
                              defaultMessage: "Single value"
                            })}</MenuItem>
                            <MenuItem value="options">{intl.formatMessage({
                              id: "channels.mapping.column.collection_values",
                              defaultMessage: "Collection of values"
                            })}</MenuItem>
                            {entityTypes.map((f,k) =>
                              <MenuItem key={"source_types_entities_"+k} value={"entity_"+f.id}>
                                {intl.formatMessage({id:"channels.mapping.column.entity_value", defaultMessage:"{entity_type} value"}, {entity_type:f.title})}
                              </MenuItem>
                            )}
                            {fields.filter(f => RegExp(/^[a-z]/).test(f.id)).map((f,k) =>
                              <MenuItem key={"source_types_"+k} value={f.id}>{f.name}</MenuItem>
                            )}
                          </Select>
                        </TableCell> : ''}
                        {/*<TableCell align="center">
                          <ArrowForward/>
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            fullWidth
                            value={mapping.conversion}
                            onChange={event => updateMapping(mapIndex, 'conversion', event.target.value)}
                          />
                        </TableCell>*/}
                        <TableCell align="center">
                          <ArrowForward/>
                        </TableCell>
                        <TableCell>{isDestinationConnected
                          ? <TextField
                            id={"destination_element_select_" + (mapIndex + 1)}
                            fullWidth
                            value={mapping.destination_element ? mapping.destination_element : ''}
                            onChange={event => updateMapping(mapIndex, 'destination_element', event.target.value)}
                          />
                          : <Autocomplete
                            name="destination_field"
                            fullWidth
                            id={"destination_field_select_" + (mapIndex + 1)}
                            value={fields.length ? mapping.destination_field : null}
                            onChange={(event, value) => updateMapping(mapIndex, 'destination_field', value)}
                            options={fields.filter(f => {
                              if (!mapping.destination_elementType) return true;
                              if (mapping.destination_elementCreatable && f.id.indexOf("_create") !== -1) return true;
                              let type = mapping.destination_elementType.split('|');
                              return !!type.find(t => t === f.type && (mapping.destination_elementLanguage === null || mapping.destination_elementLanguage === f.uses_languages));
                            })}
                            getOptionLabel={option => option ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : ""}
                            getOptionSelected={(option, value) => (value !== "" && option.id === value.id)}
                            renderOption={(option) => <React.Fragment>{(parseInt(option.id) > 0 ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : <em>{option.name}</em>)}</React.Fragment>}
                            renderInput={(params) => <TextField
                              {...params}
                              style={{fontStyle: (mapping.destination_field && parseInt(mapping.destination_field.id) > 0) ? "normal" : "italic"}}
                            />}
                          />
                        }</TableCell>
                        {(isDestinationConnected && channel.destination_connector && channel.destination_connector.driver_needs_type) ? <TableCell>
                          <Select
                            fullWidth
                            id={"destination_type_select_" + (mapIndex + 1)}
                            value={mapping.destination_type ? mapping.destination_type : 'value'}
                            onChange={event => updateMapping(mapIndex, 'destination_type', event.target.value)}
                            name="destination_type"
                          >
                            <MenuItem value="value">{intl.formatMessage({
                              id: "channels.mapping.column.simple_value",
                              defaultMessage: "Value from channel entity type"
                            })}</MenuItem>
                            <MenuItem value="options">{intl.formatMessage({
                              id: "channels.mapping.column.collection_values",
                              defaultMessage: "Collection of values"
                            })}</MenuItem>
                            {entityTypes.map((f,k) =>
                              <MenuItem key={"destination_types_entities_"+k} value={"entity_"+f.id}>
                                {intl.formatMessage({id:"channels.mapping.column.entity_value", defaultMessage:"{entity_type} value"}, {entity_type:f.title})}
                              </MenuItem>
                            )}
                            {fields.filter(f => RegExp(/^[a-z]/).test(f.id)).map(f => <MenuItem value={f.id}>{f.name}</MenuItem>)}
                          </Select>
                        </TableCell> : ''}
                        {(channel.destination_connector && channel.destination_connector.driver_can_update) ? <TableCell align="center">
                          <FormGroup>
                            <FormControlLabel
                              id={"destination_update_on_match_" + (mapIndex + 1)}
                              control={
                                <Switch
                                  checked={mapping.update_on_match}
                                  onChange={(e) => updateMapping(mapIndex, 'update_on_match', e.target.checked)}
                                  name={"destination_update_on_match_switch_" + (mapIndex + 1)}
                                  color="primary"
                                />
                              }
                              style={{margin: "20px 0"}}
                            />
                          </FormGroup>
                        </TableCell> : ''}
                        <TableCell align="right">
                          <IconButton onClick={() => deleteMapping(mapIndex)}><Delete/></IconButton>
                        </TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardActions>}
          </form>
          <CardActions style={{alignContent: "flex-end"}}>
            <Button
              variant="contained"
              color="secondary"
              style={{flexShrink: 0}}
              onClick={_e => props.history.goBack()}
            >
              {intl.formatMessage({id: "common.button.back"})}
            </Button>
            {channel.job_id === null ?
              <Box marginLeft={"auto"} flexDirection="row" display="flex" flexGrow={1}>
                <Box flexGrow={1} display="inline-flex">{
                  channel.job_message
                    ? intl.formatMessage({
                      id: "channels.label.last_job_message",
                      defaultMessage: "Message from last job execution: {message}"
                    },
                    {message: channel.job_message})
                    : ''}</Box>
                {!id ? '' : <Box display="inline-flex">
                  <Button style={{marginRight: 10}} onClick={() => setShowAddFieldDialog(true)}>
                    {intl.formatMessage({id: "channels.button.add_entity_field", defaultMessage: "Add product field"})}
                  </Button>
                  <Button style={{marginRight: 10}} onClick={() => {
                    setMappings([...mappings, buildMappingObject(null)]);
                  }}>
                    {intl.formatMessage({id: "channels.button.add_mapping", defaultMessage: "Add mapping"})}
                  </Button>
                </Box>}
                {channelMappings.length === 0 ? '' :
                  <Button
                    startIcon={<ArrowDownward/>}
                    variant={"contained"}
                    color={"secondary"}
                    style={{marginRight: 10, display: "inline-flex"}}
                    onClick={(e) => handleSave(e, true)}
                  >
                    {intl.formatMessage({id: "channels.button.start_job", defaultMessage: "Start job"})}
                  </Button>}
                {isLoading ? <CircularProgress style={{height: 43, width: 43}}/> :
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    {id
                      ? intl.formatMessage({id: "common.button.save", defaultMessage: "Save"})
                      : intl.formatMessage({
                        id: "channels.button.save_and_analyze",
                        defaultMessage: "Save and analyze mapping"
                      })}
                  </Button>}
              </Box>
              :
              <Box marginLeft="auto" width="100%" display="flex">
                <Box marginLeft="auto" flexGrow={1} display="inline-flex" flexDirection="column" alignContent="center"
                     alignItems="center">
                  <Box display="inline-flex">{channel.progress_message} - {
                    intl.formatMessage({id: "common.progress.heading", defaultMessage: "Progress {count} of {total}"}, {
                      count: channel.progress,
                      total: channel.progress_total,
                    })}</Box>
                  <LinearProgress variant={"determinate"} color={"primary"}
                                  value={(channel.progress / channel.progress_total * 100)} style={{width: "100%"}}/>
                </Box>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={{marginLeft: 10, display: "inline-flex"}}
                  onClick={() => {
                    window.clearInterval(intervalProgressTimer);
                    client.mutate('{channelUpdate(id:' + id + ',cancel_job:true) {progress progress_total progress_message job_message}}').then(r => {
                      if (r && r.hasOwnProperty('channelUpdate')) {
                        setChannel(cur => {
                          return {...cur, ...r.channelUpdate, job_id: null, job_message: 'Cancelling job...'};
                        });
                      }
                    });
                  }}
                >
                  {intl.formatMessage({id: "channels.button.cancel_job", defaultMessage: "Cancel job"})}
                </Button>
              </Box>
            }
          </CardActions>
        </Card>
        <Dialog open={showAddFieldDialog} maxWidth={"md"}>
          <DialogContent>
            <Field
              field_group_id={null}
              onSaved={field => {
                setFields([...fields, field]);
                setShowAddFieldDialog(false);
              }}
              buttons={[
                {label: intl.formatMessage({id: "common.button.cancel"}), onClick: () => setShowAddFieldDialog(null)}
              ]}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}
